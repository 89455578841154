import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {LicenseManager} from 'ag-grid-enterprise';


// tslint:disable-next-line: max-line-length
LicenseManager.setLicenseKey('Odyssee_Mobile__Odyssee_Mobile_1Devs_1Deployment_2_April_2020__MTU4NTc4MjAwMDAwMA==7cfcfd161d1f66da984a6b3d656d87b9');
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: "root"
})
export class LicenceModificationService {
  constructor(private http: HttpClient) {}

  checkIfIsDone(id) {
    return this.http
      .get<any>(`${environment.apiUrl}/licencemodification/${id}`)
      .pipe(map(x => x));
  }

  getTopModificationPending(id) {
    return this.http
      .get<any>(`${environment.apiUrl}/licencemodification/getTopModificationPending/${id}`)
      .pipe(map(x => x));
  }
}

import { Component, OnInit, HostBinding } from '@angular/core';
import { environment } from 'environments/environment';
import * as moment from "moment";

@Component({
  selector: '[appFooter]',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  version: any
  year: number
  buildDate: any
  constructor() { }

  ngOnInit() {
    this.version = environment.version;
    this.year=moment().year()

    var date = document.lastModified;
    console.log(date);
    this.buildDate = moment(new Date(date)).format('YYYY-MM-DD');
  }
  @HostBinding('class') get themeClass() {
    return 'footer'  ;
  }
}

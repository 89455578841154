import { Component, OnInit, HostBinding, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm} from '@angular/forms';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../services';



@Component({
  selector: 'appLogin',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  @ViewChild("form", {static: true}) form: NgForm;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public authenticationService: AuthenticationService
  ) {
    
  }
  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/implementation';

    this.authenticationService.isLoggedIn.pipe(map(res=>{
      if(res!==true)
      {
        this.router.navigate([this.returnUrl]);
      }
    }))
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
  });
    
  }
  get f() { return this.loginForm.controls; }
  onSubmit() {
    this.submitted = true;

        // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.loading = true;

    this.authenticationService.login(this.f.username.value, this.f.password.value)
    .subscribe( data => {
            this.router.navigate([this.returnUrl])
            this.loading = false
        },
        error => {
            this.loading = false
        });
  }
  @HostBinding('class') get themeClass() {
    return 'o-flex o-flex--vertical u-maximize-height u-maximize-width'
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class ImplementationLicencePlanService {

  constructor(private http: HttpClient) { }

  getAll(implementationId)
  {
    return this.http.get<any>(`${environment.apiUrl}/implementationlicenceplan/list/${implementationId}`).toPromise();
  }

  saveLicencePlanList(plansList, loggedUserId)
  {
    return this.http
      .post<any>(`${environment.apiUrl}/implementationlicenceplan/savelist/${loggedUserId}`, plansList)
      .pipe(map(x => x.Result));
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: "root"
})
export class LicenseReportService {
  constructor(private http: HttpClient) {}

  getLicencePlan(){
    return this.http
      .get<any>(`${environment.apiUrl}/LicenceReport/licence_plan`)
      .pipe(map(x => x.Result));
  }

  getAllLicenceReport(){
    return this.http
      .get<any>(`${environment.apiUrl}/LicenceReport/list`)
      .pipe(map(x => x.Result));
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class DataBaseUtilService {
  constructor(private http: HttpClient) {}

  extendLicenceGroup(implementation_id, newDateValidUntil, nb_allowed_users) {
    return this.http
      .post<any>(`${environment.apiUrl}/databaseutil/extendlicencegroup`, {ImplementationId: implementation_id,DateValidUntil: newDateValidUntil, NbAllowedUsers: nb_allowed_users })
      .pipe(map(x => x.Result));
  }

  extendTrial(implementation_id) {
    return this.http
      .post<any>(`${environment.apiUrl}/databaseutil/extendTrial`, {implementation_id: implementation_id})
      .pipe(map(x => x.Result));
  }

  getCurrency() {
    return this.http
      .get<any>(`${environment.apiUrl}/databaseutil/dbcurrency`)
      .pipe(map(x => x.Result));
  }
  getLanguages() {
    return this.http
      .get<any>(`${environment.apiUrl}/databaseutil/dblanguage`)
      .pipe(map(x => x.Result));
  }

  getCountries() {
    return this.http
      .get<any>(`${environment.apiUrl}/databaseutil/dbcountries`)
      .pipe(map(x => x.Result));
  }

  checkIfAdminEmailExists(email)
  {
    return this.http
      .post<any>(`${environment.apiUrl}/databaseutil/checkadminmail`,  {email})
      .pipe(map(x => x.Result));
  }

  createNewDatabase(createDbData)  {
    return this.http
  .post<any>(`${environment.apiUrl}/databaseutil/newdbui`,  createDbData)
    .pipe(map(x => x.Result));
  }

  archiveImplementation(implementation_id)  {
    return this.http
  .post<any>(`${environment.apiUrl}/databaseutil/archivedb`,  {implementation_id})
    .pipe(map(x => x.Result));
  }

  trialToProduction(implementation_id){
    return this.http
  .post<any>(`${environment.apiUrl}/databaseutil/trialtoprod`,  {implementation_id})
    .pipe(map(x => x.Result));
  }

  checkifdbexists(code)
  {
    return this.http
      .post<any>(`${environment.apiUrl}/databaseutil/checkifdbexists`,  {code})
      .pipe(map(x => x.Result));
  }

  resendEmailInvite(implementationId, email)
  {
    return this.http
      .post<any>(`${environment.apiUrl}/databaseutil/resendinviteemail/${implementationId}/${email}`, {})
      .pipe(map(x => x.Result));
  }
}

import { Component, OnInit , OnDestroy, HostBinding} from '@angular/core'
import { AlertService } from 'services/alert.service'
import { Subscription } from 'rxjs'

@Component({
  selector: '[alert]',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {

  private subscription: Subscription
  message: any
  showSuccess: boolean=false
  showError: boolean=false
  showInfo: boolean=false
  showNotification: boolean=false
  constructor(private alertService: AlertService) { }

  ngOnInit() {
    this.subscription = this.alertService.getMessage().subscribe(mess => {
      if(mess=== undefined) return
     switch(mess.type){
        case "success":
          this.showSuccess=true
        break
        case"error":
          this.showError=true
        break
        case"info":
          this.showInfo=true
          break
        case "notification":
          this.showNotification=true
          break
        case"clear":
          this.showInfo=false
          this.showSuccess=false
          this.showError=false
          this.showNotification=false
        break
        default:
            this.showError=true
        break
     }
    
     this.message = mess
  });
  }

  ngOnDestroy() {
    if (this.subscription!=null) 
      this.subscription.unsubscribe()
}

  @HostBinding('class') get themeClass() {
    return 'o-flex o-flex--vertical o-flex__item u-maximize-width'
  }
}

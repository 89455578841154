import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExceptionSearchService {

  constructor(private http: HttpClient) { }

  getPredefinedSearch() {
    return this.http.get<any>(`${environment.apiUrl}/exceptionsearch`).pipe(map(x => x.Result));
  }

  save(parameters) 
  {
    return this.http
    .post<any>(`${environment.apiUrl}/exceptionsearch`, parameters)
    .pipe(map(x => x.Result));
  }
}

import { Component, OnInit, ViewChild, Input, HostBinding, HostListener} from '@angular/core';
import {AuthenticationService} from 'services';
import { style, trigger, state, transition, animate } from '@angular/animations';
import { User } from 'models/user';

@Component({
  selector: '[appSidebar]',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('contentOffset', [
        state('add', style({
            'margin-left': '40px'
        })),
        state('remove', style({
            'margin-left': '0'
        })),
        transition('* => *', animate('100ms ease'))
    ]),
]
})
export class SidebarComponent implements OnInit {

  sideBarClosed = true
  collapser_mirrored=true
  private expandSubMenu: boolean
  private isUserAuth: boolean
  //private currentLUser:User
  constructor(private AuthService: AuthenticationService) {
  }
  
  ngOnInit() {
    this.AuthService.isLoggedIn.subscribe(data=>{ this.isUserAuth=data})
    this.expandSubMenu = this.isUserAuth
  }
  
  //this method is used by the sandwich button from header component to close/open sidebar
  toggle():void { 
    this.sideBarClosed=!this.sideBarClosed; 
  }

  //this method is used to collapse the side menu
  toggleCollapser(): void{
    this.collapser_mirrored=!this.collapser_mirrored;
  }

  //this method is used to expand the sub menu
  expand() : void{
    this.expandSubMenu=!this.expandSubMenu;
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";
import { map } from "rxjs/operators";
import { AuthenticationService } from './authentication.service';
import { User } from "models/user";

@Injectable({ providedIn: "root" })
export class UserService {

  
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {}

  getById(id: number) {
    return this.http.get(`${environment.apiUrl}/users/${id}`);
  }

  getAllUsersExceptTheLogged() {
    //if (this.isSuperAdmin() || this.canCreateNewImplementation()) {
      return  this.http.get<any>(`${environment.apiUrl}/user/users`).pipe(map(x => x.Result));
    //}
    //return of(null);
  }

  getAllUsersAreInCharge() {
      return  this.http.get<any>(`${environment.apiUrl}/user/usersincharge`).pipe(map(x => x.Result));    
  }

  updateUser(userInfo: User) {
    return this.http
      .put<any>(`${environment.apiUrl}/user/updateuser`, userInfo)
      .pipe(map(x => x.Result));
  }

  //   getAll() {
  //     return this.http.get<any[]>(`${environment.apiUrl}/users`);
  // }
  // register(user: User) {
  //     return this.http.post(`${config.apiUrl}/users/register`, user);
  // }

  // update(user: User) {
  //     return this.http.put(`${config.apiUrl}/users/${user.id}`, user);
  // }

  // delete(id: number) {
  //     return this.http.delete(`${config.apiUrl}/users/${id}`);
  // }
}

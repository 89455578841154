import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExceptionFilterService {

  constructor(private http: HttpClient) { }

   addExceptionFilterAndDeleteException(params) {
     return this.http.post<any>(`${environment.apiUrl}/ExceptionFilter/addAndDelete`, params).pipe(map(x => x.Result));
   }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'upperCaseSplit'
})
export class UpperCaseSplitPipe implements PipeTransform {

 
  transform(value: any, ...args: any[]): any {
    var i=1
    let character=''
    var wordLength=value.length-1
    while (i <= wordLength){
      character = value.charAt(i);
      if (character == character.toUpperCase()) {
        var valueSplit=value.split(character)
        //var upperCaseIdx=value.indexOf(character)
        return valueSplit[0] + " " +character.toUpperCase() + valueSplit[1]
      }
      i++
    }
    return value;
  }

}

import { Component, HostBinding, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { GridOptions, IGetRowsParams } from 'ag-grid-community'
import { User } from 'models/user'
import { AlertService, ApplicationVersionService, AuthenticationService } from 'services'
import moment from "moment"
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms'
import {IAngularMyDpOptions, IMyDateModel, VALUE} from 'angular-mydatepicker'
import { ApplicationVersion } from 'models/applicationVersion'

@Component({
  selector: "[fsa-app-versions-list]",
  templateUrl: "./fsa-app-versions-list.component.html",
  styleUrls: ["./fsa-app-versions-list.component.scss"]
})
export class FsaVersionListComponent implements OnInit {
  private gridApi
  private gridColumnApi
  public columnDefs
  public defaultColDef
  showInput = true
  gridOptions: GridOptions
  loading: Boolean = false
  //public rowData: any[]
  users: User[]= []
  currentLoggedUser:User
  fsaVersionSelected: ApplicationVersion = new ApplicationVersion()
  publishDateDvOptions: IAngularMyDpOptions={
    dateRange: false,
    dateFormat: 'dd-mmm-yyyy',
    
    openSelectorTopOfInput: false,    
    stylesData:{
      styles: `
      .dpDateAdd .myDpIconLeftArrow{
      },
      .dpDateAdd .myDpIconRightArrow{
      },
      .dpDateAdd .myDpHeaderBtn {
      }
      `
    }
  }
  endDateDvOptions: IAngularMyDpOptions={
    dateRange: false,
    dateFormat: 'dd-mmm-yyyy',
    openSelectorTopOfInput: false,
    stylesData:{
      styles: `
      .dp1 .myDpIconLeftArrow{
      },
      .dp1 .myDpIconRightArrow{
      },
      .dp1 .myDpHeaderBtn {
      }
      `
    }
  }

  public searchFsaVersionForm: FormGroup
  public fsaVersionDetailForm: FormGroup
  public fsaVersionDetailMsg: string = ""
  public isShowFsaVersionDetail: boolean = false
  public isFsaVersionSubmited: boolean = false

  public currentAction: enumCurrentAction = enumCurrentAction.None
  public confirmMsg:string=""
  public execActionErrMsg:string=""
  public isShowConfirm: boolean=false

  @ViewChild('agGrid',{static: false}) agGrid;
  constructor(
    private appVersionService: ApplicationVersionService,
    private router: Router,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder
  ) {
    let tickIcon = '<i style="font-size:20px;font-weight:bold;color:DarkGreen">&#10003;</i>';
    this.columnDefs = [
      { headerName: "Version", field: "version", filter: "agTextColumnFilter", width: 60 },
      { headerName: "Android", field: "android",filter: "agTextColumnFilter", width: 40,
        cellRenderer: params => { 
          if(params.value) {
            return tickIcon;
          }
          return '';
        }
      },
      { headerName: "iOS",field: "ios",filter: "agTextColumnFilter",width: 40,
        cellRenderer: params => { 
          if(params.value) {
            return tickIcon;
          }
          return '';
        }
      },
      { headerName: "Windows",field: "uwp",filter: "agTextColumnFilter",width: 40,
        cellRenderer: params => { 
          if(params.value) {
            return tickIcon;
          }
          return '';
        }
      },
      { headerName: "Release date",field: "dateutc_added", width: 70, filter: "agDateColumnFilter",
        cellRenderer: params => { return this.getLocalDateFromUtc(params.value) },
        filterParams: {
          filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange'],
          inRangeInclusive: true, 
          comparator: function(filterLocalDateAtMidnight, cellValue) {
            var dateAsString = moment.utc(cellValue).local().format('DD/MM/YYYY')
            
            var dateParts = dateAsString.split("/")
            var day = Number(dateParts[0])
            var month = Number(dateParts[1]) - 1
            var year = Number(dateParts[2])
            var cellDate = new Date(year, month, day)

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      },
      { headerName: "End date", field: "dateutc_end", width: 70, filter: "agDateColumnFilter",
        cellRenderer: params => { return params.value ? this.getLocalDateFromUtc(params.value) : "" },
        filterParams: {
          filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange'],
          inRangeInclusive: true, 
          comparator: function(filterLocalDateAtMidnight, cellValue) {
            if(!cellValue) return -1;

            var dateAsString = moment.utc(cellValue).local().format('DD/MM/YYYY');
            
            var dateParts = dateAsString.split("/");
            var day = Number(dateParts[0]);
            var month = Number(dateParts[1]) - 1;
            var year = Number(dateParts[2]);
            var cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      },
      { headerName: "Release notes", field: "release_note",filter: "agTextColumnFilter" },
      { headerName: "Internal notes", field: "note",filter: "agTextColumnFilter" }
    ]

    this.defaultColDef = {sortable: true, filter: true }
  }

  ngOnInit() {    
    this.authenticationService.currentUser.subscribe(x=>this.currentLoggedUser=x);
    if(!this.currentLoggedUser.AllowManageAppsVersion) {
      this.router.navigate(["/"])
    }

    this.gridOptions = {
      rowModelType: 'serverSide',
      cacheBlockSize: 500,
      paginationPageSize: 100,
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 2,
      infiniteInitialRowCount: 1,
      maxBlocksInCache: 2
    } as GridOptions
    
    this.gridOptions.getRowStyle = function(params) {
      if (params.node.rowIndex % 2 === 0) {
          return { background: '#F0F0F0', cursor: 'pointer' }
      }
      return { cursor: 'pointer' }
    }

    this.searchFsaVersionForm = this.formBuilder.group({
      status:['InProduction']
    });
    this.resetFsaVersionDetailForm();

    let self = this;
    setTimeout(function () {
      self.searchFsaVersion();
    }, 1000);
  }

  searchFsaVersion(){
    let parameters = {
      status: this.searchFsaVersionForm.value['status']
    };

    this.loading = true;
    var datasource = {
      getRows: (params: IGetRowsParams) => {
        params['request'].perge = function() {
          this.gridOptions.api.deselectAll();
          this.gridOptions.api.purgeServerSideCache(undefined);
        };
        parameters['grid_server_params'] = params['request'];
        this.appVersionService.getFsaVersionList(parameters)
          .subscribe(resultForGrid => {
            this.loading = false;
            var data = resultForGrid.length == this.gridOptions.cacheBlockSize + 1 ? resultForGrid.splice(0, resultForGrid.length == 1 ? 1 : resultForGrid.length - 1) : resultForGrid;
            params.successCallback(data, resultForGrid.length == 1 && data.length == this.gridOptions.cacheBlockSize ? params['request'].startRow + data.length + this.gridOptions.cacheBlockSize : params['request'].startRow + data.length);

            this.agGrid.api.sizeColumnsToFit();
        },
        error => {
          this.agGrid.api.showNoRowsOverlay()
          this.agGrid.api.hideOverlay()
          this.loading = false;
        });
      }
    }
    this.gridApi.setServerSideDatasource(datasource);
  }

  onGridReady(params) {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    this.agGrid.api.sizeColumnsToFit()
  }

  onFirstDataRendered(){
    this.agGrid.api.setSortModel([ {colId: 'dateutc_added', sort: 'desc'} ]);
    this.agGrid.api.sizeColumnsToFit();
  }

  onRowFsaClicked(event: any) {
    this.resetFsaVersionDetailForm();
    this.isFsaVersionSubmited = false;
    this.fsaVersionDetailMsg = '';
    this.loading = true;
    this.appVersionService.getAppVersionDetail(event.data.id)
      .subscribe(detailData => {
          this.loading = false;
          this.fsaVersionSelected = detailData;

          let dateAdd = this.getLocalDateFromUtc(this.fsaVersionSelected.dateutc_added);
          let dateEnd = this.getLocalDateFromUtc(this.fsaVersionSelected.dateutc_end);
          this.fsaVersionDetailForm.setValue({ 
            detail_version: this.fsaVersionSelected.version,
            detail_application_code: 'Field Service',
            detail_android: this.fsaVersionSelected.android,
            detail_ios: this.fsaVersionSelected.ios,
            detail_windows: this.fsaVersionSelected.uwp,
            detail_dateutc_added: dateAdd,
            detail_dateutc_end: dateEnd,
            detail_release_note: this.fsaVersionSelected.release_note,
            detail_note: this.fsaVersionSelected.note
          });
          // if(dateEnd){
          //   this.fsaVersionDetailForm.get('detail_dateutc_end').patchValue({ detail_dateutc_end: dateEnd });
          // }
          this.isShowFsaVersionDetail = true;
        },
        error => {
          this.loading = false;
          this.isShowFsaVersionDetail = true;
          this.fsaVersionDetailMsg = error.ResponseException.ExceptionMessage;
      });
  }

  goToNewFsaVersion() {
    this.resetFsaVersionDetailForm();
    this.isFsaVersionSubmited = false;
    this.fsaVersionDetailMsg = '';
    let localTime = moment().format("YYYY-MM-DD");
    this.fsaVersionSelected = new ApplicationVersion();
    this.fsaVersionSelected.application_code = "NativeService";
    this.fsaVersionSelected.dateutc_added = new Date(localTime);

    this.fsaVersionDetailForm.setValue({ 
      detail_version: '',
      detail_application_code: 'Field Service',
      detail_android: true,
      detail_ios: true,
      detail_windows: true,
      detail_dateutc_added: localTime,
      detail_dateutc_end: '',
      detail_release_note: '',
      detail_note: ''
    });
    this.isShowFsaVersionDetail = true;
  }

  onSaveFsaVersion(){
    this.isFsaVersionSubmited = true;
    if (this.fsaVersionDetailForm.invalid) {
      return;
    }

    this.fsaVersionSelected.application_code = 'NativeService';
    this.fsaVersionSelected.version = this.fsaVersionDetailForm.value['detail_version'];
    this.fsaVersionSelected.android = this.fsaVersionDetailForm.value['detail_android'];
    this.fsaVersionSelected.ios = this.fsaVersionDetailForm.value['detail_ios'];
    this.fsaVersionSelected.uwp = this.fsaVersionDetailForm.value['detail_windows'];

    let utcDateAdd = this.getFsaDateValue('detail_dateutc_added');
    let timeAdd = this.fsaVersionSelected.id && this.fsaVersionSelected.id != '00000000-0000-0000-0000-000000000000'
                  ? moment(this.fsaVersionSelected.dateutc_added).local().format('HH:mm:ss') : moment().local().format('HH:mm:ss');
    this.fsaVersionSelected.dateutc_added = new Date(moment(utcDateAdd + ' ' + timeAdd).utc().format());

    let dateEndValue = this.getFsaDateValue('detail_dateutc_end');
    if(dateEndValue && dateEndValue != ''){
      let timeEnd = this.fsaVersionSelected.id && this.fsaVersionSelected.id != '00000000-0000-0000-0000-000000000000' && this.fsaVersionSelected.dateutc_end
                  ? moment(this.fsaVersionSelected.dateutc_end).local().format('HH:mm:ss') : moment().local().format('HH:mm:ss');
      this.fsaVersionSelected.dateutc_end = new Date(moment(dateEndValue + ' ' + timeEnd).utc().format());
    }

    this.fsaVersionSelected.release_note = this.fsaVersionDetailForm.value['detail_release_note'];
    this.fsaVersionSelected.note = this.fsaVersionDetailForm.value['detail_note'];

    this.fsaVersionDetailMsg = "";
    this.loading = true;
    this.appVersionService.checkVersionExisted(this.fsaVersionSelected)
      .subscribe(resultExisted => {
        if (resultExisted && resultExisted == "true"){
          this.loading = false;
          this.isShowFsaVersionDetail = true;
          this.fsaVersionDetailMsg = 'This version ' + this.fsaVersionSelected.version + ' already exists!';
          return;
        }

        this.appVersionService.saveFsaVersion(this.fsaVersionSelected)
          .subscribe(detailData => {
              this.loading = false;
              this.isShowFsaVersionDetail = false;
              this.isFsaVersionSubmited = false;
              this.resetFsaVersionDetailForm();
              this.searchFsaVersion();
            },
            error => {
              this.loading = false;
              this.isShowFsaVersionDetail = true;
              this.fsaVersionDetailMsg = error.ResponseException.ExceptionMessage;
        });
      },
      error => {
        this.loading = false;
        this.isShowFsaVersionDetail = true;
        this.fsaVersionDetailMsg = error.ResponseException.ExceptionMessage;
    });
  }

  closeFsaVersionDetail(){
    this.isShowFsaVersionDetail = false;
  }

  fsaVersionDetailFormHasError(filedName:string){
    var result = this.isShowFsaVersionDetail
                  && this.isFsaVersionSubmited
                  && this.fsaVersionDetailForm.controls[filedName].invalid
                  && this.fsaVersionDetailForm.controls[filedName].errors;
    return result;
  }

  resetFsaVersionDetailForm(){
    let localDate = moment().format("YYYY-MM-DD");
    this.fsaVersionDetailForm = this.formBuilder.group({
      detail_application_code: ['', Validators.required],
      detail_version: ['', Validators.required],
      detail_dateutc_added:[localDate, Validators.required],
      detail_dateutc_end: [localDate],
      detail_android: [true],
      detail_ios: [true],
      detail_windows: [true],
      detail_release_note: [],
      detail_note: []
    });
  }

  getFsaDateValue(fieldName){
    if(!this.fsaVersionDetailForm.value[fieldName]) return '';

    let dateObj = this.fsaVersionDetailForm.value[fieldName];
    if(dateObj.singleDate && dateObj.singleDate.formatted){
      return dateObj.singleDate.formatted;
    }
    return dateObj;
  }

  getAppName(appCode){
    if(appCode == 'NativeService'){
      return 'Field Service';
    }
    if(appCode == 'NativeApp'){
      return 'Sales App';
    }
    return '';
  }

  getLocalDateFromUtc(utcDate){
    if(!utcDate) return '';
    return moment.utc(utcDate).local().format("DD-MMM-YYYY")
  }

  onShowConfirmDeleteExc(visible: boolean, action: enumCurrentAction = enumCurrentAction.None){
    this.execActionErrMsg = "";
    this.isShowConfirm = visible
    if(!visible){
      this.currentAction = enumCurrentAction.None;
      this.confirmMsg = "";
    }else{
      this.currentAction = action;
      if(this.currentAction == enumCurrentAction.DeleteFsaVersion){
        this.confirmMsg = "Are you sure you want to delete FSA v" + this.fsaVersionSelected.version + ' ?'; 
      }
      if(this.currentAction == enumCurrentAction.DeleteSaVersion){
        //this.confirmMsg = "Are you sure you want to delete FSA v" + this.fsaVersionSelected.version + ' ?'; 
      }      
    }
  }

  onExecuteAction(){
    this.execActionErrMsg = "";
    if(this.currentAction == enumCurrentAction.DeleteFsaVersion){
      this.onDeleteFsaVersion();
    } else if(this.currentAction == enumCurrentAction.DeleteSaVersion){
      
    }  
  }

  onDeleteFsaVersion(){
    let params = {
      id: this.fsaVersionSelected.id
    };
    this.loading = true;
    this.appVersionService.deleteAppVersion(params).subscribe(
      data => {
        this.isShowConfirm = false;
        this.isShowFsaVersionDetail = false;
        this.confirmMsg = "";
        this.loading = false;
        this.searchFsaVersion();
      },
      error => {
        this.loading = false;
        this.confirmMsg = error.ResponseException.ExceptionMessage;
      }
    )
  }

  @HostBinding("class") get themeClass() {
    return "u-scroll o-flex__item o-flex o-flex--vertical"
  }
}

export enum enumCurrentAction {
  None=0,
  DeleteFsaVersion=1,
  DeleteSaVersion=2
}

import { Component, OnInit, HostBinding, Inject, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DomSanitizer, SafeHtml, Title } from "@angular/platform-browser"
import { Subscription, timer } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { TrialService } from 'services';
import { Steps, EmailType, Industry, TrialDto, JobTitle } from 'models/trial';
import { environment } from 'environments/environment';

@Component({
  selector: "[app-trial-demo-registration-mobile]",
  templateUrl: "./trial-demo-registration-mobile.component.html",
  styleUrls: ["./trial-demo-registration-mobile.component.scss"]
})

export class TrialDemoRegistrationMobileComponent implements OnInit {

  step_1_Form: FormGroup;
  isStep_1_FormHasError: boolean = false;
  step_3Demo_Form: FormGroup;
  isStep_3Demo_FormHasError: boolean = false;
  step_3Trial_Form: FormGroup;
  isStep_3Trial_FormHasError: boolean = false;
  isStep_1GoStep_3: boolean = false;
  isIpBanned: boolean = false;
  isAlreadyUse: boolean = false;
  isAlreadyInvited: boolean = false;
  isFree: boolean = false;
  isBanned: boolean = false;
  isRecaptchaValid = false;
  loading: boolean = false;
  errorMessage: string;
  step: Steps;
  steps = Steps;
  countryList: object[];
  languageList: object[];
  industryList: object[] = [];
  jobTitleList: object[] = [];
  entity: TrialDto = new TrialDto();
  portalLoginUrl: string = environment.portalLoginUrl;
  portalRecoverPasswordUrl: string = environment.portalRecoverPasswordUrl;
  recaptchaSiteKey: string = environment.recaptchaSiteKey;
  subscription: Subscription;
  dashoffset: number = 350;
  percent: number = 0;
  trialResult: object = {};
  portalLoginPageUrl:string = environment.portalLoginPageUrl;
  freeEmailMessage: SafeHtml;
  pleaseSelect: string = '';
  //isValidNumberTechnician: boolean = true;
  isCompactVersion: boolean = false;
  isEnableTrial: boolean = true;
  spaceTop: number = 0;
  stepWidth: number = 0;
  odsTermsLink: string = '';
  privacyPolicyLink: string = '';
  newsEventsText: string = '';
  odsUrl: string = '';
  language: string = 'en';
  colorCode: string = '4D002E' //default color
  min_number_technicians: number = 5;
  needToGetTrialFormValues: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document, public translate: TranslateService, private route: ActivatedRoute,private router: Router, private formBuilder: FormBuilder, private trialService: TrialService, private _sanitizer: DomSanitizer, private title: Title) { }

  ngOnInit() {
    if (!(/iPhone|iPad|iPod|Android|blackberry|fennec/i.test(navigator.userAgent))){
      this.router.navigate(['/free-trial']);
      return;
    }

    const lang = this.route.snapshot.paramMap.get("language") || 'en';
    this.translate.use(lang.indexOf('-') != -1 ? lang.split('-')[0] : lang);
    this.language = lang.indexOf('-') != -1 ? lang.split('-')[0] : lang; 

    this.route.queryParams.subscribe(x => {
      if (x.hasOwnProperty('color')){
        if (x["color"] && x["color"] !== ''){
          if (this.isHexColor(x["color"])){
            this.colorCode = x["color"];      
          }    
        }  
      };
    });

    // check is banned ip
    this.loading = true;
    this.trialService.initTrialForm().subscribe(data => {
      this.loading = false;
      this.isIpBanned = data["isBannedIp"] === "1";
      this.isEnableTrial = data["isEnableTrial"] === "1";

      let clientCountryCode = data["countryCode"].toLowerCase();
      if(this.language.toLowerCase() == 'en' && clientCountryCode){
        this.language = clientCountryCode;
      }
      
      if (!this.isIpBanned && this.isEnableTrial) {
        this.init();
        this.needToGetTrialFormValues = true;
      }
      else{          
        window.location.href = "https://wello.solutions/" + lang + "/contact/";
      }        
    },
    error => {
      this.loading = false;
      this.errorMessage = error.Message;
    });
  }

  private passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (this.step_3Trial_Form && (control.value !== this.step_3Trial_Form.controls.adminuserpassword.value)) {
        return { passwordNotMatch: true };
    }
    return null;
 }

  init() {
    this.step = Steps.Step_1;
    this.step_1_Form = this.formBuilder.group({
      adminuseremail: ['', [Validators.required, Validators.maxLength(255), Validators.email]]
    });

    this.step_3Demo_Form = this.formBuilder.group({
      adminuserfirstname: ['', [Validators.required, Validators.maxLength(255)]],
      adminuserlastname: ['', [Validators.required, Validators.maxLength(255)]],
      companyname: ['', Validators.required],
      jobtitle: ['', Validators.required],
      phone_number: ['', Validators.required],
      industry: ['', Validators.required],
      defaultcountryname: ['', Validators.required],
      numberoftechnicians: ['', Validators.required],
      onboardinggoal: ['', Validators.required],
      news_events: [''],
      recaptcha: ['', Validators.required]
    });

    this.step_3Trial_Form = this.formBuilder.group({
      adminuserfirstname: ['', [Validators.required, Validators.maxLength(255)]],
      adminuserlastname: ['', [Validators.required, Validators.maxLength(255)]],
      adminuserpassword: ['', [Validators.required, Validators.minLength(6)]],
      password_confirm: ['', [Validators.required, this.passwordMatcher.bind(this)]],
      defaultlanguagename: ['', Validators.required],
      jobtitle: ['', Validators.required],
      phone_number: ['', Validators.required],
      defaultcountryname: ['', Validators.required],
      companyname: ['', Validators.required],
      numberoftechnicians: ['', Validators.required],
      agreement: ['', Validators.requiredTrue],
      news_events: [''],
      recaptcha: ['', Validators.required]
    });

    this.entity.istrial = true;
    this.route.queryParams.subscribe(params => {
      this.entity.adminuseremail = params['email'];
      this.isCompactVersion = params['isCompactVersion'] == "true" ? true : false;
      if(this.isCompactVersion == true) {
        document.getElementsByTagName("body")[0].style.background = "none";
      }
    });

    this.setTitle();
    this.stepWidth = 750;

    this.odsUrl = 'https://wello.solutions/';
    var currentLang = this.route.snapshot.paramMap.get("language") || 'en';
    var supportLang = ["nl", "fr"];
    if(supportLang.includes(currentLang.toLowerCase()))
    {
      this.odsUrl = 'https://wello.solutions/' + currentLang + '/';
    }

    if (this.privacyPolicyLink == ''){
      this.translate.get('lbl_shrt_PrivacyPolicyLinkWello').subscribe(res => {
        this.privacyPolicyLink = res;
        this.privacyPolicyLink = this.privacyPolicyLink.replace('[startOfPrivacyPolicy]', '<a href="https://wello.solutions/privacy-note/" target="_blank">');
        this.privacyPolicyLink = this.privacyPolicyLink.replace('[/startOfPrivacyPolicy]', '</a>');
      });
    }

    let node = document.createElement('script');
    node.src = 'https://www.googletagmanager.com/gtag/js?id=UA-47359231-7';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);

    let analytics = document.createElement('script');
    analytics.type = 'text/javascript';
    analytics.async = false;
    analytics.charset = 'utf-8';
    analytics.innerHTML = "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}; gtag('js', new Date()); gtag('config', 'UA-47359231-7');";
    document.getElementsByTagName('head')[0].appendChild(analytics);
  }

  step_3_DemoFormLoad(callback){
    if(this.needToGetTrialFormValues){
      this.getTrialFormValues(callback);
      this.needToGetTrialFormValues = false;
    }
    else{
      if (callback) callback();
    }
  }

  step_3_TrialFormLoad(callback){
    if(this.needToGetTrialFormValues){
      this.getTrialFormValues(callback);
      this.needToGetTrialFormValues = false;
    }
    else{
      if (callback) callback();
    }
  }

  getTrialFormValues(callback){
    this.loading = true;
    this.trialService.getTrialFormValues(this.language).subscribe(data => {
      this.loading = false;      
      this.countryList = data["countries"];
      this.languageList = data["languages"];
      this.min_number_technicians = data["number_technicians"];
      this.entity.numberoftechnicians = data["number_technicians"];

      let defaultCountry = this.countryList.find(c => c['code'].toLowerCase() == this.language);
      if(!defaultCountry){
        defaultCountry = this.countryList.find(c => c['is_default'] == 1);
      }
      if (defaultCountry) {
        this.entity.defaultcountryname = defaultCountry['country_name'];
        this.defaultCountryNameChange();
      }
      else {
        let langDefault = this.languageList.find(l => l['is_default'] == 1);
        if(langDefault) this.entity.defaultlanguagename = langDefault['name'];
      }

      if (callback) callback();
    },
    error => {
      this.loading = false;
      this.errorMessage = error.Message;
    });
  }

  step_1_FormSubmit() {
    this.isStep_1_FormHasError = false;
    this.isStep_1GoStep_3 = false;
    if (this.step_1_Form.invalid) {
      this.isStep_1_FormHasError = true;
      var emailValue = this.step_1_Form.get('adminuseremail').value;
      if (emailValue != '' && emailValue != undefined && this.isAlreadyUse == false && this.isAlreadyInvited == false){
        this.step_1_Form.controls.adminuseremail.setErrors({emailValue:true});
      }
      return;
    }

    // check email
    this.loading = true;
    this.trialService.getEmailType(this.entity.adminuseremail).subscribe(data => {
      this.loading = false;
      this.isAlreadyUse = data == EmailType.ALREADY_USED;
      this.isAlreadyInvited = data == EmailType.ALREADY_INVITED;
      this.isFree = data == EmailType.FREE;
      this.isBanned = data == EmailType.BANNED;

      if (this.isAlreadyUse || this.isAlreadyInvited || this.isBanned) {
        this.step_1_Form.controls.adminuseremail.setErrors({invalidEmail:true});
        this.isStep_1_FormHasError = true;
        return;
      }

      this.step_1_Form.controls.adminuseremail.setErrors(null);
      if (!this.entity.defaultlanguagename) this.defaultCountryNameChange();

      if (this.isFree) {
        let self = this;
        this.step_3_DemoFormLoad(function() {
          self.resetStep3Form();
          self.step = Steps.Step_3_Demo;
          self.isStep_1GoStep_3 = true;
          self.setTitle();
          self.calculateSpacerTop();
          self.stepWidth = 960;
        });
      }
      else {
        this.step = Steps.Step_2;
        this.stepWidth = 900;
      }
      this.setTitle();
      this.calculateSpacerTop();
    });

    // init dropdownlist and links
    this.initDropdownListAndLinks();
  }

  forceToStep2() {
    this.isStep_1_FormHasError = false;
    this.isStep_1GoStep_3 = false;
    this.step_1_Form.controls.adminuseremail.setErrors(null);
    
    if (!this.entity.defaultlanguagename) this.defaultCountryNameChange();
    if (this.isFree) {
      let self = this;
      this.step_3_DemoFormLoad(function() {
        self.resetStep3Form();
        self.step = Steps.Step_3_Demo;
        self.isStep_1GoStep_3 = true;
        self.setTitle();
        self.calculateSpacerTop();
        self.stepWidth = 960;
      });
    }
    else {
      this.step = Steps.Step_2;
      this.stepWidth = 900;
    }

    this.setTitle();
    this.calculateSpacerTop();

    // init dropdownlist and links
    this.initDropdownListAndLinks();
  }

  step_2_TrialSubmit() {
    let self = this;
    this.step_3_TrialFormLoad(function() {
      self.resetStep3Form();
      self.step = Steps.Step_3_Trial;
      self.setTitle();
      self.calculateSpacerTop();
      self.stepWidth = 960;
      if(self.isStep_3Trial_FormHasError) self.setSpacerTopOnSubmitError();
    });
  }

  step_2_DemoSubmit() {
    let self = this;
    this.step_3_DemoFormLoad(function() {
      self.resetStep3Form();
      self.step = Steps.Step_3_Demo;
      self.setTitle();
      self.calculateSpacerTop();
      self.stepWidth = 960;
      if(self.isStep_3Demo_FormHasError) self.setSpacerTopOnSubmitError();
    });
  }

  step_3Demo_FormSubmit() {
    this.isStep_3Demo_FormHasError = false;
    if (this.step_3Demo_Form.invalid) {
      this.isStep_3Demo_FormHasError = true;
      this.setSpacerTopOnSubmitError();
      return;
    }

    this.entity.istrial = false;
    this.entity.news_events_text = (this.demoFormCtrls.news_events.value ? this.newsEventsText : '');
    this.demoInformationSubmit();
  }

  step_3Trial_FormSubmit() {
    this.isStep_3Trial_FormHasError = false;
    if (this.step_3Trial_Form.invalid) {
      this.isStep_3Trial_FormHasError = true;
      this.setSpacerTopOnSubmitError();
      return;
    }

    this.entity.istrial = true;
    this.entity.news_events_text = (this.trialFormCtrls.news_events.value ? this.newsEventsText : '');
    this.trialInformationSubmit();
  }

  resetStep3Form(){
    this.isRecaptchaValid = false;
    this.entity.agreement = false;
    //this.isValidNumberTechnician = true;
    this.entity.numberoftechnicians = this.min_number_technicians;
    this.step_3Demo_Form.get('numberoftechnicians').setValue('');
    this.step_3Demo_Form.get('news_events').setValue('');
    this.step_3Trial_Form.get('numberoftechnicians').setValue('');
    this.step_3Trial_Form.get('news_events').setValue('');
  }

  step_3_FormBack() {
    this.step = (this.isStep_1GoStep_3) ? Steps.Step_1 : Steps.Step_2;
    this.setTitle();
    this.calculateSpacerTop();
    this.stepWidth = window.innerWidth - 10;
  }

  demoInformationSubmit() {
    this.loading = true;    
    this.trialService.requestdemo(this.entity).subscribe(
      data => {
        this.loading = false;
        this.trialResult = data;
        let language = this.route.snapshot.paramMap.get("language") || 'en';

        if (data.Success){          
          window.location.href = "https://wello.solutions/" + language + "/thank-you";
        }
        else{
          window.location.href = "https://wello.solutions/" + language + "/contact";
        }
      },
      error => {
        this.loading = false;
        this.errorMessage = error.Message;
      }
    )
  }

  trialInformationSubmit() {
    let canCreateTrial = true;
    this.step = Steps.Processing;
    this.loading = true;
    
    this.trialService.freeTrial(this.entity).subscribe(
      data => {
        this.loading = false;
        this.trialResult = data;

        if (data.Success == false && data.ErrorMessage == 'TRIAL_FORM_ENABLED'){
          canCreateTrial = false;          
        }
      },
      error => {
        this.loading = false;
        this.errorMessage = error.Message;
      }
    )

    this.subscription = timer(0, 100).pipe().subscribe(result => {
      if (this.loading == false) {
        if (canCreateTrial == false){
          this.subscription.unsubscribe();
          let language = this.route.snapshot.paramMap.get("language") || 'en';
          window.location.href = "https://wello.solutions/" + language + "/contact/";          
        }
        else{
          this.dashoffset = 0;
          this.percent = 100;
          this.subscription.unsubscribe();
          this.loginUserToPortal();
        }        
      } else if (this.dashoffset > 3.5) {
        this.dashoffset -= 3.5;
        this.percent += 1;
      }
    });
  }

  loginUserToPortal() {
    this.document.location.href = this.portalLoginUrl + '?domain=' + encodeURIComponent(this.trialResult['Data']['domain']) + '&email=' + encodeURIComponent(this.trialResult['Data']['email']) + '&password=' + encodeURIComponent(this.trialResult['Data']['password']);
  }

  defaultCountryNameChange() {
    if (this.entity.defaultcountryname) {
      let country = this.countryList.find(c => c['country_name'] == this.entity.defaultcountryname);
      let language = this.languageList.find(l => l['code'] == 'EN');
      switch(country['code']) {
        case 'FR':
        case 'DE':
        case 'IT':
        case 'ES':
        case 'VN':
          language = this.languageList.find(l => l['code'] == country['code'])
          break;
        case 'BE':
        case 'NL':
          language = this.languageList.find(l => l['code'] == 'NL')
          break;
        case 'AT':
          language = this.languageList.find(l => l['code'] == 'DE')
          break;
        case 'GF':
        case 'PF':
        case 'RE':
          language = this.languageList.find(l => l['code'] == 'FR')
          break;
        case 'PL':
          language = this.languageList.find(l => l['code'] == country['code'])
          break;
      }
      this.entity.defaultlanguagename = language['name'];
    }
  }

  numberoftechniciansChanged() {
    if(this.step == Steps.Step_3_Demo){
      this.checkNumberoftechniciansValid(this.step_3Demo_Form);
    }
    if(this.step == Steps.Step_3_Trial){
      this.checkNumberoftechniciansValid(this.step_3Trial_Form);
    }
  }

  checkNumberoftechniciansValid(stepForm){
    let val = stepForm.get('numberoftechnicians').value < 1 ? null : stepForm.get('numberoftechnicians').value;
    if(!val) val = this.min_number_technicians;
    this.entity.numberoftechnicians = val;
    stepForm.get('numberoftechnicians').setValue(val);
    //this.isValidNumberTechnician = val >= 5 || val == null;
  }

  passwordChanged() {
    if (this.entity.adminuserpassword && this.entity.password_confirm) {
      if (this.entity.adminuserpassword != this.entity.password_confirm)
        this.step_3Trial_Form.controls.password_confirm.setErrors({passwordNotMatch: true});
      else
        this.step_3Trial_Form.controls.password_confirm.setErrors(null);
    }
  }

  decrease() {
    if (this.entity.numberoftechnicians <= 1){
      this.entity.numberoftechnicians = 1;
      this.step3Form.get('numberoftechnicians').setValue(this.entity.numberoftechnicians);
    }
    else{
      this.entity.numberoftechnicians--;
      this.step3Form.get('numberoftechnicians').setValue(this.entity.numberoftechnicians);
    }

    //this.isValidNumberTechnician = this.entity.numberoftechnicians >= 5;
  }

  increase() {
    if (this.entity.numberoftechnicians == 9999999){
      // reach to maximum number of technicians
      return;
    }

    this.entity.numberoftechnicians++;
    this.step3Form.get('numberoftechnicians').setValue(this.entity.numberoftechnicians);
    //this.isValidNumberTechnician = this.entity.numberoftechnicians >= 5;
  }

  resolved(event) {
    this.isRecaptchaValid = event != null;
  }

  setTitle(){
    var transKey = '';
    switch (this.step) {
      case Steps.Step_1:
          transKey = 'title_shrt_trialDemoStep_1_Desc';
          break;
      case Steps.Step_2:
        transKey = 'title_shrt_trialDemoStep_2_Desc';
        break;
      case Steps.Step_3_Demo:
        transKey = 'title_shrt_trialDemoStep_3Demo_Desc';
        break;
      case Steps.Step_3_Trial:
        transKey = 'title_shrt_trialDemoStep_3Trial_DescWello';
        break;
      default:
    }

    this.translate.get(transKey).subscribe(res => {
      this.title.setTitle("Wello Solutions | " + res);
    });
  }

  calculateSpacerTop(){
    var height = window.innerHeight;
    switch (this.step)
    {
      case Steps.Step_1:
        this.spaceTop = (height - 330)/2;
        break;
      case Steps.Step_2:
        this.spaceTop = (height - 520)/2;
        break;
      case Steps.Step_3_Demo:
        this.spaceTop = (height - 730)/2;
        break;
      case Steps.Step_3_Trial:
        this.spaceTop = (height - 760)/2;
        break;
    }
    if(this.spaceTop < 0) this.spaceTop = 0;
  }

  setSpacerTopOnSubmitError(){
    var fieldsError = 0;
    if(this.step == Steps.Step_3_Demo)
    {
      if(!this.entity.adminuserfirstname || !this.entity.adminuserlastname) fieldsError += 1;
      if(!this.entity.companyname || !this.entity.jobtitle) fieldsError += 1;
      if(!this.entity.phone_number || !this.entity.industry) fieldsError += 1;
      if(!this.entity.defaultcountryname || !this.entity.numberoftechnicians) fieldsError += 1;
      if(!this.entity.onboardinggoal) fieldsError += 1;
      if(this.demoFormCtrls.recaptcha.errors) fieldsError += 1;
    }
    if(this.step == Steps.Step_3_Trial)
    {
      if(!this.entity.adminuserfirstname || !this.entity.adminuserlastname) fieldsError += 1;
      if(this.trialFormCtrls.adminuserpassword.errors || this.trialFormCtrls.password_confirm.errors) fieldsError += 1;
      if(!this.entity.defaultlanguagename || !this.entity.jobtitle) fieldsError += 1;
      if(!this.entity.phone_number || !this.entity.defaultcountryname) fieldsError += 1;
      if(!this.entity.companyname || !this.entity.numberoftechnicians) fieldsError += 1;
      if(!this.entity.agreement) fieldsError += 1;
      if(this.trialFormCtrls.recaptcha.errors) fieldsError += 1;
    }

    this.spaceTop = this.spaceTop - (fieldsError * 10)
    if(this.spaceTop < 0) this.spaceTop = 0;
  }

  isHexColor (hexCode: string) {
    return typeof hexCode === 'string'
        && hexCode.length === 6
        && !isNaN(Number('0x' + hexCode))
  }

  initDropdownListAndLinks(){
    // Industry
    if (this.industryList.length <=0){
      this.translate.get('FORM_INDUSTRIES_TELECOM').subscribe(res => {
        this.industryList.push({ code: Industry.TELECOM, name: res });
        this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_INDUSTRIES_RENEWABLE_ENERGY').subscribe(res => {
        this.industryList.push({ code: Industry.RENEWABLE_ENERGY, name: res });
        this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_INDUSTRIES_UTILITY').subscribe(res => {
        this.industryList.push({ code: Industry.UTILITY, name: res });
        this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_INDUSTRIES_HVACR').subscribe(res => {
        this.industryList.push({ code: Industry.HVACR, name: res });
        this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_INDUSTRIES_IT_HARDWARE').subscribe(res => {
        this.industryList.push({ code: Industry.IT_HARDWARE, name: res });
        this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_INDUSTRIES_FACILITIES_MANAGEMENT').subscribe(res => {
        this.industryList.push({ code: Industry.FACILITIES_MANAGEMENT, name: res });
        this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_INDUSTRIES_MEDICAL_EQUIPMENT').subscribe(res => {
        this.industryList.push({ code: Industry.MEDICAL_EQUIPMENT, name: res });
        this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_INDUSTRIES_CONSTRUCTION').subscribe(res => {
        this.industryList.push({ code: Industry.CONSTRUCTION, name: res });
        this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_INDUSTRIES_MANUFACTURING').subscribe(res => {
        this.industryList.push({ code: Industry.MANUFACTURING, name: res });
        this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_INDUSTRIES_LOGISTICS').subscribe(res => {
        this.industryList.push({ code: Industry.LOGISTICS, name: res });
        this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_INDUSTRIES_BUILDING_TECHNOLOGIES').subscribe(res => {
        this.industryList.push({ code: Industry.BUILDING_TECHNOLOGIES, name: res });
        this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_INDUSTRIES_RESIDENTIAL_SERVICES').subscribe(res => {
        this.industryList.push({ code: Industry.RESIDENTIAL_SERVICES, name: res });
        this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_INDUSTRIES_SECURITY_SYSTEMS').subscribe(res => {
        this.industryList.push({ code: Industry.SECURITY_SYSTEMS, name: res });
        this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_INDUSTRIES_FIELD_MARKETING').subscribe(res => {
        this.industryList.push({ code: Industry.FIELD_MARKETING, name: res });
        this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
    }
    

    // Job title
    if (this.jobTitleList.length <= 0){
      this.translate.get('FORM_JOB_TITLE_CEO_FOUNDER').subscribe(res => {
        this.jobTitleList.push({ code: JobTitle.CEO_FOUNDER, name: res });
        this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_JOB_TITLE_SERVICE_MANAGER').subscribe(res => {
        this.jobTitleList.push({ code: JobTitle.SERVICE_MANAGER, name: res });
        this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_JOB_TITLE_FIELD_TECHNICIAN').subscribe(res => {
        this.jobTitleList.push({ code: JobTitle.FIELD_TECHNICIAN, name: res });
        this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_JOB_TITLE_BACK_OFFICE_USER').subscribe(res => {
        this.jobTitleList.push({ code: JobTitle.BACK_OFFICE_USER, name: res });
        this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_JOB_TITLE_HELPDESK_USER').subscribe(res => {
        this.jobTitleList.push({ code: JobTitle.HELPDESK_USER, name: res });
        this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_JOB_TITLE_CIO_IT_MANAGER').subscribe(res => {
        this.jobTitleList.push({ code: JobTitle.CIO_IT_MANAGER, name: res });
        this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_JOB_TITLE_CONSULTANT').subscribe(res => {
        this.jobTitleList.push({ code: JobTitle.CONSULTANT, name: res });
        this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
  
      this.translate.get('FORM_JOB_TITLE_DIRECTOR').subscribe(res => {
        this.jobTitleList.push({ code: JobTitle.DIRECTOR, name: res });
        this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
      });
    }
    

    if (this.pleaseSelect == ''){
      this.translate.get('lbl_shrt_PleaseSelect').subscribe(res => {
        this.pleaseSelect = res;
      });
    }
    
    if (this.odsTermsLink == ''){
      this.translate.get('lbl_shrt_WelloTerms').subscribe(res => {
        this.odsTermsLink = res;
        this.odsTermsLink = this.odsTermsLink.replace('[startOfWelloTerms]', '<a href="https://wello.solutions/legal/" target="_blank">');
        this.odsTermsLink = this.odsTermsLink.replace('[/startOfWelloTerms]', '</a>*');
      });
    }
    
    if (this.privacyPolicyLink == ''){
      this.translate.get('lbl_shrt_PrivacyPolicyLinkWello').subscribe(res => {
        this.privacyPolicyLink = res;
        this.privacyPolicyLink = this.privacyPolicyLink.replace('[startOfPrivacyPolicy]', '<a href="https://wello.solutions/privacy-note/" target="_blank">');
        this.privacyPolicyLink = this.privacyPolicyLink.replace('[/startOfPrivacyPolicy]', '</a>');
      });
    }
    
    if (this.newsEventsText == ''){
      this.translate.get('lbl_shrt_NewsAndEventsWello').subscribe(res => {
        this.newsEventsText = res;
      });
    }    
  }

  get demoFormCtrls() { return this.step_3Demo_Form.controls }
  get trialFormCtrls() { return this.step_3Trial_Form.controls }
  get step3Form() {
    if(this.step == Steps.Step_3_Demo){
      return this.step_3Demo_Form;
    }
    if(this.step == Steps.Step_3_Trial){
      return this.step_3Trial_Form;
    }
  }

  ngOnDestroy() {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  @HostBinding("class") get themeClass() {
    return "u-scroll o-flex__item o-flex o-flex--vertical"
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.calculateSpacerTop();
  }
}
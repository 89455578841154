import { Component, HostBinding, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { GridOptions, IGetRowsParams } from 'ag-grid-community'
import { User } from 'models/user'
import { AlertService, ImplementationsService, UserService, AuthenticationService } from 'services'
import moment from "moment"
import { FormBuilder, FormGroup, NgForm } from '@angular/forms'

@Component({
  selector: "[app-implementation]",
  templateUrl: "./implementation.component.html",
  styleUrls: ["./implementation.component.scss"]
})
export class ImplementationComponent implements OnInit {
  private gridApi
  private gridColumnApi
  public columnDefs
  public defaultColDef
  showInput = true
  gridOptions: GridOptions
  public rowData: any[]
  users: User[]= []
  currentLoggedUser:User
  searchText
  id
  enumD: any
  statusFilter: string
  callInProgress: boolean=false
  public impSearchCount: number=0
  public searchImpForm: FormGroup

  @ViewChild('agGrid',{static: false}) agGrid;
  @ViewChild('searchImpFormDirective', { static: true }) searchImpFormDirective: NgForm;
  constructor(
    private implementationsService: ImplementationsService,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder
  ) {
    this.enumD = {
      All: "All",
      AllNotArchived: "AllNotArchived",
      Trial: "Trial",
      Production: "Production",
      Test: "Test",
      Archived: "Archived"
    }
    this.statusFilter = "All"
    this.columnDefs = [
      { headerName: "Status", field: "StatusText", filter: "agSetColumnFilter", width: 80, cellClass: function(params) { return (params.value==='Archived'?'redBoldText':''); }},
      { headerName: "Company",field: "Company_name",filter: "agTextColumnFilter", minWidth: 150},
      { headerName: "Company email",field: "Company_email",filter: "agTextColumnFilter",minWidth: 150},
      { headerName: "Domain",field: "Company_domain",filter: "agTextColumnFilter",minWidth: 150},
      { headerName: "Added",field: "date_add", width: 70, filter: "agDateColumnFilter",
        cellRenderer: params => { return moment(params.value).format("DD-MMM-YYYY") },
        filterParams: {
          // applyButton: true,
          // resetButton: true,
          filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange'],
          inRangeInclusive: true, 
          comparator: function(filterLocalDateAtMidnight, cellValue) {
            
            let returnRez=0
            var dateAsString = moment(cellValue).format('DD/MM/YYYY')
            
            var dateParts = dateAsString.split("/")
            var day = Number(dateParts[0])
            var month = Number(dateParts[1]) - 1
            var year = Number(dateParts[2])
            var cellDate = new Date(year, month, day)

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      },
      { headerName: "Project Manager",  field: "Owner", filter: "agTextColumnFilter", minWidth: 100, colId: "OwnerId"  },
      {headerName: "Database name", field: "Database_name",filter: "agTextColumnFilter",minWidth: 170},
      {headerName: "Description", field: "description",filter: "agTextColumnFilter",minWidth: 200},
      {field: "Database_name",filter: "agTextColumnFilter",colId: "DatabaseNameHideColumnId", hide: true},
      {field: "archived",filter: "agSetColumnFilter",colId: "archived",hide: true }
    ]

    this.defaultColDef = {sortable: true, filter: true }
  }

  ngOnInit() {
    
    this.authenticationService.currentUser.subscribe(x=>this.currentLoggedUser=x)
    this.gridOptions = {
      rowModelType: 'serverSide',
      cacheBlockSize: 500,
      paginationPageSize: 100,
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 2,
      infiniteInitialRowCount: 1,
      maxBlocksInCache: 2
    } as GridOptions
    
    this.gridOptions.getRowStyle = function(params) {
      if (params.node.rowIndex % 2 === 0) {
          return { background: '#F0F0F0' }
      }
    }

    this.userService.getAllUsersExceptTheLogged().subscribe(
      data => {
        var loggedUser=this.currentLoggedUser
        this.users.splice(0, 0, loggedUser)
        if(data!=null){
          this.users.push(...data)
        }
      },
      error => {
        this.alertService.error(error.ResponseException.ExceptionMessage)
      }
    );

    this.searchImpForm = this.formBuilder.group({
      keywordSearch: [],
      owner: ['all'],
      dateAdded:['all'],
      status:['AllNotArchived']
    });
    this.SetFocusToControl();
  }

  searchImp(){
    let parameters = {
      keyword: this.searchImpForm.value['keywordSearch'],
      owner: this.searchImpForm.value['owner'],
      dateAdded: this.searchImpForm.value['dateAdded'],
      status: this.searchImpForm.value['status']
    };
    if(parameters.owner){
      let targetUser = this.users.find(x => x.fullname == parameters.owner);
      parameters['ownerId'] = targetUser ? targetUser.id : '00000000-0000-0000-0000-000000000000';
    }
    this.setDateFilterToParam(parameters);

    this.callInProgress = true;
    var datasource = {
      getRows: (params: IGetRowsParams) => {
        params['request'].perge = function() {
          this.gridOptions.api.deselectAll();
          this.gridOptions.api.purgeServerSideCache(undefined);
        };
        parameters['grid_server_params'] = params['request'];
        this.implementationsService.searchImplementation(parameters)
          .subscribe(resultForGrid => {
            this.callInProgress = false;
            this.impSearchCount += resultForGrid.length;
            var data = resultForGrid.length == this.gridOptions.cacheBlockSize + 1 ? resultForGrid.splice(0, resultForGrid.length == 1 ? 1 : resultForGrid.length - 1) : resultForGrid;
            params.successCallback(data, resultForGrid.length == 1 && data.length == this.gridOptions.cacheBlockSize ? params['request'].startRow + data.length + this.gridOptions.cacheBlockSize : params['request'].startRow + data.length);

            this.SetFocusToControl();
            this.agGrid.api.sizeColumnsToFit();
        },
        error => {
          this.agGrid.api.showNoRowsOverlay()
          this.agGrid.api.hideOverlay()
          this.callInProgress = false;
          this.SetFocusToControl();
        });
      }
    }
    this.gridApi.setServerSideDatasource(datasource);

    // this.implementationsService.getPerUser().subscribe(
    //   data => {
    //     this.rowData = data
    //     this.callInProgress = false;
    //     this.SetFocusToControl();
    //     this.agGrid.api.onFilterChanged();
    //     this.agGrid.api.sizeColumnsToFit();
    //   },
    //   error => {
    //     this.agGrid.api.showNoRowsOverlay()
    //     this.agGrid.api.hideOverlay()
    //     this.callInProgress = false;
    //     this.SetFocusToControl();
    //   }
    // )
  }

  //#region  Filters 
  // ddlOwnerChanged(event): void {
  //   var ownerFilterComponent = this.agGrid.api.getFilterInstance("OwnerId")
  //   if (event.target.value === "all") {
  //     ownerFilterComponent.setModel("")
  //   } else {
  //     ownerFilterComponent.setModel({
  //       type: "contains",
  //       filter: event.target.value
  //     })
  //   }
  //   this.agGrid.api.onFilterChanged()
  // }

  setDateFilterToParam(parameters){
    switch(this.searchImpForm.value['dateAdded']) {
      case 'all':
        parameters['dateFrom'] = null;
        parameters['dateTo'] = null;
        break;
      case 'today':
        parameters['dateFrom'] = moment().format('YYYY') + "-" + moment().format('M') + "-" + moment().format('D');
        parameters['dateTo'] = null;
        break
      case 'thisWeek':
        var weekStart=moment().startOf('isoWeek')
        var weekEnd=moment().endOf('isoWeek')
        parameters['dateFrom'] = weekStart.format('YYYY') + "-" + weekStart.format('M') + "-" + weekStart.format('D');
        parameters['dateTo'] = weekEnd.format('YYYY') + "-" + weekEnd.format('M') + "-" + weekEnd.format('D');
        break
      case 'lastWeek':
        var lastWeekStart=moment().subtract(1,'weeks').startOf('isoWeek')
        var lastWeekEnd=moment().subtract(1,'weeks').endOf('isoWeek')
        parameters['dateFrom'] = lastWeekStart.format('YYYY') + "-" + lastWeekStart.format('M') + "-" + lastWeekStart.format('D');
        parameters['dateTo'] = lastWeekEnd.format('YYYY') + "-" + lastWeekEnd.format('M') + "-" + lastWeekEnd.format('D');
        break
      case 'thisMonth':
        var currentMonthStart=moment().startOf('month')
        var currentMonthEnd=moment().endOf('month')
        parameters['dateFrom'] = currentMonthStart.format('YYYY') + "-" +currentMonthStart.format('M') + "-" + currentMonthStart.format('D');
        parameters['dateTo'] = currentMonthEnd.format('YYYY') + "-" + currentMonthEnd.format('M') + "-" +  currentMonthEnd.format('D');
        break
      case 'lastMonth':
        var lastMonthStart=moment().subtract(1, 'months').startOf('month')
        var lastMonthEnd=moment().subtract(1, 'months').endOf('month')
        parameters['dateFrom'] = lastMonthStart.format('YYYY') + "-" + lastMonthStart.format('M') + "-" + lastMonthStart.format('D');
        parameters['dateTo'] = lastMonthEnd.format('YYYY') + "-" + lastMonthEnd.format('M') + "-" + lastMonthEnd.format('D');
        break
      case 'thisYear':
        var currentYearStart=moment().startOf('year')
        var currentYearEnd=moment().endOf('year')
        parameters['dateFrom'] = currentYearStart.format('YYYY') + "-" + currentYearStart.format('M') + "-" + currentYearStart.format('D');
        parameters['dateTo'] = currentYearEnd.format('YYYY') + "-" + currentYearEnd.format('M') + "-" + currentYearEnd.format('D');
        break
      case 'lastYear':
        var lastYearStart=moment().subtract(1, 'years').startOf('year')
        var lastYearEnd=moment().subtract(1, 'years').endOf('year')
        parameters['dateFrom'] = lastYearStart.format('YYYY') + "-" + lastYearStart.format('M') + "-" + lastYearStart.format('D');
        parameters['dateTo'] = lastYearEnd.format('YYYY') + "-" + lastYearEnd.format('M') + "-" + lastYearEnd.format('D');
        break
      default:
    }
  }

  // onDateFilterChanged(event): void {
  //   var dateAddFilterComponent = this.agGrid.api.getFilterInstance("date_add")
    
  //    if (event.target.value === 'all') {
  //      dateAddFilterComponent.setModel(null)
  //    } else {

  //     var model={}
  //     switch(event.target.value) {
  //       case 'today':
  //         model={
  //             type: "equals",
  //             dateFrom: moment().format('YYYY') + "-" + moment().format('M') + "-" + moment().format('D'),
  //             dateTo: null
  //           }
  //         break
  //       case 'thisWeek':
  //         var weekStart=moment().startOf('isoWeek')
  //         var weekEnd=moment().endOf('isoWeek')
  //         model={
  //           type: "inRange",
  //           dateFrom: weekStart.format('YYYY') + "-" + weekStart.format('M') + "-" + weekStart.format('D'),
  //           dateTo: weekEnd.format('YYYY') + "-" + weekEnd.format('M') + "-" + weekEnd.format('D')
  //         }
  //         break
  //         case 'lastWeek':
  //            var lastWeekStart=moment().subtract(1,'weeks').startOf('isoWeek')
  //            var lastWeekEnd=moment().subtract(1,'weeks').endOf('isoWeek')
  //            model={
  //              type: "inRange",
  //              dateFrom: lastWeekStart.format('YYYY') + "-" + lastWeekStart.format('M') + "-" + lastWeekStart.format('D'),
  //              dateTo: lastWeekEnd.format('YYYY') + "-" + lastWeekEnd.format('M') + "-" + lastWeekEnd.format('D')
  //            }
  //           break
  //       case 'thisMonth':
  //         var currentMonthStart=moment().startOf('month')
  //         var currentMonthEnd=moment().endOf('month')
  //         model={
  //           type: "inRange",
  //           dateFrom: currentMonthStart.format('YYYY') + "-" +currentMonthStart.format('M') + "-" + currentMonthStart.format('D'),
  //           dateTo: currentMonthEnd.format('YYYY') + "-" + currentMonthEnd.format('M') + "-" +  currentMonthEnd.format('D')
  //         }
  //         break
  //       case 'lastMonth':
  //         var lastMonthStart=moment().subtract(1, 'months').startOf('month')
  //         var lastMonthEnd=moment().subtract(1, 'months').endOf('month')
  //         model={
  //           type: "inRange",
  //           dateFrom: lastMonthStart.format('YYYY') + "-" + lastMonthStart.format('M') + "-" + lastMonthStart.format('D'),
  //           dateTo: lastMonthEnd.format('YYYY') + "-" + lastMonthEnd.format('M') + "-" + lastMonthEnd.format('D')
  //         }
  //           break
  //       case 'thisYear':
  //         var currentYearStart=moment().startOf('year')
  //         var currentYearEnd=moment().endOf('year')
  //         model={
  //           type: "inRange",
  //           dateFrom: currentYearStart.format('YYYY') + "-" + currentYearStart.format('M') + "-" + currentYearStart.format('D'),
  //           dateTo: currentYearEnd.format('YYYY') + "-" + currentYearEnd.format('M') + "-" + currentYearEnd.format('D')
  //         }
  //           break
  //       case 'lastYear':
  //         var lastYearStart=moment().subtract(1, 'years').startOf('year')
  //         var lastYearEnd=moment().subtract(1, 'years').endOf('year')
  //         model={
  //           type: "inRange",
  //           dateFrom: lastYearStart.format('YYYY') + "-" + lastYearStart.format('M') + "-" + lastYearStart.format('D'),
  //           dateTo: lastYearEnd.format('YYYY') + "-" + lastYearEnd.format('M') + "-" + lastYearEnd.format('D')
  //         }
  //           break
  //       default:
  //         // code block
  //     }
  //     dateAddFilterComponent.setModel(model)
  //    }
  //   this.agGrid.api.onFilterChanged()
  // }

  // filterStatus(event: any, status: any) {
  //   this.statusFilter = status
  //   var statusFilterComponent = this.agGrid.api.getFilterInstance("StatusText")
  //   var archivedFilterComponent = this.agGrid.api.getFilterInstance("archived")
  //   statusFilterComponent.selectNothing()
  //   if (this.statusFilter === "All") {
  //     statusFilterComponent.setModel(null)
  //     archivedFilterComponent.setModel(null)
  //   } else if(this.statusFilter===this.enumD.Archived){
  //     var modelStatus = {
  //       type: "set",
  //       values: ['true']
  //     }
  //     archivedFilterComponent.setModel(modelStatus)
  //     statusFilterComponent.setModel(null)
      
  //   }
  //   else if(this.statusFilter===this.enumD.AllNotArchived){
  //     var modelStatus = {
  //       type: "set",
  //       values: ['false']
  //     }
  //     archivedFilterComponent.setModel(modelStatus)
  //     statusFilterComponent.setModel(null)      
  //   }
  //   else{
  //     var model = {
  //       type: "set",
  //       values: [this.statusFilter]
  //     }
  //     archivedFilterComponent.setModel(null)
  //     statusFilterComponent.setModel(model)
  //   }
  //   this.agGrid.api.onFilterChanged()
  // }

  // onKeywordFilter(event: any) {
  //   this.gridOptions.api.setQuickFilter(event.target.value)
  // }
  //#endregion

  onGridReady(params) {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    // this.callInProgress = true;

    // this.implementationsService.getPerUser().subscribe(
    //   data => {
    //     this.rowData = data
    //     this.callInProgress = false;
    //     this.SetFocusToControl();
    //   },
    //   error => {
    //     this.agGrid.api.showNoRowsOverlay()
    //     this.agGrid.api.hideOverlay()
    //     this.callInProgress = false;
    //     this.SetFocusToControl();
    //   }
    // )
    this.SetFocusToControl();
    this.agGrid.api.sizeColumnsToFit()
  }

  onFirstDataRendered(){
    this.agGrid.api.setSortModel([ {colId: 'date_add', sort: 'desc'} ]);
    this.agGrid.api.sizeColumnsToFit();
    //this.onDateFilterChanged({target:{value:'all'}})
    //this.filterStatus({},this.enumD.AllNotArchived)
  }

  onRowClicked(event: any) {
    this.id = event.data.Id
    this.router.navigate(["/implementationItem", this.id])
  }
  goToNewImp() {
    this.router.navigateByUrl("implementationNew")
  }

  SetFocusToControl() {
    setTimeout(function() {
      (<HTMLElement>document.getElementById('textFieldId7')).focus();
    }, 500);
  }

  @HostBinding("class") get themeClass() {
    return "u-scroll o-flex__item o-flex o-flex--vertical"
  }
}

export enum Steps {
    LoginInformation = 'LoginInformation',
    TrialInformation = 'TrialInformation',
    Processing = 'Processing',
    Step_1 = "Step_1",
    Step_2 = "Step_2",
    Step_3_Demo = "Step_3_Demo",
    Step_3_Trial = "Step_3_Trial",
    Step_3_GetPlan = "Step_3_GetPlan",
    Step_4 = "Step_4"
  }

export enum EmailType {
      FREE, BANNED, ALREADY_USED, BUSINESS, NOT_TRIAL, ALREADY_INVITED
  }

  export enum Industry {
    TELECOM = 'TELECOM',
    RENEWABLE_ENERGY = 'RENEWABLE_ENERGY',
    UTILITY = 'UTILITY',
    HVACR = 'HVACR',
    IT_HARDWARE = 'IT_HARDWARE',
    SECURITY_SYSTEMS = 'SECURITY_SYSTEMS',
    FACILITIES_MANAGEMENT = 'FACILITIES_MANAGEMENT',
    MEDICAL_EQUIPMENT = 'MEDICAL_EQUIPMENT',
    CONSTRUCTION = 'CONSTRUCTION',
    MANUFACTURING = 'MANUFACTURING',
    LOGISTICS = 'LOGISTICS',
    BUILDING_TECHNOLOGIES = 'BUILDING_TECHNOLOGIES',
    RESIDENTIAL_SERVICES = 'RESIDENTIAL_SERVICES',
    FIELD_MARKETING = 'FIELD_MARKETING',
  }

  export enum JobTitle {
    CEO_FOUNDER = 'CEO_FOUNDER',
    SERVICE_MANAGER = 'SERVICE_MANAGER',
    FIELD_TECHNICIAN = 'FIELD_TECHNICIAN',
    BACK_OFFICE_USER = 'BACK_OFFICE_USER',
    HELPDESK_USER = 'HELPDESK_USER',
    CIO_IT_MANAGER = 'CIO_IT_MANAGER',
    CONSULTANT = 'CONSULTANT',
    DIRECTOR = 'DIRECTOR',
  }

  export class TrialDto {
    public adminuseremail: string;
    public adminuserpassword: string;
    public password_confirm: string;
    public adminuserfirstname: string;
    public adminuserlastname: string;
    public defaultcountryname: string;
    public defaultlanguagename: string;
    public companyname: string;
    public phone_number: string;
    public industry: string;
    public nballowedusers: number;
    public istrial: boolean;
    public agreement: boolean;
    public onboardinggoal: string;
    public numberoftechnicians: number;
    public jobtitle : string;
    public news_events_text : string;
}

export class FreeTrialBannedIpDto {
  public ip_from: string;
  public ip_to: string;
  public description: string;
  public added_by_user_id: number;
}

export class FreeTrialBannedEmailDto {
  public email: string;
  public is_free: boolean;
  public added_by_user_id: number;
}

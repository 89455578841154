import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { initializePhraseAppEditor, PhraseAppCompiler} from 'ngx-translate-phraseapp'
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from "services"
import { environment } from 'environments/environment';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import localeVi from '@angular/common/locales/vi';
import localeEs from '@angular/common/locales/es';
import localeEl from '@angular/common/locales/el';
import localeHu from '@angular/common/locales/hu';
import localePl from '@angular/common/locales/pl';
import localeRu from '@angular/common/locales/ru';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeVi, 'vi');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeEl, 'el');
registerLocaleData(localeHu, 'hu');
registerLocaleData(localePl, 'pl');
registerLocaleData(localeRu, 'ru');

@Component({
  selector: '[appRoot]',
  templateUrl: './app.component.html',
  styleUrls: ["./app.component.scss"]
})
export class AppComponent{

   public showHeader: boolean = false
   public showSidebar : boolean= false
   public showFooter : boolean= false
   private isInitPhrase: boolean = false;
   public isTrial: boolean = false;
   public isTrialOnCompactVersion: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public translate: TranslateService, private alertService: AlertService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader
        this.showSidebar = this.activatedRoute.firstChild.snapshot.data.showSidebar
        this.showFooter = this.activatedRoute.firstChild.snapshot.data.showFooter
        this.isTrial = this.activatedRoute.firstChild.snapshot.url[0].path == 'free-trial' || this.activatedRoute.firstChild.snapshot.url[0].path == 'm-free-trial';
        this.activatedRoute.queryParams.subscribe(params => {
          this.isTrialOnCompactVersion = params['isCompactVersion'] == "true" ? true : false;
        });

        if (!this.isInitPhrase) {
          this.isInitPhrase = true;

          let phraseEnabled = sessionStorage['phrase_enabled'] == 'true'
                             || ((this.activatedRoute.firstChild.snapshot.url[0].path == 'subscription'
                                    || this.activatedRoute.firstChild.snapshot.url[0].path == 'free-trial'
                                    || this.activatedRoute.firstChild.snapshot.url[0].path == 'm-free-trial')
                                && this.activatedRoute.firstChild.snapshot.paramMap.get("phrase_enabled") == 'phraseapp');
          let config = {
            projectId: environment.phraseProjectId,
            phraseEnabled: phraseEnabled,
            prefix: "{{__",
            suffix: "__}}",
            fullReparse: false,
            autoLowercase: false
          };

          initializePhraseAppEditor(config);
        }
      }
    });

    translate.setDefaultLang('en');

    (<any>window).TriggerEvent = function(data) {
      alertService.notification(data);
    }
   }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService , AlertService } from '../services';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    networkStatus: boolean = false;
    networkStatus$: Subscription = Subscription.EMPTY;

    constructor(private authenticationService: AuthenticationService, private alertService: AlertService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request)
        .pipe(
          catchError(
            (error: HttpErrorResponse) => {
              this.checkNetworkStatus(error);
              
              const err = error.error;
              if (err.IsSuccess === false) {
                this.alertService.error(err.ResponseException.ExceptionMessage);
                if (err.StatusCode === 401) {
                  this.authenticationService.logout();
                  return throwError(err);
                }
                if(err.ResponseException.ValidationErrors!==null)
                  {
                    err.ResponseException.ValidationErrors.forEach(element => {
                      this.alertService.error(element.Field +" : " + element.Message);
                    });
                  }
              }
              return throwError(err);
            }));
    }

    checkNetworkStatus(error) {
      this.networkStatus = navigator.onLine;
      this.networkStatus$ = merge(
        of(null),
        fromEvent(window, 'online'),
        fromEvent(window, 'offline')
      )
        .pipe(map(() => navigator.onLine))
        .subscribe(status => {
          this.networkStatus = status;
          if (this.networkStatus == false){
            this.alertService.error("Please verify your internet connection");
            this.authenticationService.logout(); 
            this.networkStatus$.unsubscribe();
            return throwError(error);                         
          }
          else{
            if (error.status === 0) {
              this.alertService.error("The server is not started.");
              this.authenticationService.logout();
              this.networkStatus$.unsubscribe();
              return throwError(error);
            }
          }

          this.networkStatus$.unsubscribe();
        });
    }
}

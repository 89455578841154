import { Component, HostBinding, NgModule, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { User } from 'models/user'
import { AlertService, ImplementationsService, UserService, AuthenticationService } from 'services'

@Component({
  selector: "[app-user-management]",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"]
})

export class UserManagementComponent implements OnInit {
  public rowData: any[]
  users: User[]= []
  currentLoggedUser:User
  selectedUser:User
  searchText
  id
  loading: Boolean = false

  @ViewChild('agGrid',{static: false}) agGrid;
  constructor(
    private implementationsService: ImplementationsService,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) {    

    
  }

    ngOnInit() {

      this.authenticationService.currentUser.subscribe(x=>this.currentLoggedUser=x)

      this.callInProgress(true);      
      this.userService.getAllUsersExceptTheLogged().subscribe(
        data => {
          var loggedUser=this.currentLoggedUser      
          this.users.splice(0, 0, loggedUser)

          if(data!=null){
            this.users.push(...data)     
            this.users.sort(this.dynamicSort('fullname'));  
            this.selectedUser = this.users[0];                 
          }          
          this.callInProgress(false);
        },
        error => {
          this.callInProgress(false);
          this.alertService.error(error.ResponseException.ExceptionMessage)
        }
      )
    }

    ddlUserChanged(event): void {
      this.selectedUser = this.users[event.target.selectedIndex]
    }
  
    onFirstDataRendered(){
      // filter companies for logged user as default at the first time
      var projectManagerFilterComponent = this.agGrid.api.getFilterInstance("UserId")
      projectManagerFilterComponent.setModel({
        type: "contains",
        filter: this.currentLoggedUser.fullname
      })
      this.agGrid.api.onFilterChanged()

      // sort by company name
      this.agGrid.api.setSortModel([ {colId: 'Company_name', sort: 'asc'} ])
    }

    callInProgress(inProgress: boolean){
      this.loading = inProgress
    }

    dynamicSort(property) {
      var sortOrder = 1;
      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }
      return function (a,b) {
          var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
          return result * sortOrder;
      }
    }

    onUserSubmit(){
      this.callInProgress(true);
      this.userService.updateUser(this.selectedUser).subscribe(
        data => {
          this.callInProgress(false);
          this.alertService.success("User is updated");
      },
      error =>{
        this.callInProgress(false);
        this.alertService.error("Update failed: " + error.ResponseException.ExceptionMessage)
      })
    }
  
    @HostBinding("class") get themeClass() {
      return "u-scroll o-flex__item o-flex o-flex--vertical"
    }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: "root"
})
export class ImplementationsService {
  constructor(private http: HttpClient) {}

  getPerUser() {
    return this.http
      .get<any>(`${environment.apiUrl}/implementation/user`)
      .pipe(map(x => x.Result));
  }

  searchImplementation(params) {
    return this.http.post<any>(`${environment.apiUrl}/implementation/search`, params)
                    .pipe(map(x => x.Result));
  }

  getAll(){
    return this.http
      .get<any>(`${environment.apiUrl}/Implementation`)
      .pipe(map(x => x.Result));
  }

  getAllConsultancy(){
    return this.http
      .get<any>(`${environment.apiUrl}/ConsultancyOverview/list`)
      .pipe(map(x => x.Result));
  }

  getById(id: any) {
    return this.http
      .get<any>(`${environment.apiUrl}/implementation/${id}`)
      .pipe(map(x=>x));
  }

  extendTrial(user_id, implementation_id) {
    return this.http
      .post<any>(`${environment.apiUrl}/implementation/extendTrial`, {user_id: user_id,implementation_id: implementation_id})
      .pipe(map(x => x.Result));
  }

  getInfo(id){
    return this.http
      .get<any>(`${environment.apiUrl}/implementation/info/${id}`)
      .pipe(map(x=>x));
  }

  saveLicenceInfo(id, listOfValues)
  {
    //console.log(dto)
    return this.http
      .post<any>(`${environment.apiUrl}/implementation/info/${id}`, listOfValues)
      .pipe(map(x => x.Result));
  }

  AddManualLicenceTransaction(implementation_id, occ_user_id, description, internal_description, credit_type_code, balance, dateutc) {
    return this.http
      .post<any>(`${environment.apiUrl}/LicenceTransaction/AddManual/${implementation_id}/${occ_user_id}`, {implementation_id, occ_user_id, description, internal_description, credit_type_code, balance, dateutc})
      .pipe(map(x => x.Result));
  }

  allowInvoice(implementation_id, allow_invoice, due_days, required_po_number) {
    return this.http
      .post<any>(`${environment.apiUrl}/implementation/allowInvoice/${implementation_id}`, {allow_invoice: allow_invoice, due_days: due_days, required_po_number: required_po_number})
      .pipe(map(x => x.Result));
  }

  updateManagerId(implementation_id, manager_id, is_project_manager) {
    return this.http
      .post<any>(`${environment.apiUrl}/implementation/updatemanagerid/${implementation_id}/${manager_id}`, {is_project_manager: is_project_manager})
      .pipe(map(x => x.Result));
  }

  updateDescription(id, description) {
    return this.http
      .post<any>(`${environment.apiUrl}/implementation/updateDescription/${id}`, {description: description})
      .pipe(map(x => x.Result));
  }

  getActivities(id){
    return this.http
      .get<any>(`${environment.apiUrl}/implementation/activities/${id}`)
      .pipe(map(x=>x));
  }

  updateAdvanced(id, listOfValues)
  {
    return this.http
      .post<any>(`${environment.apiUrl}/implementation/updateAdvanced/${id}`, listOfValues)
      .pipe(map(x => x.Result));
  }

  getAllUsers(id){
    return this.http
      .get<any>(`${environment.apiUrl}/implementation/getallusers/${id}`)
      .pipe(map(x=>x));
  }

  getActivitiesByUser(id, user_id){
    return this.http
      .get<any>(`${environment.apiUrl}/implementation/activitiesbyuser/${id}/${user_id}`)
      .pipe(map(x=>x));
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { PaymentDetails, StripeCustomer, Card, SaveSubscriptionDto, CancelSubscriptionDto } from 'models/SubscriptionDto';

const httpOptions = {
  headers: new HttpHeaders({ 'Occidentifier': environment.accessToken })
};

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  constructor(private http: HttpClient) { }


  get(id, obs_user_id)
  {
    if (obs_user_id)
      return this.http.get<any>(`${environment.apiUrl}/OdysseeSubscription/${id}/${obs_user_id}`).pipe(map(x => x.Result));
    else
      return this.http.get<any>(`${environment.apiUrl}/OdysseeSubscription/${id}`).pipe(map(x => x.Result));
  }

  getCard(customer_id,card_id)
  {
    return this.http.get<any>(`${environment.apiUrl}/OdysseePayment/card/${customer_id}/${card_id}`).pipe(map(x => x.Result));
  }

  getCustomer(customer_id)
  {
    return this.http.get<any>(`${environment.apiUrl}/OdysseePayment/customer/${customer_id}`, httpOptions).pipe(map(x => x.Result));
  }
  getCountries()
  {
    return this.http.get<any>(`${environment.apiUrl}/OdysseePayment/countries`).pipe(map(x => x.Result));
  }

  getAllPrices()
  {
    return this.http.get<any>(`${environment.apiUrl}/OdysseePayment/price`).pipe(map(x => x.Result));
  }

  getAllPricesIncludingCurrentPlan(current_plan_id)
  {
    return this.http.get<any>(`${environment.apiUrl}/OdysseePayment/price/${current_plan_id}`).pipe(map(x => x.Result));
  }

  saveCard(customer_id, card: Card) {
    return this.http
      .put<any>(`${environment.apiUrl}/OdysseePayment/card/${customer_id}`, card)
      .pipe(map(x => x.Result));
  }

  upgradeMyPlan(implementation_id, stripe_coupon, save_subscription_dto: SaveSubscriptionDto) {
    return this.http
      .post<any>(`${environment.apiUrl}/OdysseeSubscription/info/${implementation_id}/${stripe_coupon}`, save_subscription_dto)
      .pipe(map(x => x.Result));
  }

  updateCustomer(customer_id, customer: any) {
    return this.http
      .put<any>(`${environment.apiUrl}/OdysseePayment/customer/${customer_id}`, customer)
      .pipe(map(x => x.Result));
  }

  stripTaxTypes(){
    return this.http.get(`${environment.baseUrl}/_files/StripeTaxTypes.json`).pipe(map(x=>x));
  }

  getCouponValue(coupon_code)
  {
    return this.http.get<any>(`${environment.apiUrl}/OdysseePayment/getcouponvalue/${coupon_code}`).pipe(map(x => x.Result));
  }

  cancelSubscription(id, cancelSubscriptionDto: CancelSubscriptionDto) {
    return this.http
      .post<any>(`${environment.apiUrl}/OdysseeSubscription/cancel/${id}`, cancelSubscriptionDto)
      .pipe(map(x => x.Result));
  }

  getLicenceTransaction(implementation_id, limit)
  {
    if (limit)
      return this.http.get<any>(`${environment.apiUrl}/OdysseeSubscription/licencetransaction/${implementation_id}/${limit}`).pipe(map(x => x.Result));
    else
      return this.http.get<any>(`${environment.apiUrl}/OdysseeSubscription/licencetransaction/${implementation_id}`).pipe(map(x => x.Result));
  }

  isInvoiceMode(customer_id)
  {
    return this.http.get<any>(`${environment.apiUrl}/OdysseePayment/isinvoicemode/${customer_id}`).pipe(map(x => x.Result));
  }

  isValidPayment(customer_id, implementation_id)
  {
    return this.http.get<any>(`${environment.apiUrl}/OdysseePayment/isvalidpayment/${customer_id}/${implementation_id}`).pipe(map(x => x.Result));
  }

  allowInvoice(implementation_id)
  {
    return this.http.get<any>(`${environment.apiUrl}/OdysseePayment/allowinvoice/${implementation_id}`).pipe(map(x => x.Result));
  }

  computeAmount(customer_id, updated_product_list) {
    return this.http
      .post<any>(`${environment.apiUrl}/OdysseePayment/computeamount/${customer_id}`, updated_product_list)
      .pipe(map(x => x.Result));
  }

  updateCustomerPoNumber(customer_id, po_number: any) {
    return this.http
      .put<any>(`${environment.apiUrl}/OdysseePayment/updateponumber/${customer_id}`, po_number)
      .pipe(map(x => x.Result));
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../services';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private AuthService: AuthenticationService) { }

  canActivate(next:ActivatedRouteSnapshot, state:RouterStateSnapshot) {
    return this.AuthService.isLoggedIn.pipe(map(res => {
      if (res!==true) {
        this.router.navigate(['/login'])
        return false;
      } else {
        return true;
      }
    }),
    catchError((err) => {
      return of(false);
    })
  )
} 
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from '../environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpClient } from '@angular/common/http';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private _isAuthenticatedSubject = new BehaviorSubject<boolean>(this.isAuthenticatedLocal)
  private _currentUser=new BehaviorSubject<User>(this.getUserData)
  constructor(private http: HttpClient, private router: Router, private jwtHelper: JwtHelperService) {
    //we need this to persist the data on page refresh 
    this._isAuthenticatedSubject=new BehaviorSubject<boolean>(this.isAuthenticatedLocal)
    this._currentUser=new BehaviorSubject<User>(this.getUserData)
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/authentication`, { username, password })
    .pipe(map(res => {
      if (res.IsSuccess===true) {
        var token=res.Result
        this.setLocalToken=token
        this._currentUser.next(this.getUserData)
        this._isAuthenticatedSubject.next(true)
    }
    else{
      this.logout()
    }
      return res.IsSuccess
    }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('jWtToken')
    this._isAuthenticatedSubject.next(false)
    this._currentUser.next(null)
    this.router.navigate(['/login'])
  }

  
  get isLoggedIn(): Observable<boolean>{
    return this._isAuthenticatedSubject.asObservable()
  }

  get currentUser(): Observable<User>{
    return this._currentUser.asObservable()
  }
  private get isAuthenticatedLocal():boolean{
    return !this.isTokenExpired
  }
  private get isTokenExpired():boolean{
    return this.hasToken?this.jwtHelper.isTokenExpired(this.getLocalToken):true
  }

  private get getTokenExpirationDate(){
    return this.hasToken?this.jwtHelper.getTokenExpirationDate(this.getLocalToken):null
  }

  private get getUserData(): User{
    return this.hasToken?JSON.parse(this.jwtHelper.decodeToken(this.getLocalToken).UserData): new User
  }
  private get hasToken() : boolean {
    return !!this.getLocalToken
  }

  private get getLocalToken(): string 
  {
    return localStorage.getItem('jWtToken')
  }

  private set setLocalToken(token: string){
    localStorage.setItem('jWtToken', JSON.stringify(token))
  }

}

import { Component, HostBinding, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { GridOptions } from 'ag-grid-community'
import { User } from 'models/user'
import { AlertService, ImplementationsService, UserService, AuthenticationService } from 'services'
import moment from "moment"

@Component({
  selector: "[app-consultancy-overview]",
  templateUrl: "./consultancy-overview.component.html",
  styleUrls: ["./consultancy-overview.component.scss"]
})

export class ConsultancyOverviewComponent implements OnInit {
  private gridApi
  private gridColumnApi
  public columnDefs
  public defaultColDef
  showInput = true
  gridOptions: GridOptions
  public rowData: any[]
  users: User[]= []
  currentLoggedUser:User
  searchText
  id
  callInProgress: boolean=false

  @ViewChild('agGrid',{static: false}) agGrid;
  constructor(
    private implementationsService: ImplementationsService,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) {

    this.columnDefs = [     
      { headerName: "Company",field: "CompanyName",filter: "agTextColumnFilter", minWidth: 150,
        cellRenderer: function (param) {
        return '<a href="/implementationItem/' + param['data'].Id + '?credits=true" target="_blank">' + param['data'].CompanyName + '</a>';
        }
      },
      { headerName: "Project Manager",  field: "UserName", filter: "agTextColumnFilter", minWidth: 100, colId: "UserId"  },
      { headerName: "Account Manager",field: "AccountManagerUserName",filter: "agTextColumnFilter",minWidth: 100},
      { headerName: "Description", field: "Description", filter: "agTextColumnFilter", minWidth: 250},
      { headerName: "Remaining Credits", field: "RemainingCredits",filter: "agTextColumnFilter",minWidth: 170},
      { headerName: "This Week", field: "ThisWeekBalance",filter: "agTextColumnFilter",minWidth: 170,
        cellRenderer: function (param) {
          if (param['data'].ThisWeekBalance > 0 && param['data'].ThisWeekBalance < param['data'].RemainingCredits && param['data'].RemainingCredits > 0){
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].ThisWeekBalance+' </label>' +
                    '<div style="background-color:green; width:20px; height:25px; margin-left:5px;"></div>' + 
                  '</div>';
          }
          else if (param['data'].ThisWeekBalance > param['data'].RemainingCredits && param['data'].RemainingCredits > 0){
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].ThisWeekBalance+' </label>' +
                    '<div style="background-color:orange; width:20px; height:25px; margin-left:5px;"></div>' + 
                  '</div>';
          }
          else if (param['data'].ThisWeekBalance > 0 && param['data'].RemainingCredits <= 0) {
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].ThisWeekBalance+' </label>' +
                    '<div style="background-color:red; width:20px; height:25px; margin-left:5px;"></div>' + 
                  '</div>';
          }
          else{
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].ThisWeekBalance+' </label>' +                    
                    '</div>';
          }
        }
      },
      { headerName: "Last Week", field: "LastWeekBalance",filter: "agTextColumnFilter",minWidth: 170,
        cellRenderer: function (param) {
          if (param['data'].LastWeekBalance > 0 && param['data'].LastWeekBalance < param['data'].RemainingCredits && param['data'].RemainingCredits > 0){
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].LastWeekBalance+' </label>' +
                    '<div style="background-color:green; width:20px; height:25px; margin-left:5px;"></div>' + 
                  '</div>';
          }
          else if (param['data'].LastWeekBalance > param['data'].RemainingCredits && param['data'].RemainingCredits > 0){
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].LastWeekBalance+' </label>' +
                    '<div style="background-color:orange; width:20px; height:25px; margin-left:5px;"></div>' + 
                  '</div>';
          }
          else if (param['data'].LastWeekBalance > 0 && param['data'].RemainingCredits <= 0) {
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].LastWeekBalance+' </label>' +
                    '<div style="background-color:red; width:20px; height:25px; margin-left:5px;"></div>' + 
                  '</div>';
          }
          else{
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].LastWeekBalance+' </label>' +
                    '</div>';
          }
        }
      },
      { headerName: "Last 4 Weeks", field: "Last4WeeksBalance",filter: "agTextColumnFilter",minWidth: 170,
        cellRenderer: function (param) {
          if (param['data'].Last4WeeksBalance > 0 && param['data'].Last4WeeksBalance < param['data'].RemainingCredits && param['data'].RemainingCredits > 0){
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].Last4WeeksBalance+' </label>' +
                    '<div style="background-color:green; width:20px; height:25px; margin-left:5px;"></div>' + 
                  '</div>';
          }
          else if (param['data'].Last4WeeksBalance > param['data'].RemainingCredits && param['data'].RemainingCredits > 0){
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].Last4WeeksBalance+' </label>' +
                    '<div style="background-color:orange; width:20px; height:25px; margin-left:5px;"></div>' + 
                  '</div>';
          }
          else if (param['data'].Last4WeeksBalance > 0 && param['data'].RemainingCredits <= 0) {
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].Last4WeeksBalance+' </label>' +
                    '<div style="background-color:red; width:20px; height:25px; margin-left:5px;"></div>' + 
                  '</div>';
          }
          else{
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].Last4WeeksBalance+' </label>' +
                    '</div>';
          }
        }
      },
      { headerName: "Last 12 Weeks", field: "Last12WeeksBalance",filter: "agTextColumnFilter",minWidth: 170,
        cellRenderer: function (param) {
          if (param['data'].Last12WeeksBalance > 0 && param['data'].Last12WeeksBalance < param['data'].RemainingCredits && param['data'].RemainingCredits > 0){
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].Last12WeeksBalance+'   ' +' </label>' +
                    '<div style="background-color:green; width:20px; height:25px; margin-left:5px;"></div>' + 
                  '</div>';
          }
          else if (param['data'].Last12WeeksBalance > param['data'].RemainingCredits && param['data'].RemainingCredits > 0){
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].Last12WeeksBalance+' </label>' +
                    '<div style="background-color:orange; width:20px; height:25px; margin-left:5px;"></div>' + 
                  '</div>';
          }
          else if (param['data'].Last12WeeksBalance > 0 && param['data'].RemainingCredits <= 0) {
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].Last12WeeksBalance+' </label>' +
                    '<div style="background-color:red; width:20px; height:25px; margin-left:5px;"></div>' + 
                  '</div>';
          }
          else{
            return  '<div style="display:flex; align-items:center;">' + 
                    '<label style="width:15%;">'+param['data'].Last12WeeksBalance+' </label>' +
                    '</div>';
          }
        }
      },      
      /* { headerName: "Estimation", width: 120,
        cellStyle: params => {
          if ((params['data'].Last4WeeksBalance < params['data'].RemainingCredits) && params['data'].RemainingCredits > 0)
            return {
              backgroundColor: 'green'
            };
          else if ((params['data'].Last4WeeksBalance > params['data'].RemainingCredits) && params['data'].RemainingCredits > 0)
            return {backgroundColor: 'orange'};
          else if ((params['data'].Last4WeeksBalance > 0 && params['data'].RemainingCredits == 0) || params['data'].RemainingCredits < 0)
            return {backgroundColor: 'red'};
        }        
      } */
    ]

    this.defaultColDef = {sortable: true, filter: true }
  }

    ngOnInit() {
      this.authenticationService.currentUser.subscribe(x=>this.currentLoggedUser=x)
      this.gridOptions = {} as GridOptions
      
      this.gridOptions.getRowStyle = function(params) {
        if (params.node.rowIndex % 2 === 0) {
            return { background: '#F0F0F0' }
        }
      }

      this.userService.getAllUsersAreInCharge().subscribe(
        data => {
          //var loggedUser=this.currentLoggedUser
          //this.users.splice(0, 0, loggedUser)
          if(data!=null){
            this.users.push(...data)
          }          
        },
        error => {
          this.alertService.error(error.ResponseException.ExceptionMessage)
        }
      )
    }

    ddlOwnerChanged(event): void {
      var projectManagerFilterComponent = this.agGrid.api.getFilterInstance("UserId")
      if (event.target.value === "all") {
        projectManagerFilterComponent.setModel("")
      } else {
        projectManagerFilterComponent.setModel({
          type: "contains",
          filter: event.target.value
        })
      }
      this.agGrid.api.onFilterChanged()
    }

    onKeywordFilter(event: any) {
      this.gridOptions.api.setQuickFilter(event.target.value)
    }

    onGridReady(params) {
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
      this.callInProgress = true;
      this.implementationsService.getAllConsultancy().subscribe(
        data => {
          this.rowData = data      
          this.callInProgress = false;    
          this.SetFocusToControl();
        },
        error => {
          this.agGrid.api.showNoRowsOverlay()
          this.agGrid.api.hideOverlay()
          this.callInProgress = false;
          this.SetFocusToControl();
        }
      )
      this.agGrid.api.sizeColumnsToFit()      
    }
  
    onFirstDataRendered(){
      // filter companies for logged user as default at the first time
      var projectManagerFilterComponent = this.agGrid.api.getFilterInstance("UserId")
      projectManagerFilterComponent.setModel({
        type: "contains",
        filter: this.currentLoggedUser.fullname
      })
      this.agGrid.api.onFilterChanged()

      // sort by company name
      this.agGrid.api.setSortModel([ {colId: 'Company_name', sort: 'asc'} ])
    }

    SetFocusToControl() {
      // set focus on text search
      setTimeout(function() {
        (<HTMLElement>document.getElementById('textFieldId7')).focus();
      }, 500);
    }
  
    @HostBinding("class") get themeClass() {
      return "u-scroll o-flex__item o-flex o-flex--vertical"
    }
}
import { Component, OnInit, HostBinding, Inject } from '@angular/core';
import { UserService, AlertService, DataBaseUtilService, AuthenticationService, DbserverService } from 'services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from "@angular/platform-browser"
import { User } from 'models/user';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
@Component({
  selector: "[app-implementation-new]",
  templateUrl: "./implementation-new.component.html",
  styleUrls: ["./implementation-new.component.scss"]
})
export class ImplementationNewComponent implements OnInit {
  newImpForm: FormGroup
  isSuperAdmin: boolean 
  users: User[]
  currencyList: any[]
  languageList: any[]
  countryList: any[]
  dbServersList: any[]
  DbServerDefaultValue:string="Random"
  emailValid: boolean=false
  submitted = false
  showAdvanceSettings: boolean=false
  loading:boolean
  saveButtonText
  min_nb_mobile_user: number = 5;
  private currentCUser:User
  constructor(
    private userService: UserService, private formBuilder: FormBuilder,
    private alertService: AlertService, private router: Router,
    private databaseUtilService: DataBaseUtilService, private authenticationService: AuthenticationService,
    private dbServerService:DbserverService, private title: Title
  ) { }

  ngOnInit() {
    this.min_nb_mobile_user = environment.min_nb_mobile_user;
    this.callInProgress(false)
    this.alertService.clear()
    this.title.setTitle("New implementation")
    this.authenticationService.currentUser.subscribe(data=>this.currentCUser=data)
    if(!this.currentCUser.CanCreateNewImplementation) {
       this.router.navigate(["/"])
    }

    this.getLanguages()
    this.getCountries()
    this.getCurrency()
    this.getAllUsersExceptTheLogged()
    this.getAllDbServers()
    
    this.newImpForm = this.formBuilder.group({
      companyname:  ['', Validators.minLength(3)],
      companydomain: [],
      companyemail: [''], //configureit@odysseemobile.com
      adminuserfirstname:[''],
      adminuserlastname:[''],
      adminuseremail:['',Validators.required],
      nballowedusers: ["5", Validators.compose([Validators.min(this.min_nb_mobile_user)])],
      nballowedregularusers: ["10"],
      subcontractorcredit: ["10"],
      istrial: ['trial'],
      defaultlanguagename: [''],
      defaultcurrencyname: [''],
      defaultcountryname: [],
      userid: [''],
      dbservercode:[this.DbServerDefaultValue],
      ddlLicencePack:[''],
      databasename:[''], 
      sendinviteemail:[true],
      account_manager_user_id: [''],
      allow_portaldev:[false]
    })
  }

  isGuid(stringToTest) {
    if (stringToTest[0] === "{") {
      stringToTest = stringToTest.substring(1, stringToTest.length - 1);
    }
    var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
  }

  get f() { return this.newImpForm.controls }

  onSubmit(){
    this.submitted = true
    if (this.newImpForm.invalid) { return }

    this.newImpForm.value.dbservercode=this.newImpForm.value.dbservercode===this.DbServerDefaultValue?"":this.newImpForm.value.dbservercode
    this.newImpForm.value.databasename=this.newImpForm.value.databasename===""?"" :"obs_"+this.newImpForm.value.databasename
    this.callInProgress(true)
    this.databaseUtilService.checkIfAdminEmailExists(this.newImpForm.value.adminuseremail).subscribe(
      data=>{ 
          if(data=="true")
          {
            this.alertService.error("There is another admin with this email. Please use another email address.")
            this.callInProgress(false)
          }
          else{
            this.databaseUtilService.createNewDatabase(this.newImpForm.value).subscribe(data=>{
              if(data.Success)
              {
                this.alertService.success("Implementation has been successful saved.")
                const id=data.NewImpId
                if(this.isGuid(id))
                  this.router.navigate(["/implementationItem", id])
              }
              else{
                this.alertService.error(data.result.ErrorMessage)
              }
              this.callInProgress(false)
            })
          }
        }
    )
  }
  getLanguages(){
    this.databaseUtilService.getLanguages().subscribe(data => {
      this.languageList=data
      var defaultLangObj=this.languageList.filter( (c) => { return c.is_default==true} )[0]
      var defaultLang=defaultLangObj==null?this.languageList[0].id:defaultLangObj.name
      this.newImpForm.get('defaultlanguagename').setValue(defaultLang)
    });
  }
  getCountries(){
    this.databaseUtilService.getCountries().subscribe(data => {
      this.countryList=data
      //this.countryList.splice(0, 0, {'id':'', 'country_name':'Select a country'});
      var defaultCountryObj=this.countryList.filter( (c) => { return c.country_name=="Belgium"} )[0]
      var defaultCountry=defaultCountryObj==null?this.countryList[0].country_name:defaultCountryObj.country_name
      this.newImpForm.get('defaultcountryname').setValue(defaultCountry);
    });

  }
  getCurrency(){
    this.databaseUtilService.getCurrency().subscribe(data => {
      this.currencyList=data
      if(this.currencyList.length>0){
        var defaultCurrencyObj=this.currencyList.filter( (c) => { return c.is_default==true} )[0]
        var defaultCurrency=defaultCurrencyObj==null?this.languageList[0].id:defaultCurrencyObj.name
        this.newImpForm.get('defaultcurrencyname').setValue(defaultCurrency)
      }
    });
  }
  
  getAllUsersExceptTheLogged(){
    this.userService.getAllUsersExceptTheLogged().subscribe(
      data => {
        var loggedUser=this.currentCUser
          
        if(data!=null){
          this.users = data
        }
        
        this.users.splice(0, 0, loggedUser)
        this.newImpForm.get('userid').setValue(loggedUser.id)
        this.newImpForm.get('account_manager_user_id').setValue(loggedUser.id)
      },
      error => {
        this.alertService.error(error.ResponseException.ExceptionMessage)
      }
    );
  }
  getAllDbServers()
  {
    this.dbServerService.getAll().subscribe(
      data => {
        if(data!=null){
          this.dbServersList = data;
        }
        this.dbServersList.splice(0, 0, {code: this.DbServerDefaultValue})
      },
      error => {
        this.alertService.error(error.ResponseException.ExceptionMessage);
      }
    );
  }

  showAdvanceSettingsEvt(event){
      event.preventDefault()
      event.stopPropagation()
      this.showAdvanceSettings=!this.showAdvanceSettings
      this.newImpForm.get('databasename').setValue('')
      this.newImpForm.get('dbservercode').setValue(this.DbServerDefaultValue)
  }
  callInProgress(inProgress: boolean){
    this.loading = inProgress
    this.saveButtonText=inProgress?"Saving":"Save"
  }
  cancel() {
    this.router.navigate(["/implementation"])
  }

  @HostBinding("class") get themeClass() {
    return "u-scroll o-flex__item o-flex o-flex--vertical"
  }
}

import { Component, HostBinding, NgModule, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { User } from 'models/user'
import { AlertService, AuthenticationService, PowerBiReport } from 'services'
//import * as PowerbiClient from 'powerbi-client';

@Component({
  selector: "[app-powerbi-report]",
  templateUrl: "./powerbi-report.component.html",
  styleUrls: ["./powerbi-report.component.scss"]
})

export class PowerBiReportComponent implements OnInit {
  public rowData: any[]
  currentLoggedUser:User
  loading: Boolean = false
  workspaces: any[]
  reports: any[]
  selectedWorkspaceId: string=""
  reportMode: boolean = true
  languageCode: string="en-EN"

  @ViewChild('agGrid',{static: false}) agGrid;
  constructor(
    private router: Router,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private PowerBiReport: PowerBiReport,
    private route: ActivatedRoute
  ) {    
    
  }

    ngOnInit() {
      this.languageCode = this.route.snapshot.paramMap.get("language")
      this.authenticationService.currentUser.subscribe(x=>this.currentLoggedUser=x)
      this.listAllWorkspaces();
    }

    callInProgress(inProgress: boolean){
      this.loading = inProgress
    }

    listAllWorkspaces(){
      this.callInProgress(true)
      this.PowerBiReport.listAllworkspaces().subscribe(data => {
        this.workspaces = data
        this.callInProgress(false)
      },
      error =>{
        this.callInProgress(false)
      })
    }    

    ddlWorkSpaceChanged(event): void {
      if (event.target.value === "select") {
        this.selectedWorkspaceId = ""
        this.reports = []
        return
      }

      this.callInProgress(true)
      this.selectedWorkspaceId = event.target.value
      this.PowerBiReport.listallReportsOfWorkspace(event.target.value).subscribe(data => {
        this.reports = data
        this.callInProgress(false)
      },
      error =>{
        this.callInProgress(false)
      })            
    }

    ddlReportChanged(event): void {
      if (event.target.value === "select") {                
        return
      }

      this.showReport(this.selectedWorkspaceId, event.target.value)      
    }

    showReport(workspace_id, report_id){
      this.callInProgress(true)
      this.PowerBiReport.getEmbedParams(workspace_id, report_id).subscribe(data => {
        this.callInProgress(false)

        let embedParams = data;

        let models = window["powerbi-client"].models;
        let reportContainer = document.getElementById('report-container');

        let reportLoadConfig = {
          type: "report",
          tokenType: models.TokenType.Embed,
          accessToken: embedParams.EmbedToken.token,
          // You can embed different reports as per your need
          embedUrl: embedParams.EmbedReport[0].EmbedUrl + '&language=' + this.languageCode.toLocaleLowerCase() + '-' + this.languageCode.toLocaleUpperCase(),

          // Enable this setting to remove gray shoulders from embedded report
          // settings: {
          //     background: models.BackgroundType.Transparent
          // }
          settings: {
              panes: {
                  pageNavigation: {
                      visible: true,
                      position: models.PageNavigationPosition.Left,
                  }
              }
          }
        };

        // Use the token expiry to regenerate Embed token for seamless end user experience
        // Refer https://aka.ms/RefreshEmbedToken
        let tokenExpiry = embedParams.EmbedToken.expiration;

        // Embed Power BI report when Access token and Embed URL are available
        var report = window['powerbi'].embedNew(reportContainer, reportLoadConfig);

        // Clear any other loaded handler events
        report.off("loaded");

        // Triggers when a report schema is successfully loaded
        report.on("loaded", function () {
            console.log("Report load successful");
        });

        // Clear any other rendered handler events
        report.off("rendered");

        // Triggers when a report is successfully embedded in UI
        report.on("rendered", function () {
            console.log("Report render successful");
        });

        // Clear any other error handler events
        report.off("error");
        
        // Handle embed errors
        report.on("error", function (event) {
            var errorMsg = event.detail;
        
            // Use errorMsg variable to log error in any destination of choice
            console.error(errorMsg);
            return;
        });
      },
      error =>{
        this.callInProgress(false)
      });
    }

   /*  modeChanged(){
      this.reportMode = !this.reportMode

      if (this.selectedWorkspaceId == ""){
        return
      }

      if (this.reportMode){
        this.PowerBiReport.listallReportsOfWorkspace(this.selectedWorkspaceId).subscribe(data => {
          this.reports = data
          this.callInProgress(false)
        },
        error =>{
          this.callInProgress(false)
        })
      }
      else{
        this.PowerBiReport.listallDashboardOfWorkspace(this.selectedWorkspaceId).subscribe(data => {
          this.reports = data
          this.callInProgress(false)
        },
        error =>{
          this.callInProgress(false)
        })
      }
    } */
  
    @HostBinding("class") get themeClass() {
      return "u-scroll o-flex__item o-flex o-flex--vertical"
    }
}
import { Component, HostBinding, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { GridOptions } from 'ag-grid-community'
import { User } from 'models/user'
import { AlertService, LicenseReportService, UserService, AuthenticationService } from 'services'
import moment from "moment"
import { analyzeAndValidateNgModules } from '@angular/compiler'

@Component({
  selector: "[app-licence-report]",
  templateUrl: "./licence-report.component.html",
  styleUrls: ["./licence-report.component.scss"]
})

export class LicenceReportComponent implements OnInit {
  private gridApi
  private gridColumnApi
  public columnDefs
  public defaultColDef
  public sideBar
  showInput = true
  gridOptions: GridOptions
  public rowData: any[]
  public licenceReportList: any[]
  public licencePlanList: any[]
  users: User[]= []
  currentLoggedUser:User
  searchText: string
  ownerSelected: string
  id
  callInProgress: boolean=false

  @ViewChild('agGrid',{static: false}) agGrid;
  constructor(
    private licenseReportService: LicenseReportService,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) {

      this.defaultColDef = {sortable: true, filter: true, resizable: true }
    }

    ngOnInit() {
      this.authenticationService.currentUser.subscribe(x=>this.currentLoggedUser=x)
      this.gridOptions = {
       } as GridOptions
      
      this.gridOptions.getRowStyle = function(params) {
        if (params.node.rowIndex % 2 === 0) {
            return { background: '#F0F0F0' }
        }
      }

      this.callInProgress = true;
      this.licenseReportService.getLicencePlan().subscribe(
        data => {
          this.licencePlanList = data;
          let colDefs = this.createDefaultGridColumns();
          let self = this;
          data.forEach(item => {
            if (item.is_usable == false){
              item.commercial_name = item.commercial_name + " (Not Usable)";
            }

            let colDef = { headerName: item.commercial_name, field: "LicencePlanCommercialName", filter: "agTextColumnFilter", width: 120, colId: item.id,
              cellRenderer: function (param) {
                if(param['data']){
                  if(param['data'].LicencePlanId && param['data'].LicencePlanId.toUpperCase() == param.column.colId.toUpperCase()){
                    return '<div style="display:flex; justify-content:flex-end; margin-right:5px;">' + param['data'].NbAllowedUser + '</div>';
                  }
                  else if(self.isRowForTotal(param['data'])
                        && param['data'].TotalNbOfLicencePlan
                        && param['data'].TotalNbOfLicencePlan.hasOwnProperty('TotalAllowedUser_' + param.column.colId)){
                          return '<b style="font-size:13px; display:flex; justify-content:flex-end; margin-right:5px;">' + param['data'].TotalNbOfLicencePlan['TotalAllowedUser_' + param.column.colId] + '</b>';
                  }
                }
                return '';
              }
            };
            colDefs.push(colDef);
          });

          let colBoDef = { headerName: "Back office", field: "NbAllowedRegularUser", filter: "agTextColumnFilter", width: 100, 
            cellRenderer: function (param) {
              if(param['data']){
                if(!self.isRowForTotal(param['data'])){
                  return '<div style="display:flex; justify-content:flex-end; margin-right:5px;">' + param['data'].NbAllowedRegularUser + '</div>';
                }
                else if(param['data'].TotalNbOfLicencePlan.hasOwnProperty('TotalBO')){
                  return '<b style="font-size:13px; display:flex; justify-content:flex-end; margin-right:5px;">' + param['data'].TotalNbOfLicencePlan['TotalBO'] + '</b>';
                }
              }
              return '';
            }
          };
          colDefs.push(colBoDef);

          let colTotalDef = { headerName: "Total", field: "NbTotal", filter: "agTextColumnFilter", width: 100,
            cellRenderer: function (param) {
              if(param['data']){
                if(!self.isRowForTotal(param['data'])){
                  return '<div style="display:flex; justify-content:flex-end; margin-right:5px;">' + param['data'].NbTotal + '</div>';
                }
                else if(param['data'].TotalNbOfLicencePlan.hasOwnProperty('Total')){
                  return '<b style="font-size:13px; display:flex; justify-content:flex-end; margin-right:5px;">' + param['data'].TotalNbOfLicencePlan['Total'] + '</b>';
                }
              }
              return '';
            }
          };
          colDefs.push(colTotalDef);
          this.columnDefs = colDefs;
        },
        error => {
          this.callInProgress = false;
        }
      )

      this.userService.getAllUsersAreInCharge().subscribe(
        data => {
          if(data!=null){
            this.users.push(...data)
          }          
        },
        error => {
          this.alertService.error(error.ResponseException.ExceptionMessage)
        }
      );

      this.sideBar = {
        toolPanels: [{
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: false,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
        }]
      };
    }

    ddlOwnerChanged(event): void {
      this.ownerSelected = event.target.value;
      this.licenceReportFiltering();
      let self = this;
      setTimeout(function() {
        self.ReGenderGridColumnByFilter();
      }, 500);
    }

    onKeywordFilter(event: any) {
      this.searchText = event.target.value;
      this.licenceReportFiltering();
      let self = this;
      setTimeout(function() {
        self.ReGenderGridColumnByFilter();
      }, 500);
    }

    onExportAsExcel(){
      let self = this;
      let licencePlanIds = [];
      this.licencePlanList.forEach(item => { licencePlanIds.push(item.id) });
      this.gridApi.exportDataAsExcel({
        columnKeys: this.generateColumnsForExcel(),
        fileName: 'LicenceReport.xlsx',
        sheetName: 'LicenceReport',
        processCellCallback: function (params) {
          if(!params.node.data) return params.value;

          let rowData = params.node.data;
          if(licencePlanIds.includes(params.column.colId)){
            if(rowData.LicencePlanId && rowData.LicencePlanId.toUpperCase() == params.column.colId.toUpperCase()){
              return rowData.NbAllowedUser;
            }
            else if(self.isRowForTotal(rowData))
            {
              if(rowData.TotalNbOfLicencePlan && rowData.TotalNbOfLicencePlan.hasOwnProperty('TotalAllowedUser_' + params.column.colId)){
                return rowData.TotalNbOfLicencePlan['TotalAllowedUser_' + params.column.colId];
              }              
            }
            return '';
          }
          if(self.isRowForTotal(rowData)){
            if(params.column.colId == 'NbAllowedRegularUser' && rowData.TotalNbOfLicencePlan && rowData.TotalNbOfLicencePlan.hasOwnProperty('TotalBO')){
              return rowData.TotalNbOfLicencePlan['TotalBO'];
            }
            if(params.column.colId == 'NbTotal' && rowData.TotalNbOfLicencePlan && rowData.TotalNbOfLicencePlan.hasOwnProperty('Total')){
              return rowData.TotalNbOfLicencePlan['Total'];
            }
          }
          else {
            if(params.column.colId == 'CancellationDate'){
              if(rowData && rowData.CancellationDate){
                var localTime = moment.utc(rowData.CancellationDate).local().format('DD MMM YYYY, HH:mm');
                return localTime;
              }
              return '';
            }
          }

          return params.value;
        }
      });
    }
    generateColumnsForExcel(): string[] {
      const keys = this.gridColumnApi
        .getAllDisplayedColumns()
        .map(column => column.getColId());
      return keys;
    }

    onGridReady(params) {
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
      this.callInProgress = true;
      this.licenseReportService.getAllLicenceReport().subscribe(
        data => {
          this.rowData = data.Item2;
          this.licenceReportList = data.Item2;
          this.callInProgress = false;
          this.agGrid.api.sizeColumnsToFit();
          this.autoSizeAll();
          this.SetFocusToControl();
        },
        error => {
          this.agGrid.api.showNoRowsOverlay()
          this.agGrid.api.hideOverlay()
          this.callInProgress = false;
          this.SetFocusToControl();
        }
      );
    }
  
    onFirstDataRendered(){
      this.ownerSelected = this.currentLoggedUser.fullname;
      this.licenceReportFiltering();
      let self = this;
      setTimeout(function() {
        self.ReGenderGridColumnByFilter();
        self.autoSizeAll();
      }, 500);
    }

    ReGenderGridColumnByFilter(){
      let licencePlanIdUsed = [];
      this.gridApi.forEachNodeAfterFilter(node => {
        licencePlanIdUsed.push(node.data.LicencePlanId);
      });
      let licencePlansValid = this.licencePlanList.filter(x => x.is_usable || (!x.is_usable && licencePlanIdUsed.includes(x.id)));
      this.createGridColumnsByLicencePlan(licencePlansValid);
    }

    createGridColumnsByLicencePlan(licencePlansValid){
      let licencePlanIds = [];
      let liPlanValidIds = [];
      this.licencePlanList.forEach(item => { licencePlanIds.push(item.id) });
      licencePlansValid.forEach(item => { liPlanValidIds.push(item.id) });

      var allCols = this.gridOptions.columnApi.getAllColumns();
      this.gridOptions.columnApi.setColumnsVisible(allCols, true);
      this.columnDefs.forEach(col => {
        try{
          if(licencePlanIds.includes(col.colId) && !liPlanValidIds.includes(col.colId)){
            this.gridOptions.columnApi.setColumnVisible(col.colId, false);
          }
        }
        catch(error){
        }
      });
    }

    createDefaultGridColumns(){
      let colDefs = [];
      colDefs = [
        { headerName: "Company",field: "CompanyNameDesc",filter: "agTextColumnFilter", minWidth: 350, maxWidth:450, colId: "CompanyNameDesc",
          cellRenderer: function (param) {
            if(param['data']){
              if(param['data'].Id == '00000000-0000-0000-0000-000000000000'){
                return '<b style="font-size:13px;">' + param['data'].CompanyNameDesc + '</b>';
              } else {
                return '<a href="/implementationItem/' + param['data'].Id + '" target="_blank">' + param['data'].CompanyNameDesc + '</a>';
              }
            }
            return '';
          }
        },
        { headerName: "Project Manager", field: "ImpUserName", filter: "agTextColumnFilter", minWidth: 200, maxWidth:300, colId: "ImpUserId"},
        { headerName: "Account Manager", field: "AccountManagerUserName",filter: "agTextColumnFilter", minWidth: 200, maxWidth:300, colId: "AccountManagerUserName"},
        { headerName: "Country", field: "BillingCountry",filter: "agTextColumnFilter", minWidth: 100, maxWidth:110, colId: "BillingCountry"},
        { headerName: "Cancellation Date", field: "CancellationDate",filter: "agTextColumnFilter", minWidth: 130, maxWidth:140, colId: "CancellationDate",
          cellRenderer: function (param) {
            if(param['data'] && param['data'].CancellationDate){
              var localTime = moment.utc(param['data'].CancellationDate).local().format('DD MMM YYYY, HH:mm');
              return localTime;
            }
            return '';
          }
        },
      ]
      return colDefs;
    }

    licenceReportFiltering(){
      if(!this.searchText && (!this.ownerSelected || this.ownerSelected == 'all')){
        this.rowData = this.licenceReportList;
        this.calSumForRowTotal();
        return;
      }

      let targetRowsToFilter = this.licenceReportList.filter(x => !this.isRowForTotal(x));
      this.rowData = [];
      targetRowsToFilter.forEach(licenReport => {
        var isRowValid = true;
        if(this.ownerSelected && this.ownerSelected != 'all'){
            isRowValid = this.hasIncludeText(licenReport.ImpUserName, this.ownerSelected);
        }
        if(this.searchText && this.searchText != ''){
          if(isRowValid){
            isRowValid = false;
            this.columnDefs.forEach(col => {
              try{
                if(this.hasIncludeText(licenReport[col.field], this.searchText)){
                  isRowValid = true;
                }
              }
              catch(error){
              }
            });
          }
        }

        if(isRowValid) this.rowData.push(licenReport);
      });
      this.calSumForRowTotal();      
    }

    isRowForTotal(licenceReport){
      let result = (licenceReport.Id == '00000000-0000-0000-0000-000000000000' && licenceReport.CompanyName == 'Total');
      return result;
    }

    // So after filter then rowData changed -> need to cal sum value fo row total
    calSumForRowTotal(){
      let rowForTotal = this.licenceReportList.filter(x => this.isRowForTotal(x))[0];
      let indexExist = this.rowData.indexOf(rowForTotal);
      if(indexExist >= 0){
        this.rowData.splice(indexExist, 1);
      }

      this.licencePlanList.forEach(item => {
        let rowHasLice = this.rowData.filter(x => x.LicencePlanId == item.id);
        let sumAllowedUser = 0;
        if(rowHasLice) {
          rowHasLice.forEach(x => {
            sumAllowedUser += x.NbAllowedUser;
          });
        }
        rowForTotal.TotalNbOfLicencePlan['TotalAllowedUser_' + item.id] = (sumAllowedUser > 0 ? sumAllowedUser : '');
      });
      let sumRegularUser = 0;
      let sumTotal = 0;
      this.rowData.forEach(x => {
        sumRegularUser += x.NbAllowedRegularUser;
        sumTotal += x.NbTotal;
      });
      rowForTotal.TotalNbOfLicencePlan['TotalBO'] = (sumRegularUser > 0 ? sumRegularUser : '');
      rowForTotal.TotalNbOfLicencePlan['Total'] = (sumTotal > 0 ? sumTotal : '');

      this.rowData.push(rowForTotal);
    }

    hasIncludeText(fieldValue, keyword){
      if(!fieldValue) return false;
      let result = fieldValue.toUpperCase().includes(keyword.toUpperCase());
      return result;
    }

    SetFocusToControl() {
      // set focus on text search
      setTimeout(function() {
        (<HTMLElement>document.getElementById('textFieldId7')).focus();
      }, 500);
    }

    autoSizeAll() {
      const allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach(function(column) {
        if(column.colId != 'CompanyNameDesc'){
          allColumnIds.push(column.colId);
        }
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }

    numberFormatter(num) {
      var sansDec = num.toFixed(0);
      var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formatted;
    }
  
    @HostBinding("class") get themeClass() {
      return "u-scroll o-flex__item o-flex o-flex--vertical"
    }
}
import { Component, OnInit, HostBinding, EventEmitter, Output } from '@angular/core';
import {AuthenticationService} from 'services';
import { User } from 'models/user';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: '[appHeader]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isLoggedIn : Observable<boolean>
  isPhraseEnabled: boolean = false;
  currentLoggedUser: User
  constructor(private authService : AuthenticationService, public translate: TranslateService) { }

  ngOnInit(){
    this.isLoggedIn = this.authService.isLoggedIn
    this.authService.currentUser.subscribe(x=>{
      this.currentLoggedUser=x;
      if(this.currentLoggedUser && this.currentLoggedUser['language_code']) {
        let language_code = this.currentLoggedUser['language_code'].toLowerCase();
        this.translate.use(language_code.indexOf('-') != -1 ? language_code.split("-")[0] : language_code);
      }
    })
    this.isPhraseEnabled = sessionStorage['phrase_enabled'] == 'true';
  }

  @Output() onSideNav = new EventEmitter<boolean>();

  onToggleSideNav() {
      
      this.onSideNav.emit();
  }

  enable() {
    sessionStorage['phrase_enabled'] = true;
    window.location.reload();
  }

  disable() {
    sessionStorage['phrase_enabled'] = false;
    window.location.reload();
  }
}

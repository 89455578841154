// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Location } from "@angular/common";

export const environment = {
         apiUrl: location.protocol + "//testoccapi.wello.solutions/api",
         baseUrl: location.protocol + "//testocc.wello.solutions",
         production: false,
         version: "3.6.0",
         planeHelperUrl: "https://demo.odysseeservice.com/plan-helper-en",
         comparePlansUrl: "https://wello.solutions/pricing/",
         phraseProjectId: "0dbd49620da723449528301d0e36115a",
         portalLoginUrl: "https://login.wello.solutions/identity/account/externallogin",
         portalRecoverPasswordUrl: "https://login.wello.solutions/recovery",
         recaptchaSiteKey: "6LfoZLUZAAAAAC3jjOa_zWHqQI7Bt0rkj2BzrzUq",
         accessToken: "HFKLUEPM6729KLDPSYIMNZEW@#40QPMZLAFT1U845G",
         portalLoginPageUrl: "https://login.wello.solutions",
         vat: 21,
         ContactSalesUrl: "https://portal.wello.solutions/upgrade?contact=1",
         min_nb_mobile_user: 5
       };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

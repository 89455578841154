import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { ImplementationComponent } from '../components/implementation/implementation.component'
import { LoginComponent } from '../components/login/login.component'
import { AuthGuard } from 'quards/auth.quard'
import { ImplementationItemComponent } from '../components/implementation-item/implementation-item.component'
import { ImplementationNewComponent } from 'components/implementation-new/implementation-new.component'
import { TrialRegistrationComponent } from 'components/trial-registration/trial-registration.component'
//import { TrialDemoRegistrationComponent } from 'components/trial-demo-registration/trial-demo-registration.component'
import { TrialDemoSimpleRegistrationComponent } from 'components/trial-demo-simple-registration/trial-demo-simple-registration.component'
//import { TrialDemoRegistrationMobileComponent } from 'components/trial-demo-registration-mobile/trial-demo-registration-mobile.component'
import { TrialDemoSimpleMobileRegistrationComponent } from 'components/trial-demo-simple-mobile-registration/trial-demo-simple-mobile-registration.component'
import { HomeComponent } from 'components/home/home.component'
import { OccExceptionComponent } from 'components/occ-exception/occ-exception.component'
import { ConsultancyOverviewComponent } from '../components/consultancy-overview/consultancy-overview.component'
import { UserManagementComponent } from '../components/user-management/user-management.component'
import { PowerBiReportComponent } from '../components/powerbi-report/powerbi-report.component'
import { PowerBiDashboardComponent } from '../components/powerbi-dashboard/powerbi-dashboard.component'
import { LicenceReportComponent } from '../components/licence-report/licence-report.component'
import { FsaVersionListComponent } from '../components/fsa-versions/fsa-app-versions-list.component'
import { SaVersionListComponent } from '../components/sa-versions/sa-app-versions-list.component'

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full', canActivate: [AuthGuard], data:{showHeader: true, showFooter: true, showSidebar: true}},
  {path: 'home', component: HomeComponent, canActivate: [AuthGuard],data:{showHeader: true, showFooter: true, showSidebar: true}},
  {path: 'implementation', component: ImplementationComponent, canActivate: [AuthGuard],data:{showHeader: true, showFooter: true, showSidebar: true}},
  {path: 'implementationItem/:id', component: ImplementationItemComponent, canActivate: [AuthGuard],data:{showHeader: true, showFooter: true, showSidebar: true}},
  {path: 'implementationNew', component: ImplementationNewComponent, canActivate: [AuthGuard],data:{showHeader: true, showFooter: true, showSidebar: true}},
  {path: 'free-trial', component: TrialDemoSimpleRegistrationComponent, data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'free-trial/:language', component: TrialDemoSimpleRegistrationComponent, data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'free-trial/:language/:phrase_enabled', component: TrialDemoSimpleRegistrationComponent, data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'm-free-trial', component: TrialDemoSimpleMobileRegistrationComponent, data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'm-free-trial/:language', component: TrialDemoSimpleMobileRegistrationComponent, data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'free-trial-mobile', component: TrialRegistrationComponent, data: { showHeader: false, showFooter: false, showSidebar: false } }, // free-trial-mobile: only use for FSA app
  {path: 'free-trial-mobile/:language', component: TrialRegistrationComponent, data: { showHeader: false, showFooter: false, showSidebar: false } },
  {path: 'exception', component: OccExceptionComponent, canActivate: [AuthGuard], data: { showHeader: true, showFooter: true, showSidebar: true } },
  {path: 'exception/:id', component: OccExceptionComponent, canActivate: [AuthGuard], data: { showHeader: true, showFooter: true, showSidebar: true } },
  {path: 'consultancyOverview', component: ConsultancyOverviewComponent, canActivate: [AuthGuard],data:{showHeader: true, showFooter: true, showSidebar: true}},
  {path: 'userManagement', component: UserManagementComponent, canActivate: [AuthGuard],data:{showHeader: true, showFooter: true, showSidebar: true}},
  {path: 'powerBiReport/:language', component: PowerBiReportComponent, canActivate: [AuthGuard],data:{showHeader: true, showFooter: true, showSidebar: true}},
  {path: 'powerBiDashboard/:id/:language', component: PowerBiDashboardComponent, data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'licenceReport', component: LicenceReportComponent, canActivate: [AuthGuard],data:{showHeader: true, showFooter: true, showSidebar: true}},
  {path: 'fsaVersionList', component: FsaVersionListComponent, canActivate: [AuthGuard],data:{showHeader: true, showFooter: true, showSidebar: true}},
  {path: 'saVersionList', component: SaVersionListComponent, canActivate: [AuthGuard],data:{showHeader: true, showFooter: true, showSidebar: true}},
  {path: 'login', component: LoginComponent,data:{showHeader: true, showFooter: true, showSidebar: true}},
  {path: 'subscription/:id/:language', loadChildren: ()=> import('modules/stripe/stripe.module').then(m=>m.StripeModule),
     data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'subscription/:id/:language/:obs_user_id', loadChildren: ()=> import('modules/stripe/stripe.module').then(m=>m.StripeModule),
     data:{showHeader: false, showFooter: false, showSidebar: false}},
  {path: 'subscription/:id/:language/:obs_user_id/:phrase_enabled', loadChildren: ()=> import('modules/stripe/stripe.module').then(m=>m.StripeModule),
     data:{showHeader: false, showFooter: false, showSidebar: false}},
   {path: 'registration', loadChildren: ()=> import('modules/stripe/stripe.module').then(m=>m.StripeModule),
     data:{showHeader: false, showFooter: false, showSidebar: false}},  
  {path: '**', redirectTo: 'login' ,data:{showHeader: true, showFooter: true, showSidebar: true}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }

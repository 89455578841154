import { FreeTrialBannedEmailDto } from './../../models/trial';
import { Component, OnInit, HostBinding, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { formatDate } from "@angular/common";
import {
  ImplementationsService,
  AlertService,
  DataBaseUtilService,
  AuthenticationService,
  LicencePlanService,
  ImplementationLicencePlanService,
  LicenceModificationService,
  StripeService,
  LogsService,
  CultureService,
  TrialService,
  UserService,
  PowerBiReport
} from "services"
import { CreditTypes, Implementation } from "models/implementation"
import { User } from 'models/user'
import {IAngularMyDpOptions, IMyDateModel, VALUE} from 'angular-mydatepicker'
import { isUndefined } from 'util'
import moment from "moment"
import Utils from 'helpers/utils'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { SubscriptionDto } from 'models/SubscriptionDto';
import { GridOptions } from 'ag-grid-community'
import { environment } from 'environments/environment';
import { EmailType, FreeTrialBannedIpDto } from 'models/trial';

@Component({
  selector: "[app-implementation-item]",
  templateUrl: "./implementation-item.component.html",
  styleUrls: ["./implementation-item.component.scss"]
})
export class ImplementationItemComponent implements OnInit {
  imp: Implementation = new Implementation()
  impInfo: object;
  trialIp: string;
  isBannedIp: Boolean = false;
  freeTrialBannedIp: FreeTrialBannedIpDto = new FreeTrialBannedIpDto();
  bannedIpInfo = new FreeTrialBannedIpDto();
  bannedIpUserInfo: string; 
  freeTrialBannedEmailDto: FreeTrialBannedEmailDto = new FreeTrialBannedEmailDto();
  bannedEmailInfo = new FreeTrialBannedEmailDto();
  bannedEmailUserInfo: string;
  isBannedEmail: Boolean = false;
  isFreeEmail: Boolean = false;
  loading: Boolean = false
  errorLoadingDatabase: Boolean = false
  disableDeleteButton: Boolean = true
  disableExtendTrialButton: Boolean = true
  showModal: Boolean = false
  currentLoggedUser:User
  plansList: any
  implementationLicencePlanList: any
  licenseDataInfo:LicenceInfo=new LicenceInfo('', '', '','','')
  disableExtendDateUntilProductionBtn: Boolean=true
  tabName:string='OVERVIEW'
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mmm-yyyy',
    openSelectorTopOfInput:true,
    disableUntil: {year: moment().year(),  month: moment().add(1,'months').month(),  day:  moment().add(1,'days').day()},
    stylesData:{
      styles: `
      .dp1 .myDpIconLeftArrow{
      },
      .dp1 .myDpIconRightArrow{
      },
      .dp1 .myDpHeaderBtn {
      }
  `
    }
  }
  newDateValidUntil: any
  numberOfUsers: number
  dateUntilExtendedProd: IMyDateModel = null
  licenceForm: FormGroup
  creditForm : FormGroup
  submitted = false
  formCreditsTypesubmitted = false
  subscriptionDto: SubscriptionDto
  //licenceInfoDict: { [characterName: string]: string}= {}
  //licenceInfoDict: Map<string,string>= new Map()
  licenceInfoDict:any={}
  disableEditLicenceInfo:boolean=false
  isCheckModificationPending:boolean=false;
  disableEditCreditsType = false;
  advancedInfoDict:any={}
  myDvOptions: IAngularMyDpOptions={
    dateRange: false,
    dateFormat: 'dd-mmm-yyyy',
    disableUntil: {year: moment().year(),  month: moment().add(1,'months').month(),  day:  moment().add(1,'days').day()},
    openSelectorTopOfInput:false,
    stylesData:{
      styles: `
      .dp1 .myDpIconLeftArrow{
      },
      .dp1 .myDpIconRightArrow{
      },
      .dp1 .myDpHeaderBtn {
      }
      `
  }
}
licTransDateDvOptions: IAngularMyDpOptions={
  dateRange: false,
  dateFormat: 'dd-mmm-yyyy',
  openSelectorTopOfInput: false,
  stylesData:{
    styles: `
    .dp1 .myDpIconLeftArrow{
    },
    .dp1 .myDpIconRightArrow{
    },
    .dp1 .myDpHeaderBtn {
    }
    `
  }
}
creditLicTransDateMsg: string = ''
databasePerformance:DatabasePerformance=new DatabasePerformance('', '', 0, 0, 0, 0, 0)
editProjectManager: Boolean = false
editAccountManager: Boolean = false
projectManagerId: string = '00000000-0000-0000-0000-000000000000'
accountManagerId: string = '00000000-0000-0000-0000-000000000000'
editDescription: Boolean = false
currentImpDescription: string = ''
creditQuantityMaxlength: number = 5;

billingCycle: string = 'Unknow'
selectedActivitiesItem: string = ''
activitiesList: any
dbUsersList: any
activitiesListFilter: any
showWarningPoNumberRequired: Boolean = false
isShowEnableBiDashboard: Boolean = false
reportBreakingRules: any
reportBreakingRulesMsg: string = '';

  private gridApi
  private gridColumnApi
  public columnDefs
  public defaultColDef
  showInput = true
  gridOptions: GridOptions
  public rowData: any[]
  min_nb_mobile_user: number = 5;
  user_list: User[]= [];

  creditsTypeList = Object.values(CreditTypes);

  @ViewChild('agGrid',{static: false}) agGrid;

  constructor(
    private implementationsService: ImplementationsService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private databaseUtilService: DataBaseUtilService,
    private AuthService:AuthenticationService,
    private licencePlanSrv: LicencePlanService,
    private implementationLicencePlanService: ImplementationLicencePlanService,
    private licenceModificationService: LicenceModificationService
    ,
    private formBuilder: FormBuilder,
    private stripeService: StripeService,
    private logsService: LogsService,
    private cultureService: CultureService,
    private trialService: TrialService,
    private userService: UserService,
    private powerBiReport: PowerBiReport
  ) {
    this.columnDefs = [
      { headerName: "Date create",field: "date_create", width: 160, filter: "agDateColumnFilter", sort: 'desc',
        cellRenderer: params => { return moment(params.value).format("DD-MMM-YYYY HH:mm") },
        filterParams: {
          filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange'],
          inRangeInclusive: true,
          comparator: function(filterLocalDateAtMidnight, cellValue) {

            let returnRez=0
            var dateAsString = moment(cellValue).format('DD/MM/YYYY')

            var dateParts = dateAsString.split("/")
            var day = Number(dateParts[0])
            var month = Number(dateParts[1]) - 1
            var year = Number(dateParts[2])
            var cellDate = new Date(year, month, day)

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      },
      { headerName: "Type code",field: "type_code",filter: "agTextColumnFilter", width: 150},
      { headerName: "Body",field: "body", cellStyle: {'white-space': 'normal'},
        autoHeight: true, filter: "agTextColumnFilter", width: 600}
      //{ headerName: "Archived", field: "archived", filter: "agSetColumnFilter", width: 100, cellRenderer: function(params) { return '<input disabled type="checkbox" ' + (params.archived ? 'checked' : '') + '>'; }}
    ]

    this.defaultColDef = {sortable: true, filter: true }
  }

  ngOnInit() {
    this.min_nb_mobile_user = environment.min_nb_mobile_user;
    const id = this.route.snapshot.paramMap.get("id")
    if (this.route.snapshot.queryParams["credits"] === "true"){
      this.tabName = "CREDITS"
    }

    if (id == null) {
      this.alertService.error("Invalid implementation", true)
      return
    }
    if (!Utils.isGuid(id)) {
      this.router.navigate(["/implementation"])
      this.alertService.error("Invalid implementation", true)
      return
    }

    this.gridOptions = {} as GridOptions
    this.gridOptions.getRowStyle = function(params) {
      if (params.node.rowIndex % 2 === 0) {
          return { background: '#F0F0F0' }
      }
    }

    this.getAllUsers();

    this.getImById(id)

    this.AuthService.currentUser.subscribe(x=>this.currentLoggedUser=x)
    this.disableExtendDateUntilProductionBtn=!this.currentLoggedUser.CanExtendTrial
    this.licenceForm = this.formBuilder.group({
      planName: [],
      dateValidUntil: [''],
      numberOfMobileUsers: [''],
      numberOfPlannedUsers: [''],
    })
    this.initCreditForm();
    this.getTrialIp(id);
    this.getPlans()
    this.getImplementationLicencePlan(id);
    this.getImpInfo(id)
    this.getSubcription(id);   
    //this.getActivities(id);

    // ASG-2148, date between now-14 days to now+14 days, but add -15 to allow selecting the first-range and last-range date
    let transDateFrom = moment().add(-15,'days');
    let transDateTo = moment().add(15,'days');
    this.licTransDateDvOptions.disableUntil = {year: transDateFrom.year(),  month: transDateFrom.month() + 1,  day: transDateFrom.date()};
    this.licTransDateDvOptions.disableSince = {year: transDateTo.year(),  month: transDateTo.month() + 1,  day: transDateTo.date()};

    //this.getWorkspaceIdOfImp(id);
    this.getReportBreakingRules(id);
  }
  checkPedding(id){
    if(this.isCheckModificationPending)
    {
      this.callInProgress(true);
    }

    this.licenceModificationService.getTopModificationPending(id).subscribe(data=>{
      if(data != null && data.IsSuccess){
        if(this.isCheckModificationPending)
        {
          this.callInProgress(false);
        }

        this.disableEditLicenceInfo = (data.Result != null);
        if(this.disableEditLicenceInfo)
        {
          this.isCheckModificationPending = true;
          
          var localTime = moment.utc(data.Result.date_create).local().format('YYYY-MM-DD HH:mm:ss');
          let dateCreateLocalTime = moment(localTime).fromNow();
          let msg = 'Some modifications are still pending ' + dateCreateLocalTime;
          this.alertService.error(msg);

          // if has modification pending -> auto refresh each 5 sec
          setTimeout(() => {
            this.checkPedding(id);
          }, 5000);
        }
        else{
          this.isCheckModificationPending = false;
          this.alertService.clear();
        }
        this.setDisableLicenceFormControl();
      }
      else
      {
        if(this.isCheckModificationPending)
        {
          this.alertService.clear();
          this.isCheckModificationPending = false;
          this.disableEditLicenceInfo = false;
          this.setDisableLicenceFormControl();
          this.callInProgress(true);
          this.getImById(id);
          this.getImpInfo(id)
          this.getSubcription(id);  
        }
      }
    })
  }

  setDisableLicenceFormControl(){
    const action = this.disableEditLicenceInfo  ? 'disable' : 'enable';
    Object.keys(this.licenceForm.controls).forEach((controlName) => {
      this.licenceForm.controls[controlName][action]();
    });
  }

  getImpInfo(id){
    this.implementationsService.getInfo(id).subscribe(data=>{
      if(data.IsSuccess){
        this.impInfo = data.Result
        this.licenseDataInfo= new LicenceInfo(this.impInfo['LicencePlanType'], this.impInfo['LicencePlanDate'], this.impInfo['MobileUser'], this.impInfo['PlannedUser'], this.impInfo['LicencePlanTypeId'])
        this.databasePerformance=new DatabasePerformance(this.impInfo['LicencePerformanceLevel'],this.impInfo['ActualPerformanceLevel'],this.impInfo['CPU'],this.impInfo['IO'],this.impInfo['AVG_CPU'],this.impInfo['AVG_IO'],this.impInfo['DbSize'])
        //this.myDvOptions.disableUntil={year:month:day} --TO BE DONE
      }
      //this.callInProgress(false)
    })
  }
  getPlans(){
    this.licencePlanSrv.getAllPlans().then(data => {
      if(data.IsSuccess){
         this.plansList=data.Result;
         this.generateImplementationLicencePlanList();
      }
    });
  }
  getImplementationLicencePlan(implementationId){
    this.implementationLicencePlanService.getAll(implementationId).then(data => {
      if (data.IsSuccess) {
         this.implementationLicencePlanList = data.Result;
         this.generateImplementationLicencePlanList();
      }
    });
  }
  generateImplementationLicencePlanList() {
    if (this.plansList && this.implementationLicencePlanList) {
      this.plansList.forEach(item => {
        var implementationLicencePlan = this.implementationLicencePlanList.find(l => l['licence_plan_id'] == item['id']) || {};
        item.implementation_licence_plan_monthly_allowed = implementationLicencePlan.monthly_allowed;
        item.implementation_licence_plan_trial_plan_general_available = implementationLicencePlan.trial_plan_general_available;
      });
    }
  }
  getImById(id) {
    this.loading = true
    this.implementationsService.getById(id).subscribe(
      data => {
        if (data.IsSuccess == true)
        {
          this.imp = data.Result
          //this.loading = false
          if(this.imp.legacy_occ_implementation)
          {
            this.alertService.info("This implementation cannot be changed using occ v3.");
            Object.keys(this.licenceForm.controls).forEach((controlName) => {
                this.licenceForm.controls[controlName]['disable']();
            });
          } else {
            this.checkPedding(id);
          }

          if(this.imp.invoice_due_date_days == null || this.imp.invoice_due_date_days == 0){
            this.imp.invoice_due_date_days = 30
          }

          if(!!this.currentLoggedUser.CanExtendTrial){
            this.disableExtendTrialButton = false
          }else{
            this.disableExtendTrialButton = true
          }

          if(!!this.currentLoggedUser.CanArchiveImplementation){
            this.disableDeleteButton = false
          }
          else{
            if(!!this.currentLoggedUser.CanArchiveTrialImplementation)
            {
              if(!!this.imp.is_trial){
                this.disableDeleteButton = false
              }
              else{
                this.disableDeleteButton = true
              }
            }
            else{
              this.disableDeleteButton = true
            }
          }
          this.getTrialEmailType(this.imp.Company_email);
        } else {
          this.loading = false
          this.errorLoadingDatabase = true
        }
      },
      error => {
        this.loading = false
        this.errorLoadingDatabase = true
      }
    )
  }
  getSubcription(id) {
    this.loading = true;
    this.stripeService.get(id, null).subscribe(
      data => {
        this.subscriptionDto = data;
        this.subscriptionDto.licence_minimum_number_of_mobile_users = this.subscriptionDto.licence_minimum_number_of_mobile_users < this.min_nb_mobile_user ? this.min_nb_mobile_user : this.subscriptionDto.licence_minimum_number_of_mobile_users;
        //this.loading = false;
        this.licenceForm = this.formBuilder.group({
          planName: [],
          dateValidUntil: [''],
          numberOfMobileUsers: [this.licenceInfoDict.MobileUser, Validators.compose([Validators.min(this.subscriptionDto.licence_minimum_number_of_mobile_users)])],
          numberOfPlannedUsers: [this.licenceInfoDict.PlannedUser, Validators.compose([Validators.min(this.subscriptionDto.licence_minimum_of_planned_users)])],
        })
        
        if (this.imp && this.imp.is_invoicable){
          if (this.subscriptionDto.renew_frequency_nb_month == 12){
            this.billingCycle = 'Yearly';
          }
          else if (this.subscriptionDto.renew_frequency_nb_month == 1) {
            this.billingCycle = 'Monthly';
          }          
        }

        this.callInProgress(false);
      },
      error => {
        this.loading = false
        this.errorLoadingDatabase = true
      }
    )
  }
  getTrialIp(id) {
    this.trialService.getTrialIp(id).subscribe(data => {
      this.trialIp = data;
      this.checkBannedTrailIp(this.trialIp);
    });
  };

  checkBannedTrailIp(ip) {
    /* if (ip) {
      this.trialService.isBannedTrialIp(ip).subscribe(data => {
        this.isBannedIp = (data == 'true');
      });
    } */

    if (ip) {
      this.trialService.getBannedIpInfo(ip).subscribe(data => {
        if (data){
          this.isBannedIp = true;
          this.bannedIpUserInfo = 'Already banned by ';
          if (data['added_by_user_id'] == this.currentLoggedUser.id){
            this.bannedIpUserInfo = this.bannedIpUserInfo  + this.currentLoggedUser.fullname + ' since ' + moment(data['date_added']).format("DD-MM-YYYY");
          }
          else{
            var user_info = this.user_list.find(u => u.id == data['added_by_user_id']);
            this.bannedIpUserInfo = this.bannedIpUserInfo  + user_info.fullname + ' since ' + moment(data['date_added']).format("DD-MM-YYYY");
          }
          
        }
      },
      error => {
      });
    }

  };
  getTrialEmailType(email) {
    /* this.trialService.getTrialEmailType(email).subscribe(data => {
      this.isFreeEmail = (data == EmailType.FREE);
      this.isBannedEmail = (data == EmailType.BANNED);
    }); */

    if (email == '' || email == undefined){
      return;
    }

    this.callInProgress(true);
    this.trialService.getBannedEmailInfo(email).subscribe(data => {
      if (data) {
        this.isFreeEmail = (data.is_free == true);
        this.isBannedEmail = (data.is_free == false);
        if (data['is_free'])
          this.bannedEmailUserInfo = 'Email domain is flagged as free';
        else
          this.bannedEmailUserInfo = 'Email domain banned';

        if (data['added_by_user_id'] != null && data['added_by_user_id'] != '00000000-0000-0000-0000-000000000000'){
            if (data['added_by_user_id'] == this.currentLoggedUser.id){
              this.bannedEmailUserInfo = this.bannedEmailUserInfo + ' by ' + this.currentLoggedUser.fullname + ' since ' + moment(data['date_added']).format("DD-MM-YYYY");
            }
            else{
              var user_info = this.user_list.find(u => u.id == data['added_by_user_id']);
              this.bannedEmailUserInfo = this.bannedEmailUserInfo + ' by ' + user_info.fullname + ' since ' + moment(data['date_added']).format("DD-MM-YYYY");
            }
        }
      }

      this.callInProgress(false);
    }, error => {
      this.callInProgress(false);
    }
    );
  };

  getAllUsers(){
    this.userService.getAllUsersExceptTheLogged().subscribe(data => {
      var loggedUser=this.currentLoggedUser      
      this.user_list.splice(0, 0, loggedUser)
      if (data){
        this.user_list.push(...data);        
        this.user_list.sort(this.dynamicSort('fullname'));
      }
    })

  };

  getActivities(id) {
    this.callInProgress(true);
    this.implementationsService.getActivities(id).subscribe(data=>{
      if(data.IsSuccess){
        this.activitiesList = data.Result;

        // format utc date and calculate timespan based on local date of occ user
        this.activitiesList.forEach(item => {
          if (item['invite_accepted'] != null && item['invite_accepted'] != ''){
            let dateAccept = moment(item['invite_accepted'] + ' UTC').format('YYYYMMDDHHMM')
            item['invite_accepted'] = moment(item['invite_accepted'] + ' UTC').format('D MMMM YYYY HH:mm')
            item['invite_accepted'] = item['invite_accepted'] + ' (' + moment(dateAccept, 'YYYYMMDDHHMM').fromNow() + ')'
          }

          if (item['invite_email_sent'] != null && item['invite_email_sent'] != ''){
            let dateInvite = moment(item['invite_email_sent'] + ' UTC').format('YYYYMMDDHHMM')
            item['invite_email_sent'] = moment(item['invite_email_sent'] + ' UTC').format('D MMMM YYYY HH:mm')
            item['invite_email_sent'] = item['invite_email_sent'] + ' (' + moment(dateInvite, 'YYYYMMDDHHMM').fromNow() + ')'
          }

          if (item['date_install_serviceApp'] != null && item['date_install_serviceApp'] != ''){
            let dateInstallApp = moment(item['date_install_serviceApp'] + ' UTC').format('YYYYMMDDHHMM')
            item['date_install_serviceApp'] = moment(item['date_install_serviceApp'] + ' UTC').format('D MMMM YYYY HH:mm')
            item['date_install_serviceApp'] = item['date_install_serviceApp'] + ' (' + moment(dateInstallApp, 'YYYYMMDDHHMM').fromNow() + ')'
          }

          if (item['last_logged_portal'] != null && item['last_logged_portal'] != ''){
            let lastLogPortal = moment(item['last_logged_portal'] + ' UTC').format('YYYYMMDDHHMM')
            item['last_logged_portal'] = moment(lastLogPortal, 'YYYYMMDDHHMM').fromNow()
          }

          if (item['wo_date_completed'] != null && item['wo_date_completed'] != ''){
            let dateCompleted = moment(item['wo_date_completed'] + ' UTC').format('YYYYMMDDHHMM')
            item['wo_completed'] = item['wo_completed'] + ', ' + moment(dateCompleted, 'YYYYMMDDHHMM').fromNow()
          }
          
          if (item['last_activity_serviceApp'] != null && item['last_activity_serviceApp'] != ''){
            let lastActivityDate = moment(item['last_activity_serviceApp'] + ' UTC').format('YYYYMMDDHHMM')
            item['last_activity_serviceApp'] = moment(lastActivityDate, 'YYYYMMDDHHMM').fromNow()
          }
        });

        this.activitiesListFilter = this.activitiesList;
      }
      this.callInProgress(false);
    },
    error => {
      this.callInProgress(false);
    })
  }

  onGridReady(params) {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    this.agGrid.api.sizeColumnsToFit();
    this.agGrid.api.resetRowHeights();

    this.logsService.getLogs(this.route.snapshot.paramMap.get("id")).subscribe(
      data => {
        this.rowData = data
      },
      error => {
        this.agGrid.api.showNoRowsOverlay()
        this.agGrid.api.hideOverlay()
      }
    )
  }

  onFirstDataRendered(){
    this.agGrid.api.sizeColumnsToFit();
  }

  @HostBinding("class") get themeClass() {
    return "u-scroll o-flex__item o-flex o-flex--vertical"
  }

  extendDateUntilProduction(){
    this.alertService.clear()
    if(isUndefined(this.newDateValidUntil) || this.newDateValidUntil==null)
    {
      this.alertService.error("Please select a valid date to define.")
      return
    }
    if(this.numberOfUsers<1)
    {
      this.alertService.error("Please specify the allowed number of power users.")
      return
    }

    var dateValid=moment(this.newDateValidUntil.singleDate.jsDate).add(1,'days')
    this.loading = true
    this.databaseUtilService.extendLicenceGroup(this.imp.Id, dateValid, this.numberOfUsers).subscribe(
      data => {
        if (data.Success) {
          this.getImById(this.imp.Id)
          this.alertService.success(
            "The date valid has been extended until: " + this.newDateValidUntil.singleDate.formatted
          )
          this.newDateValidUntil=null
        } else
          this.alertService.error(
            "Something went wrong and the date valid has not been extended."
          )
        this.loading = false
      },
      error => {
        this.loading = false
      }
    )
  }
  extendTrial() {
    this.loading = true
    this.databaseUtilService.extendTrial(this.imp.Id).subscribe(
      data => {
        if (data.Success) {
          this.getImById(this.imp.Id)
          this.alertService.success(
            "The trial period has been extended with another 7 days"
          )
        } else
          this.alertService.error(
            "Something went wrong and the trial period has not been extended."
          )
        this.loading = false
      },
      error => {
        this.loading = false
        console.log(error)
      }
    )
  }

  delete() {
    this.loading = true
    this.databaseUtilService.archiveImplementation(this.imp.Id).subscribe(
      data => {
        if (data.Success) {
          this.alertService.success("The database has been deleted", true)
          this.router.navigate(["/implementation"])
        }
        this.loading = false
      },
      error => {
        this.loading = false
        this.errorLoadingDatabase = true
      }
    )
  }

  openInnerTrends(userParameter)
  {
    window.open("https://my.innertrends.com/citj/odyssee/get-user-activity.php?user="+userParameter, "_blank")
  }

  openIpInfo(ip) {
    window.open("http://ip-api.com/xml/"+ip, "_blank");
  }

  openStripe(customerCode){
    window.open("https://dashboard.stripe.com/customers/" + customerCode, "_blank");
  }

  banIp() {
    this.loading = true;
    this.freeTrialBannedIp.added_by_user_id = this.currentLoggedUser.id;
    this.freeTrialBannedIp.ip_from = this.trialIp;
    this.freeTrialBannedIp.ip_to = this.trialIp;
    this.freeTrialBannedIp.description = "Added by OCC when banned domain=" + this.imp.Company_domain + " by user " + this.currentLoggedUser.fullname;
    this.trialService.banIp(this.freeTrialBannedIp).subscribe(
      data => {
        if (data.Success) {
          this.checkBannedTrailIp(this.trialIp);
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }
  banEmail(isFree: boolean) {
    this.loading = true;
    this.freeTrialBannedEmailDto.added_by_user_id = this.currentLoggedUser.id;
    this.freeTrialBannedEmailDto.email = this.imp.Company_email;
    this.freeTrialBannedEmailDto.is_free = isFree;
    this.trialService.banEmail(this.freeTrialBannedEmailDto).subscribe(
      data => {
        if (data.Success) {
          this.getTrialEmailType(this.imp.Company_email);
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }
  trialToProduction() {
    this.loading = true
    this.databaseUtilService.trialToProduction(this.imp.Id).subscribe(
      data => {
        if (data.Success) {
          this.alertService.success("The database has been ", true)
          this.router.navigate(["/implementation"])
        }
        this.loading = false
      },
      error => {
        this.loading = false
      }
    )
  }
  showConfirmation(visible: boolean) {
    this.showModal = visible
  }

  onLicenceInfoSubmit()
  {
    this.alertService.clear()
    this.submitted = true
    if (this.licenceForm.invalid) {
      return
    }

    this.licenceInfoDict={}
    if(!!this.licenceForm.value.planName){
      this.licenceInfoDict['LicencePlanTypeId']=this.licenceForm.value.planName
    }

    if(!!this.licenceForm.value.dateValidUntil){
      this.licenceInfoDict['LicencePlanDate'] = formatDate(this.licenceForm.value.dateValidUntil.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
    }

    if(!!this.licenceForm.value.numberOfMobileUsers){
      this.licenceInfoDict['MobileUser']=this.licenceForm.value.numberOfMobileUsers
    }
    if(this.licenceForm.value.numberOfPlannedUsers != null && this.licenceForm.value.numberOfPlannedUsers !== ""){
      this.licenceInfoDict['PlannedUser']=this.licenceForm.value.numberOfPlannedUsers
    }
     this.callInProgress(true)
     this.disableEditLicenceInfo = true;
     this.implementationsService.saveLicenceInfo(this.imp.Id,this.licenceInfoDict).subscribe(data=>{

       if(data.Success)
       {
         //this.alertService.success("Data has been saved")
         this.checkPedding(this.imp.Id);
       }
       else{
         this.alertService.error("Data has not been saved. Please contact the administrator.")
       }
       this.getImpInfo(this.imp.Id)
     },
     error => {
       this.alertService.error(error.ResponseException.ExceptionMessage)
       this.callInProgress(false)
     })
  }

  openSubscription(isOwner) {
    let url = '/subscription/' + this.imp.Id + '/' + (this.currentLoggedUser.language_code || 'en') + '/' + (isOwner ? this.impInfo['OwnerId'] : '00000000-0000-0000-0000-000000000000');
    window.open(url, '_blank');
  }

  callInProgress(inProgress: boolean){
    this.loading = inProgress
  }
  //#region Tabs
  loadTab(tabN) {
    this.tabName=tabN

    if (this.tabName == 'ACTIVITIES'){
      if (this.dbUsersList == null || this.dbUsersList.length == 0){
        const id = this.route.snapshot.paramMap.get("id");
        this.getAllDbUsers(id);
      }
      else{
        this.activitiesListFilter = this.activitiesList;
      }    
    }
  }
  //#endregion

  saveImplementationLicencePlans() {
    var updateList = [];

    this.plansList.forEach(item => {
      var implementationLicencePlan = this.implementationLicencePlanList.find(l => l['licence_plan_id'] == item['id']) || {};

      if ((item.implementation_licence_plan_monthly_allowed != undefined && item.implementation_licence_plan_monthly_allowed != implementationLicencePlan.monthly_allowed) || (item.implementation_licence_plan_trial_plan_general_available != undefined && item.implementation_licence_plan_trial_plan_general_available != implementationLicencePlan.trial_plan_general_available)) {
        implementationLicencePlan.implementation_id = this.route.snapshot.paramMap.get("id");
        implementationLicencePlan.licence_plan_id = item.id;
        implementationLicencePlan.monthly_allowed = (item.implementation_licence_plan_monthly_allowed == undefined || item.implementation_licence_plan_monthly_allowed == 'undefined') ? item.monthly_allowed : item.implementation_licence_plan_monthly_allowed;
        implementationLicencePlan.trial_plan_general_available = (item.implementation_licence_plan_trial_plan_general_available == undefined || item.implementation_licence_plan_trial_plan_general_available == 'undefined') ? item.trial_plan_general_available : item.implementation_licence_plan_trial_plan_general_available;

        updateList.push(implementationLicencePlan);
      }
    });

    if (updateList.length == 0)
      return;

    this.callInProgress(true)

    this.implementationLicencePlanService.saveLicencePlanList(updateList, this.currentLoggedUser['id']).subscribe(data=>{
      this.callInProgress(false)
      if(data == 'true')
      {
         this.alertService.success("Data has been saved.");
         this.getImplementationLicencePlan(this.route.snapshot.paramMap.get("id"));
      } else {
         this.alertService.error("Data has not been saved. Please contact the administrator.")
      }
    },
    error => {
      this.alertService.error(error.ResponseException.ExceptionMessage)
      this.callInProgress(false)
    })
  }
  onAddManualCreditSubmit() {
    this.alertService.clear();
    this.formCreditsTypesubmitted = true;
    this.creditLicTransDateMsg = '';
    let licenceTransDateUtc = this.getValidLicenceTransDate();
    if (!licenceTransDateUtc || licenceTransDateUtc == '')
    {
      let transDateFrom = moment().add(-14,'days').format('DD-MMM-YYYY');
      let transDateTo = moment().add(14,'days').format('DD-MMM-YYYY');
      this.creditLicTransDateMsg = 'Date is not valid, it must be between '+ transDateFrom + ' and ' + transDateTo;
    }

    if (this.creditForm.invalid) {
      return;
    }
    if (this.creditForm.value.numberOfCredits == 0) {
      this.alertService.error('Quantity must be non-zero.');
      return;
    }
    if (this.creditLicTransDateMsg != '') return;

    this.callInProgress(true);
    this.disableEditCreditsType = true;
    this.implementationsService.AddManualLicenceTransaction(
      this.imp.Id,
      this.currentLoggedUser.id,
      this.creditForm.value.txtDescription,
      this.creditForm.value.txtInternalDescription,
      this.creditForm.value.creditsType,
      this.creditForm.value.numberOfCredits,
      licenceTransDateUtc).subscribe(data=>{
      if (data.Success) {
        this.alertService.success("Data has been added.");
        this.getSubcription(this.route.snapshot.paramMap.get("id"));
        this.formCreditsTypesubmitted = false;
        this.initCreditForm();
      }
      else {
        this.alertService.error("Data has not been saved. Please contact the administrator.");
        this.callInProgress(false);
      }
      this.disableEditCreditsType = false;
    },
    error => {
      this.alertService.error(error.ResponseException.ExceptionMessage);
      this.callInProgress(false);
      this.disableEditCreditsType = false;
    });

  }
  savePaymentInfo() {
    this.callInProgress(true);
    this.implementationsService.allowInvoice(this.imp.Id, this.imp.allow_invoice, this.imp.invoice_due_date_days, this.imp.required_po_number).subscribe(data=>{
      this.callInProgress(false)
      if (data.Success) {
        this.alertService.success("Data has been updated.");
        this.getImById(this.route.snapshot.paramMap.get("id"));
      }
      else {
        this.alertService.error("Data has not been saved. Please contact the administrator.");
      }
    },
    error => {
      this.alertService.error(error.ResponseException.ExceptionMessage);
      this.callInProgress(false);
    });
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }

  changeProjectManager(){
    this.editProjectManager = !this.editProjectManager;
    if (!this.editProjectManager){
      if (this.projectManagerId !== this.imp.User_id)
      {
        this.updateManager(this.projectManagerId, true);
      }
      else{
        let btnProjectManager: HTMLElement = <HTMLElement>document.getElementById('btnChangeProjectManager');
        if (btnProjectManager){
          btnProjectManager.textContent = "Change"
        }        
      }      
    }
    else{
      this.projectManagerId = this.imp['User_id']
      let btnProjectManager: HTMLElement = <HTMLElement>document.getElementById('btnChangeProjectManager');
      if (btnProjectManager){
        btnProjectManager.textContent = "Save"
      }      
    }  
  }

  changeAccountManager(){
    this.editAccountManager = !this.editAccountManager;

    if (!this.editAccountManager){
      if (this.accountManagerId !== this.imp.Account_manager_user_id)
      {
        this.updateManager(this.accountManagerId, false);
      }       
      else{
        let btnAccountManager: HTMLElement = <HTMLElement>document.getElementById('btnChangeAccountManager');
        if (btnAccountManager){
          btnAccountManager.textContent = "Change"
        }        
      }    
    }
    else{
      this.accountManagerId = this.imp['Account_manager_user_id']
      let btnAccountManager: HTMLElement = <HTMLElement>document.getElementById('btnChangeAccountManager');
      if (btnAccountManager){
        btnAccountManager.textContent = "Save";
      }      
    }          
  }


  ddlProjectManagerChanged(event): void {
    this.projectManagerId = event.target.value;    
  }

  ddlAccountManagerChanged(event): void {
    this.accountManagerId = event.target.value;
  }

  updateManager(manager_id, is_project_manager) {
    this.callInProgress(true);
    this.implementationsService.updateManagerId(this.imp.Id, manager_id, is_project_manager).subscribe(data=>{
      this.callInProgress(false)
      if (data.Success) {
        this.alertService.success("Data has been updated.");
        this.getImById(this.route.snapshot.paramMap.get("id"));
      }
      else {
        this.alertService.error("Data has not been saved. Please contact the administrator.");
      }

      if (is_project_manager){
        let btnProjectManager: HTMLElement = <HTMLElement>document.getElementById('btnChangeProjectManager');
        if (btnProjectManager){
          btnProjectManager.textContent = "Change"
        }        
      }
      else{
        let btnAccountManager: HTMLElement = <HTMLElement>document.getElementById('btnChangeAccountManager');
        if (btnAccountManager){
          btnAccountManager.textContent = "Change"
        }        
      }
    },
    error => {
      this.alertService.error(error.ResponseException.ExceptionMessage);
      this.callInProgress(false);

      if (is_project_manager){
        let btnProjectManager: HTMLElement = <HTMLElement>document.getElementById('btnChangeProjectManager');
        if (btnProjectManager){
          btnProjectManager.textContent = "Change"        
        }        
      }
      else{
        let btnAccountManager: HTMLElement = <HTMLElement>document.getElementById('btnChangeAccountManager');
        if (btnAccountManager){
          btnAccountManager.textContent = "Change"
        }        
      }
    });
  }

  changeDescription(){
    this.editDescription = !this.editDescription;

    if (!this.editDescription){
      if (this.currentImpDescription !== this.imp.description)
      {
        this.callInProgress(true);
        this.implementationsService.updateDescription(this.imp.Id, this.imp.description).subscribe(data=>{
          this.callInProgress(false)
          if (data.Success) {
            this.alertService.success("Data has been updated.");
            this.getImById(this.route.snapshot.paramMap.get("id"));
          }
          else {
            this.alertService.error("Data has not been saved. Please contact the administrator.");
          }
        },
        error => {
          let msg = (error.ResponseException != undefined) ? error.ResponseException.ExceptionMessage : error;
          this.alertService.error(msg);
          this.callInProgress(false);
        });
      }
      this.changeTextOfDescriptionBtn("Change");
    }
    else{
      this.currentImpDescription = this.imp.description;
      this.changeTextOfDescriptionBtn("Save");
    }
  }

  changeTextOfDescriptionBtn(btnText){
    let btnChangeDescription: HTMLElement = <HTMLElement>document.getElementById('btnChangeDescription');
      if (btnChangeDescription){
        btnChangeDescription.textContent = btnText;
      }      
  }

  onAdvancedSubmit()
  {
    this.alertService.clear()
    this.advancedInfoDict = {}
    this.advancedInfoDict['allow_portaldev'] = this.imp.allow_portaldev;

    this.callInProgress(true)
    this.implementationsService.updateAdvanced(this.imp.Id, this.advancedInfoDict).subscribe(data=>
      {
       if(data.Success)
       {
         this.alertService.success("Data has been saved")
       }
       else{
         this.alertService.error("Data has not been saved. Please contact the administrator.")
       }
       this.callInProgress(false)
     },
     error => {
       this.alertService.error(error.ResponseException.ExceptionMessage)
       this.callInProgress(false)
     })
  }

  logAsSuperadmin() {
    //let url = 'https://portal.odysseemobile.com/identity/account/ExternalLogin?domain='+ this.imp.domain_encrypt +'&login='+ this.imp.superadmin_username_encrypt +'&passwd=' + this.imp.superadmin_password_encrypt
    let url = environment.portalLoginUrl + '?domain='+ this.imp.domain_encrypt +'&email='+ this.imp.superadmin_username_encrypt +'&password=' + this.imp.superadmin_password_encrypt
    window.open(url, '_blank');
  }

  resendEmailInvite(email){
    document.querySelector('#title').scrollIntoView();
    this.loading = true
    this.databaseUtilService.resendEmailInvite(this.imp.Id, email).subscribe(
      data => {
        if (data.Success) {
          this.alertService.success(
            "Resend invite was done for email: " + email
          )
        } else
          this.alertService.error(
            "Cannot resend invite for email: " + email + " due to " + data.ErrorMessage
          )
        this.loading = false
      },
      error => {
        this.alertService.error(error.ResponseException.ExceptionMessage)
        this.loading = false
      }
    )        
  }

  showConfirmationPoNumberRequired(visible: boolean) {
    if (visible){
      if (this.subscriptionDto.recurrent_po_number){
        this.showWarningPoNumberRequired = true;         
      }
      else{
        this.showWarningPoNumberRequired = false;
      }

      return;
    }
    else{
      if (this.subscriptionDto.recurrent_po_number){
        this.imp.required_po_number = true;
      }
    }
    
    this.showWarningPoNumberRequired = visible
  }

  initCreditForm(){
    this.creditForm = this.formBuilder.group({
      creditsType: ['',Validators.required],
      txtDescription: [''],
      txtInternalDescription: ['',Validators.required],
      numberOfCredits: ['0',  [Validators.required, Validators.pattern(/^[-.\d]/)]],
      licenceTransDate: ['', [Validators.required]]
    });
    this.creditForm.controls.licenceTransDate.setValue({
      singleDate: {
        jsDate: new Date()
      }
    });
  }

  onQuantityInput(event){
    var newOnForm = this.creditForm.value.numberOfCredits ? this.creditForm.value.numberOfCredits.toString() : '';
    var maxLength = this.creditQuantityMaxlength;
    if(newOnForm.startsWith('-')){
      maxLength +=1;
    }
    if(newOnForm.indexOf('.') >= 0){
      var strList = newOnForm.split('.');
      if(strList[0].length > maxLength){
        strList[0] = strList[0].slice(0, maxLength);
      }
      if(strList.length > 1 && strList[1].length > 2){
        strList[1] = strList[1].slice(0, 2);
      }
      var newVal = strList[0] + '.' + strList[1];
      if(newVal != newOnForm){
        event.target.value = newVal;
      }
    }
    else if(newOnForm.length > maxLength){
      event.target.value = newOnForm.slice(0, maxLength);
    }
  }

  quantityInputKeyDown(event){
    var isInputNum = false;
    var inputKey = event.key;
    if(inputKey >= 0 || inputKey <= 0){
      isInputNum = true;
    }
    var key = event.keyCode || event.charCode;
    var keysAllow = [
      8, //Backspace
      37, // Left
      39, // Delete
      46, // Right
      109, 189, // -
      110, 190, 231, // .
      36, // Home
      35, // End
    ];
    if (isInputNum || keysAllow.indexOf(key) > -1) {
      return true;
    }
    return false;
  }

  getValidLicenceTransDate(){
    if (!this.creditForm.value.licenceTransDate
      || isUndefined(this.creditForm.value.licenceTransDate)
      || !this.creditForm.value.licenceTransDate.singleDate.jsDate) return '';

    var dateValid = formatDate(this.creditForm.value.licenceTransDate.singleDate.jsDate, 'yyyy-MM-dd', 'en-US');
    if (!dateValid) return '';

    return dateValid;
  }

  getAllDbUsers(id) {
    this.callInProgress(true);
    this.implementationsService.getAllUsers(id).subscribe(data=>{
      if(data.IsSuccess){
        this.dbUsersList = data.Result;

        this.getActivities(id);
      }
    },
    error => {
      this.callInProgress(false);
    })
  }

  loadActivities(event): void{
    if (event.target.value == ''){
      this.activitiesListFilter = this.activitiesList;
      return;
    }

    this.activitiesListFilter = this.activitiesList.filter(i=>i.user_id == event.target.value);
    if (this.activitiesListFilter.length == 0){
      this.callInProgress(true);
      // get activities of specific user
      const id = this.route.snapshot.paramMap.get("id");
      this.implementationsService.getActivitiesByUser(id, event.target.value).subscribe(data=>{
        if(data.IsSuccess){
          if (data.Result != null){
            let item = data.Result;
            if (item['invite_accepted'] != null && item['invite_accepted'] != ''){
              let dateAccept = moment(item['invite_accepted'] + ' UTC').format('YYYYMMDDHHMM')
              item['invite_accepted'] = moment(item['invite_accepted'] + ' UTC').format('D MMMM YYYY HH:mm')
              item['invite_accepted'] = item['invite_accepted'] + ' (' + moment(dateAccept, 'YYYYMMDDHHMM').fromNow() + ')'
            }
  
            if (item['invite_email_sent'] != null && item['invite_email_sent'] != ''){
              let dateInvite = moment(item['invite_email_sent'] + ' UTC').format('YYYYMMDDHHMM')
              item['invite_email_sent'] = moment(item['invite_email_sent'] + ' UTC').format('D MMMM YYYY HH:mm')
              item['invite_email_sent'] = item['invite_email_sent'] + ' (' + moment(dateInvite, 'YYYYMMDDHHMM').fromNow() + ')'
            }
  
            if (item['date_install_serviceApp'] != null && item['date_install_serviceApp'] != ''){
              let dateInstallApp = moment(item['date_install_serviceApp'] + ' UTC').format('YYYYMMDDHHMM')
              item['date_install_serviceApp'] = moment(item['date_install_serviceApp'] + ' UTC').format('D MMMM YYYY HH:mm')
              item['date_install_serviceApp'] = item['date_install_serviceApp'] + ' (' + moment(dateInstallApp, 'YYYYMMDDHHMM').fromNow() + ')'
            }
  
            if (item['last_logged_portal'] != null && item['last_logged_portal'] != ''){
              let lastLogPortal = moment(item['last_logged_portal'] + ' UTC').format('YYYYMMDDHHMM')
              item['last_logged_portal'] = moment(lastLogPortal, 'YYYYMMDDHHMM').fromNow()
            }
  
            if (item['wo_date_completed'] != null && item['wo_date_completed'] != ''){
              let dateCompleted = moment(item['wo_date_completed'] + ' UTC').format('YYYYMMDDHHMM')
              item['wo_completed'] = item['wo_completed'] + ', ' + moment(dateCompleted, 'YYYYMMDDHHMM').fromNow()
            }
            
            if (item['last_activity_serviceApp'] != null && item['last_activity_serviceApp'] != ''){
              let lastActivityDate = moment(item['last_activity_serviceApp'] + ' UTC').format('YYYYMMDDHHMM')
              item['last_activity_serviceApp'] = moment(lastActivityDate, 'YYYYMMDDHHMM').fromNow()
            }

            this.activitiesList.push(item);
            this.activitiesList.sort(this.dynamicSort('name'));
          }
          
          this.activitiesListFilter = this.activitiesList.filter(i=>i.user_id == event.target.value);
        }

        this.callInProgress(false);
      },
      error => {
        this.callInProgress(false);
      })
    }
  }

  //-- Power Bi
  getWorkspaceIdOfImp(impId){
    this.callInProgress(true);
    this.powerBiReport.getWorkspaceIdByImplementId(impId).subscribe(data => {
      this.isShowEnableBiDashboard = (data == "");
      this.callInProgress(false);
    },
    error => {
      this.callInProgress(false);
    })
  };

  enablePowerBiDashboard(){
    this.callInProgress(true);
    this.powerBiReport.createWorkspaceIdForImp(this.imp.Id).subscribe(data => {
      this.isShowEnableBiDashboard = false;
      this.callInProgress(false);
    },
    error => {
      this.callInProgress(false);
    })
  }

  //-- Breaking rules
  getReportBreakingRules(impId){
    this.reportBreakingRulesMsg = '';
    this.callInProgress(true);
    this.powerBiReport.getReportBreakingRules(impId).subscribe(data => {
      this.callInProgress(false);
      this.reportBreakingRules = data;
    },
    error => {
      if(error.ResponseException && error.ResponseException.ExceptionMessage){
        this.reportBreakingRulesMsg = error.ResponseException.ExceptionMessage;
      } else {
        this.reportBreakingRulesMsg = error;
      }
      this.callInProgress(false);
    })
  }

  publishReportOnImp(report_id, reportName){
    this.callInProgress(true);
      this.powerBiReport.publishReportOnImp(this.imp.Id, report_id, reportName).subscribe(data => {
        let publishMsg = '';
        if(data.ErrorMessage != null && data.ErrorMessage != '')
        {
          publishMsg = data.ErrorMessage;
        }
        //console.log('Publish result....');
        //console.log(data);
        if(data.Success)
        {
          this.getReportBreakingRules(this.imp.Id);
        }
        else {
          this.reportBreakingRules.forEach(item => {
            if(item.report_id == report_id) {
              item.publish_msg = publishMsg;
            }
            else {
              item.publish_msg = '';
            }
          });
          this.callInProgress(false);
        }
      },
      error =>{
        this.callInProgress(false);
      })
  }

  saveReportImplementationList() {
    this.reportBreakingRules.forEach(item => {
      if(item.is_visible == "null") item.is_visible = null;
      if(item.default_visibile == "null") item.default_visibile = null;      
    });

    this.callInProgress(true);
    this.powerBiReport.updateReportBreakingRules(this.reportBreakingRules).subscribe(data=>{
      this.callInProgress(false)
      if(data == 'true')
      {
         this.alertService.success("Data has been saved.");
      } else {
         this.alertService.error("Data has not been saved. Please contact the administrator.")
      }
    },
    error => {
      this.alertService.error(error.ResponseException.ExceptionMessage)
      this.callInProgress(false)
    })
  }

  get f() { return this.licenceForm.controls; }
  get creditsTypeControls() { return this.creditForm.controls; }
}

export class LicenceInfo{
  constructor(planType:string, planDate:string, mobileUser:string, plannedUser:string, planTypeId:string){
    this.LicencePlanType=planType
    this.LicencePlanTypeId=planTypeId
    this.LicencePlanDate=planDate
    this.MobileUser=mobileUser
    this.PlannedUser=plannedUser
  }
  public LicencePlanDate:string=''
  public LicencePlanType:string=''
  public MobileUser:string=''
  public PlannedUser: string=''
  public LicencePlanTypeId: string=''
}

export class DatabasePerformance{
  constructor(licencePerformanceLevel:string, actualPerformanceLevel:string, cpu: number, io: number, avg_cpu: number, avg_io: number, db_size: number){
    this.licencePerformanceLevel = licencePerformanceLevel
    this.actualPerformanceLevel = actualPerformanceLevel
    this.cpu = cpu
    this.io = io
    this.avg_cpu = avg_cpu
    this.avg_io = avg_io
    this.db_size = db_size
  }
  public licencePerformanceLevel:string=''
  public actualPerformanceLevel:string=''
  public cpu:number=0
  public io: number=0
  public avg_cpu:number=0
  public avg_io: number=0
  public db_size: number=0
}

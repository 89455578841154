import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { ImplementationComponent } from './implementation/implementation.component';
import { ImplementationItemComponent } from './implementation-item/implementation-item.component';
import { ImplementationNewComponent } from './implementation-new/implementation-new.component';
import { TrialRegistrationComponent } from 'components/trial-registration/trial-registration.component';
import { TrialDemoRegistrationComponent } from 'components/trial-demo-registration/trial-demo-registration.component';
import { TrialDemoSimpleRegistrationComponent } from 'components/trial-demo-simple-registration/trial-demo-simple-registration.component'
import { TrialDemoRegistrationMobileComponent } from 'components/trial-demo-registration-mobile/trial-demo-registration-mobile.component';
import { TrialDemoSimpleMobileRegistrationComponent } from 'components/trial-demo-simple-mobile-registration/trial-demo-simple-mobile-registration.component'
import { SidebarComponent } from './sidebar/sidebar.component';
import { LoginComponent } from './login/login.component';
import { AlertComponent } from './alert/alert.component';

import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { AuthGuard } from 'quards/auth.quard';
import { HomeComponent } from './home/home.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { OccExceptionComponent } from './occ-exception/occ-exception.component';
import { UpperCaseSplitPipe } from 'pipes/upper-case-split.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { ConsultancyOverviewComponent } from './consultancy-overview/consultancy-overview.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { PowerBiReportComponent } from './powerbi-report/powerbi-report.component';
import { PowerBiDashboardComponent } from './powerbi-dashboard/powerbi-dashboard.component';
import { LicenceReportComponent } from './licence-report/licence-report.component';
import { TippyModule } from 'ng-tippy';
import { FsaVersionListComponent } from './fsa-versions/fsa-app-versions-list.component';
import { SaVersionListComponent } from './sa-versions/sa-app-versions-list.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AgGridModule.withComponents([]),
    AngularMyDatePickerModule,
    TranslateModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TippyModule
  ],
  declarations: [
  HeaderComponent,
  ImplementationComponent,
  ImplementationItemComponent,
  SidebarComponent,
  HomeComponent,
  LoginComponent,
  AlertComponent,
  ImplementationNewComponent,
  TrialRegistrationComponent,
  TrialDemoSimpleRegistrationComponent,
  TrialDemoSimpleMobileRegistrationComponent,
  TrialDemoRegistrationComponent,
  TrialDemoRegistrationMobileComponent,
  UpperCaseSplitPipe,
  OccExceptionComponent,
  ConsultancyOverviewComponent,
  UserManagementComponent,
  PowerBiReportComponent,
  PowerBiDashboardComponent,
  LicenceReportComponent,
  FsaVersionListComponent,
  SaVersionListComponent
  ],

  exports: [
    HeaderComponent,
    LoginComponent, SidebarComponent
  ],
  providers: [AuthGuard, {
    provide: RECAPTCHA_LANGUAGE,
    useValue: 'en',
  }]

})
export class ComponentsModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class LicencePlanService {

  constructor(private http: HttpClient) { }

  getAll()
  {
    return this.http.get<any>(`${environment.apiUrl}/licenceplan/`).toPromise();
  }

  getAllPlans()
  {
    return this.http.get<any>(`${environment.apiUrl}/licenceplan/listallplans`).toPromise();
  }

  getByImp(implementationId)
  {
    return this.http.get<any>(`${environment.apiUrl}/licenceplan/list/${implementationId}`).toPromise();
  }

  getByImpIncludeOldPlan(implementationId)
  {
    return this.http.get<any>(`${environment.apiUrl}/licenceplan/listincludeoldplan/${implementationId}`).toPromise();
  }

  getById(id)
  {
    return this.http.get<any>(`${environment.apiUrl}/licenceplan/${id}`).toPromise();
  }

  getPlanWitBreakingRules(implementationId, id)
  {
    return this.http.get<any>(`${environment.apiUrl}/licenceplan/getplanwitbreakingrules/${implementationId}/${id}`).toPromise();
  }

  async getAvaible(): Promise<any> {
    const now = new Date();
    const utcTimestamp = new Date(now.getTime() + now.getTimezoneOffset() * 60000).getTime();
    let result: Array<Object> = [];
    let promise = await this.getAll();
    promise.Result.forEach(item => {
      if (item.archived == false && item.is_usable == true && new Date(item.start_date).getTime() <= utcTimestamp && new Date(item.end_date).getTime() >= utcTimestamp)
      result.push(item);
    });
    result = result.sort((a, b) => a['sequence'] - b['sequence'])
    return result;
  }
}

import { Injectable, NgModule } from '@angular/core';
import {DatePipe, DecimalPipe, CurrencyPipe } from '@angular/common'
import { TranslateService } from '@ngx-translate/core';

@NgModule({
  providers:[DatePipe, DecimalPipe]       
})

@Injectable({
  providedIn: 'root'
})

export class CultureService {

  private defaultCulture: object = {
    is_ampm: false,
    time_format: 'HH:mm',
    date_format: 'dd/MM/yy',
    number_format: '123,456,789.00',
    thousand_indicator: ',',
    decimal_indicator: '.',
    pre_negative: '(',
    suf_negative: ')'
  };

  private culture: object = {};

  constructor(private datePipe: DatePipe, private decimalPipe: DecimalPipe, private currencyPipe: CurrencyPipe, private translate: TranslateService) { }

  init(culture: string) {
    if (culture)
      this.culture = JSON.parse(culture);
  }

  public formatDate(value: any) {
    let format = this.culture['date_format'] || this.defaultCulture['date_format'];
    return this.datePipe.transform(value, format, null, this.translate.currentLang)
  }

  public formatTime(value: any) {
    let format = this.culture['time_format'] || this.defaultCulture['time_format'];
    let isAmpm = this.culture['is_ampm'] || this.defaultCulture['is_ampm'];
    format = isAmpm ? 'hh:mm a' : format;

    return this.datePipe.transform(value, format, null, this.translate.currentLang)
  }

  public formatDateTime(value: any) {
    let dateFormat = this.culture['date_format'] || this.defaultCulture['date_format'];
    let timeFormat = this.culture['time_format'] || this.defaultCulture['time_format'];
    let isAmpm = this.culture['is_ampm'] || this.defaultCulture['is_ampm'];
    timeFormat = isAmpm ? 'hh:mm a' : timeFormat;

    return this.datePipe.transform(value, dateFormat + ' ' + timeFormat, null, this.translate.currentLang)
  }

  public formatNumber(value: any) {

    let thousandIndicator = this.culture['thousand_indicator'] || this.defaultCulture['thousand_indicator'];
    let decimalIndicator = this.culture['decimal_indicator'] || this.defaultCulture['decimal_indicator'];
    let formated = this.decimalPipe.transform(value, '1.2-2');
    const arrayData = formated.split('.');
    formated = arrayData[0].replace(',', thousandIndicator) + decimalIndicator + arrayData[1];

    if (value < 0) {
      let preNegative = this.culture['pre_negative'] == undefined ? this.defaultCulture['pre_negative'] : this.culture['pre_negative'];
      let sufNegative = this.culture['suf_negative'] == undefined ? this.defaultCulture['suf_negative'] : this.culture['suf_negative'];
      formated = preNegative + (formated.replace('-', '')) + sufNegative;
    }

    return formated;
  }

  public formatCurrentcy(value: any, currency: string) {
    if (!currency) {
      currency = '€';
    }
    let formated = this.currencyPipe.transform(0, currency.toLocaleUpperCase(), 'symbol', '1.0', this.translate.currentLang);
    let numberFormat = this.formatNumber(value);

    return formated.toString().replace('0', numberFormat);
  }

  public formatInteger(value: any) {
    let formated = this.formatNumber(value);
    let decimalIndicator = this.culture['decimal_indicator'] || this.defaultCulture['decimal_indicator'];
    if (formated.toString().endsWith(decimalIndicator + '00'))
      formated = formated.toString().replace(decimalIndicator + '00', '');

    return formated;
  }

  public formatIntegerWithNegative(value: any) {
    let formated = this.formatNumber(value);
    let decimalIndicator = this.culture['decimal_indicator'] || this.defaultCulture['decimal_indicator'];

    let valueFormated = formated.toString().replace(')','').replace('-','').trim();
    if (valueFormated.endsWith(decimalIndicator + '00'))
      formated = formated.toString().replace(decimalIndicator + '00', '');

    return formated;
  }

  public formatNumberOfSize(value: any) {
    value = parseFloat(value.toFixed(2));
    let thousandIndicator = this.culture['thousand_indicator'] || this.defaultCulture['thousand_indicator'];
    let decimalIndicator = this.culture['decimal_indicator'] || this.defaultCulture['decimal_indicator'];
    let formated = this.decimalPipe.transform(value, '1.2-2');
    const arrayData = formated.split('.');
    formated = arrayData[0].replace(',', thousandIndicator) + decimalIndicator + arrayData[1];

    var splitted = formated.split(decimalIndicator, 2); 
    if  (splitted != null && splitted.length > 0 && splitted[1] == '00'){
      formated = splitted[0];
    }

    if (value < 0) {
      let preNegative = this.culture['pre_negative'] == undefined ? this.defaultCulture['pre_negative'] : this.culture['pre_negative'];
      let sufNegative = this.culture['suf_negative'] == undefined ? this.defaultCulture['suf_negative'] : this.culture['suf_negative'];
      formated = preNegative + (formated.replace('-', '')) + sufNegative;
    }

    return formated;
  }
}


export class Implementation {
  archived: boolean
  Id: string
  Company_name: string
  Company_domain: string
  Company_email: string
  Database_name: string
  Database_hostname: string
  Database_ip: string
  Database_login: string
  Database_password: string
  date_add: string
  is_invoicable: boolean
  is_trial: boolean
  User_id: string
  Status: boolean
  StatusText: string
  Owner: string
  DateValidUntil: Date
  CreatedBy: string
  DbServerCode: string
  Nb_allowed_users: number
  legacy_occ_implementation: any
  allow_invoice: boolean
  invoice_due_date_days: number
  Account_manager_user_id: string
  allow_portaldev: boolean
  domain_encrypt: string
  superadmin_username_encrypt: string
  superadmin_password_encrypt: string
  required_po_number: boolean
  description: string
  stripe_customer_code: string
}

export enum CreditTypes {
  SUBCONTRACTOR='Subcontractor',
  CONSULTANCY='Consultancy'
}

export class ApplicationVersion {
    id: string
    application_code: string
    version: string
    android: boolean
    ios: boolean
    uwp: boolean
    dateutc_added: Date
    dateutc_end: Date
    release_note: string
    note: string
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExceptionService {

  constructor(private http: HttpClient) { }

   getExceptions(params) {
     return this.http.post<any>(`${environment.apiUrl}/exception`, params).pipe(map(x => x.Result));
  }

  getExceptionById(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/exception/getExceptionById/${id}`).pipe(map(x => x.Result));
  }

  getAppCodes()
  {
    return this.http.get<any>(`${environment.apiUrl}/exception/appcode`).pipe(map(x => x.Result));
  }

  getDomains() {
    return this.http.get<any>(`${environment.apiUrl}/exception/domain`).pipe(map(x => x.Result));
  }

  deleteException(params) {
    return this.http.put<any>(`${environment.apiUrl}/exception/delete`, params).pipe(map(x => x.Result));
  }
}

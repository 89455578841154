import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PredefinedValueService {

  constructor(private http: HttpClient) { }

  getByTypeCode(typeCode) {
    return this.http
      .post<any>(`${environment.apiUrl}/PredefinedValue`, {type_code: typeCode})
      .pipe(map(x => x.Result));
  }
}

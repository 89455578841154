import { Component, HostBinding, NgModule, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { User } from 'models/user'
import { PowerBiReport } from 'services'
import { TranslateService } from '@ngx-translate/core';
import moment from "moment"

@Component({
  selector: "[app-powerbi-dashboard]",
  templateUrl: "./powerbi-dashboard.component.html",
  styleUrls: ["./powerbi-dashboard.component.scss"]
})

export class PowerBiDashboardComponent implements OnInit {
  loading: Boolean = false
  workspaces: any[]
  reports: any[]
  embedParamsReport: any
  reportsTemplateName: any[]
  reportTemplateSelected: string = ""
  implementationId: string = ""
  currentLang: string='en'
  displayReportMode = 'full'
  reportMessages: string = "";
  reportLastRefreshed: string = "";

  constructor(
    private route: ActivatedRoute,
    private powerBiReport: PowerBiReport,
    public translate: TranslateService
  ) {
    
  }
    ngOnInit() {
      this.implementationId = this.route.snapshot.paramMap.get("id")
      if(this.implementationId != null && this.implementationId != "")
      {
        this.listWorkspacesByImp(this.implementationId);
      }

      const lang = this.route.snapshot.paramMap.get("language")
      this.translate.use(lang.indexOf('-') != -1 ? lang.split('-')[0] : lang);

      this.currentLang = lang.indexOf('-') != -1 ? lang.split('-')[0] : lang;
    }

    callInProgress(inProgress: boolean){
      this.loading = inProgress
    }

    listWorkspacesByImp(impId){
      this.callInProgress(true)
      this.powerBiReport.getCustomReportByImplementId(impId).subscribe(data => {
        this.callInProgress(false);

        this.workspaces = data.Workspaces;
        this.reports = data.Reports;
        this.reportsTemplateName = data.ReportsTemplateName;
        //this.reportTemplateSelected = data.ReportTemplateSelected;

        if(this.reportsTemplateName == null || this.reportsTemplateName.length == 0)
        { 
          this.translate.get('msg_long_powerBiDashboard_ReportNotEnabled').subscribe(res => {
            this.reportMessages = res;
          });
          return;
        }

        if(data.ErrorMessages != null && data.ErrorMessages != '')
        {
          console.log("listWorkspacesByImp error: " + data.ErrorMessages);          
        }
        if(data.EmbedParamsReport != null)
        {
          this.embedParamsReport = data.EmbedParamsReport;
          //this.showReport(data.EmbedParamsReport);
        }
      },
      error =>{
        this.callInProgress(false)
      })
    }

    refreshPowerBiDashboard(){
      if(this.workspaces == null || this.workspaces.length == 0)
      {
        console.log("workspaces is null or empty");
        return;
      }

      this.callInProgress(true)
      this.powerBiReport.refreshCustomReport(this.workspaces[0].id, this.reportTemplateSelected).subscribe(data => {
        this.callInProgress(false)
        if(data.ErrorMessages != null && data.ErrorMessages != '')
        {
          console.log("refreshPowerBiDashboard error: " + data.ErrorMessages);
        }
        else
        {
          this.showReport(data.EmbedParamsReport, true);

          this.calLastRefreshed(data.LastRefreshed);
        }
      },
      error =>{
        this.callInProgress(false)
      })
    }

    calLastRefreshed(lastRefreshed){
      var lastRefreshLocalTime = moment.utc(lastRefreshed).local();
      var nowLocalTime = moment.utc().local();
      var secondsDiff = nowLocalTime.diff(lastRefreshLocalTime, 'seconds');
      /* if(secondsDiff < 60)
      {
        this.translate.get('title_shrt_PowerBi_UpdateAgo').subscribe(res => {
          this.reportLastRefreshed = '( ' + res.replace('{0}', secondsDiff) + ' )';
        });
      }
      else
      {
        this.translate.get('title_shrt_PowerBi_UpdateAt').subscribe(res => {
          this.reportLastRefreshed = '( ' + res + ' ' + lastRefreshLocalTime.format('YYYY-MM-DD HH:mm:ss') + ' )';
          
        });               
      }
 */
      this.translate.get('title_shrt_PowerBi_LastUpdate').subscribe(res => {
          this.reportLastRefreshed = res + ' ' + moment(lastRefreshLocalTime).fromNow();
        });
      
    }

    showReport(data, isRefresh){
        let embedParams = data;
        let models = window["powerbi-client"].models;
        let reportContainer = document.getElementById('report-container');
        let height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        let width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - 300;

        let reportLoadConfig = {
          type: "report",
          tokenType: models.TokenType.Embed,
          accessToken: embedParams.EmbedToken.token,
          // You can embed different reports as per your need
          embedUrl: embedParams.EmbedReport[0].EmbedUrl,
          pageView: "fitToWidth",
          // Enable this setting to remove gray shoulders from embedded report
          // settings: {
          //     background: models.BackgroundType.Transparent
          // }

          settings: {
            panes: {
                pageNavigation: {
                    visible: false
                }
            },
            /* layoutType: models.LayoutType.Custom,
            customLayout: {
              pageSize: {
                type: models.PageSizeType.Custom,
                height: height,
                width: width
              }
            } */
          }
        };

        // Use the token expiry to regenerate Embed token for seamless end user experience
        // Refer https://aka.ms/RefreshEmbedToken
        //let tokenExpiry = embedParams.EmbedToken.expiration;

        if(isRefresh)
        {
          console.log('Refreshing ...');
          let powerbi = window['powerbi'];
          powerbi.preload(reportLoadConfig);

          let reportContainer = document.getElementById('report-container');
          var report = powerbi.get(reportContainer);
          if(report != null)
          {
            report.refresh().then(function (result) {
              console.log("Refreshed");
            })
            .catch(function (errors) {
              console.log('Refresh report error: ' + errors);
            });
          }

          return;
        }

        // Embed Power BI report when Access token and Embed URL are available
        var report = window['powerbi'].embedNew(reportContainer, reportLoadConfig);

        // Clear any other loaded handler events
        report.off("loaded");

        // Triggers when a report schema is successfully loaded
        let reportName = this.reportTemplateSelected;
        /* report.on("loaded", function () {
            // 2023-05-11 - Temporarily do not resize 'Customer Excellence' report
            if(reportName == 'Customer Excellence'){
              return;
            }

            let reportHeight = Math.floor((height * 82)/100);
            let reportWidth = width - 50;
            //console.log("Window height/width: " + reportHeight + ' / ' + reportWidth);

            let mainVisualName = reportName.replace(/ /g, "").toLowerCase() + '_';
            var promiseGetPages = report.getPages();
            promiseGetPages.then(function(pages) {
              try
              {
                for(let index = 0; index < pages.length; index ++)
                {
                  pages[index].getVisuals().then(function (visuals) {
                    if(visuals != null && visuals.length > 0)
                    {
                      if(visuals.length == 1)
                      {
                        visuals[0].resizeVisual(reportWidth, reportHeight).catch(error => {
                          console.log('MainDashboard resizeVisual error: ' + error);
                        });
                      }
                      else {
                        var mainVisual = visuals.find(x => x.title != null && (x.title.replace(/ /g, "").toLowerCase().startsWith(mainVisualName)
                                                                              || (x.title == "Matrix")));
                        var filterVisual = visuals.find(x => x.type != null && x.type == 'textbox');
                        if(mainVisual != undefined && mainVisual != null)
                        {
                          mainVisual.resizeVisual(reportWidth, reportHeight).catch(error => {
                            console.log('MainDashboard resizeVisual error: ' + error);
                          });
                        }

                        if(filterVisual != undefined && filterVisual != null)
                        {
                          filterVisual.resizeVisual(reportWidth, filterVisual.height).catch(error => {
                            console.log('Filter resizeVisual error: ' + error);
                          });
                        }
                      }
                    }
                  });
                }
              }
              catch(error)
              {
                console.log('getPages error:' + error);
              }
            }, function() {});
        }); */

        // Clear any other rendered handler events
        report.off("rendered");

        // Triggers when a report is successfully embedded in UI
        report.on("rendered", function () {
      
          //console.log("Report render successful");
        });

        // Clear any other error handler events
        report.off("error");
        
        // Handle embed errors
        report.on("error", function (event) {
            var errorMsg = event.detail;
        
            // Use errorMsg variable to log error in any destination of choice
            console.error(errorMsg);
            return;
        });
    }

    changeDisplayReportMode(){
      this.displayReportMode = this.displayReportMode == 'full' ? 'compact' : 'full';
    }

    getEmbedReport(reportName){
      if(this.reportTemplateSelected == reportName)
      {
        return;
      }

      this.reportTemplateSelected = reportName;
      this.callInProgress(true);
      this.powerBiReport.getEmbedReportByName(this.implementationId, this.workspaces[0].id, reportName).subscribe(data => {
        this.callInProgress(false)
        if(data != null)
        {
          this.showReport(data.EmbedParamsReport, false);
          this.calLastRefreshed(data.LastRefreshed);
        }
        if(data.ErrorMessages != null && data.ErrorMessages != '')
        {
          console.log(data.ErrorMessages);
        }
      },
      error =>{
        this.callInProgress(false);
      })
    }
  
    @HostBinding("class") get themeClass() {
      return "u-scroll o-flex__item o-flex o-flex--vertical"
    }
}
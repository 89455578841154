import { Component, OnInit, HostBinding, ViewChild, ViewContainerRef, ElementRef } from '@angular/core'


import { AutoWidthCalculator, GridOptions , IDatasource, IGetRowsParams  } from 'ag-grid-community'
import { AuthenticationService, AlertService, ExceptionService, ExceptionSearchService, UsageTrackingService, ExceptionFilterService } from 'services'
import { AgGridNg2 } from 'ag-grid-angular'
import { User } from 'models/user'
import { Exception } from 'models/exception'
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm, AbstractControl } from '@angular/forms'
import { IAngularMyDpOptions, IMyDateModel, AngularMyDatePickerDirective } from 'angular-mydatepicker'

import  moment from "moment"
import { stringify } from 'querystring'

declare var utilObject: any;

@Component({
  selector: 'app-occ-exception',
  templateUrl: './occ-exception.component.html',
  styleUrls: ['./occ-exception.component.scss']
})
export class OccExceptionComponent implements OnInit {

  public searchExcForm: FormGroup
  public saveSearchForm: FormGroup
  public addExcFilterForm: FormGroup
  public usageTrackingForm: FormGroup
  public currentLUser:User
  //@ViewChild('exceptionNGrid', { static: true }) myGrid: AgGridNg2
  @ViewChild('dp', { static: true}) myDp: AngularMyDatePickerDirective
  @ViewChild('searchExcFormDirective', { static: true }) searchExcFormDirective: NgForm;
  public gridOptions: GridOptions
  public gridApi
  public gridColumnApi
  public columnDefs
  public defaultColDef
  public cacheOverflowSize
  public maxConcurrentDatasourceRequests
  public infiniteInitialRowCount
  public rowData: any
  public exceptionsSearchCount: number=0
  
  public showModal: Boolean = false
  public showInput: boolean=true
  public showCustomDate: boolean=false
  public saveSubmitted = false
  public loading:boolean
  
  public dateRangeModel: IMyDateModel = null;   // not initial date set
  myDpOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateRangeDatesDelimiter: "#",
    dateFormat: 'dd-mmm-yyyy',
  }
  public LocalDateOptionsList : string[]
  public DomainList: any
  public AppCodeList: any
  public AllString: string="All"
  public PredefinedSearchList: any
  @ViewChild('agGrid',{static: false}) agGrid;
  @ViewChild('agGridTracking',{static: false}) agGridTracking;

  public currentAction: enumCurrentAction = enumCurrentAction.None
  public isShowConfirm: boolean=false
  public confirmMsg:string=""
  public isProcessAction: boolean=false;

  // Detail exception
  public showExceptionDetail: boolean=false
  public exceptionSelected: Exception
  public isShowAddExcFilter: boolean=false

  // Usage tracking
  public gridTrackingApi
  public gridTrackingColumnApi
  public showUsageTracking: boolean=false
  public columnDefsTracking
  public defaultColDefTracking
  public gridTrackingOptions
  public rowTrackingData: any[]
  public currentTrackingDate: Date = new Date()
  public preTrackingDate: Date = new Date()
  public isTrackingGetting: boolean = false

  // Exception filter
  public isExcFilterFormSubmited: boolean = false
  public addExcFilterResult:string="";
  
  constructor(private  exceptionService: ExceptionService,
    private exceptionSearchService: ExceptionSearchService, 
    private authService: AuthenticationService, 
    private route: ActivatedRoute, private router: Router,
    private alertService: AlertService,
    private usageTrackingService: UsageTrackingService,
    private exceptionFilterService: ExceptionFilterService,
    private formBuilder: FormBuilder) {
    this.columnDefs = [
      { headerName: 'Date', field: 'date_generated', filter: "agDateColumnFilter", width: 130, suppressSizeToFit: true,
      cellRenderer: params => { return moment(params.value).format("DD-MMM-YYYY HH:mm") },
      filterParams: {
        filterOptions: ['equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange'],
        inRangeInclusive: true, 
        comparator: function(filterLocalDateAtMidnight, cellValue) {
          
          let returnRez=0
          var dateAsString = moment(cellValue).format('DD/MM/YYYY')
          
          var dateParts = dateAsString.split("/")
          var day = Number(dateParts[0])
          var month = Number(dateParts[1]) - 1
          var year = Number(dateParts[2])
          var cellDate = new Date(year, month, day)

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        }
      } },
      { headerName: 'Domain', field: 'ImplementationDomain', filter: 'agTextColumnFilter', width: 130, suppressSizeToFit: true, filterParams: { applyButton: true, clearButton:true }},
      { headerName: 'App', field: 'application_code', filter: "agTextColumnFilter", width: 120, suppressSizeToFit: true },
      { headerName: 'Version', field: 'application_version', filter: "agTextColumnFilter", width: 100, suppressSizeToFit: true },
      { headerName: 'Message', field: 'message', filter: "agTextColumnFilter", width: 150, suppressSizeToFit: true },
      { headerName: 'Exc', field: 'exception_message', filter: "agTextColumnFilter", with:AutoWidthCalculator },
      { headerName: 'Device', field: 'device_code', filter: "agTextColumnFilter", width: 200, suppressSizeToFit: true },
      { headerName: 'User Name', field: 'username', filter: "agTextColumnFilter", width: 150, suppressSizeToFit: true }
    ];

    this.defaultColDef = {sortable: true, filter: true, resizable: true };

    this.gridOptions = {
      headerHeight: 45,
      rowHeight: 30,
      cacheBlockSize: 500,
      paginationPageSize: 100,
      rowModelType: 'serverSide',
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 2,
      infiniteInitialRowCount: 1,
      maxBlocksInCache: 2
     }

     this.rowTrackingData = [];
     this.columnDefsTracking = [
      { headerName: 'View', field: 'view_name', filter: "agTextColumnFilter", width: 150, suppressSizeToFit: true },
      { headerName: 'Action', field: 'action_type', filter: "agTextColumnFilter", width: 150, suppressSizeToFit: true },
      { headerName: 'Date Utc', field: 'dateutc_start', filter: "agDateColumnFilter", width: 160, suppressSizeToFit: true,
        cellRenderer: params => { return moment(params.value).format("DD-MMM-YYYY HH:mm:ss.SSS") } },
      { headerName: 'Comment', field: 'comment', filter: "agTextColumnFilter", width: 450, suppressSizeToFit: true }
    ];

    this.defaultColDefTracking = {sortable: true, filter: false, resizable: true };

   }
   ngOnInit() {
    
    this.authService.currentUser.subscribe(x=>this.currentLUser=x)
     if(!this.currentLUser.AllowSeeException) {
         this.router.navigate(["/"])
     }
     this.callInProgress(false)
     this.getDomains()
     this.getAppCodes()
     this.getPredefinedSrch()
     const optionsJsObj = Object.keys(enumDateOptions);
     this.LocalDateOptionsList = optionsJsObj.slice(optionsJsObj.length / 2);
     this.searchExcForm = this.formBuilder.group({
      domain: [''],
      appCode: [this.AllString],
      dateAdded:[this.LocalDateOptionsList[0]],
      customDateRange:[],
      appVersion:[],
      message:[],
      exception:[],
      predefinedSearch:[],
      username: [],
      showArchivedExceptions: []
    })

    this.saveSearchForm=this.formBuilder.group({
      predefinedSearchName: ['', Validators.required],
      is_private: [false]
     
    })

    this.addExcFilterForm = this.formBuilder.group({
      implementation_id: [],
      application_code: [],
      application_version:[],
      message: [],
      exception_message: [],
      username: [],
      description: ['', Validators.required],
      action: '1'
    })
    this.usageTrackingForm = this.formBuilder.group({
    })

    this.gridOptions = {} as GridOptions;
    this.gridOptions.rowModelType = 'serverSide';

    this.exceptionSelected = {} as Exception;
    this.gridTrackingOptions = {} as GridOptions
  }

  get searchExcFormControls() { return this.saveSearchForm.controls}
  get saveSearchFormControls() { return this.saveSearchForm.controls }
  getDomains(){
     this.exceptionService.getDomains().subscribe(data => {
      this.DomainList=data
      this.DomainList.splice(0, 0, {'Id':'', 'Company_domain':this.AllString});
      this.searchExcForm.get('domain').setValue('');
    });
  }

  getAppCodes() {
    this.exceptionService.getAppCodes().subscribe(data => {
      this.AppCodeList=data
      this.AppCodeList.splice(0, 0, this.AllString);
      this.searchExcForm.get('appCode').setValue(this.AllString);
    });
  }

  getPredefinedSrch(){
    this.exceptionSearchService.getPredefinedSearch().subscribe(data => {
      this.PredefinedSearchList=data
      this.searchExcForm.get('predefinedSearch').setValue('');
    });
  }
  
   onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.agGrid.api.showNoRowsOverlay();
    this.agGrid.api.hideOverlay();
     this.agGrid.api.sizeColumnsToFit();

     var exceptionId = this.route.snapshot.paramMap.get("id") || '';
     if (exceptionId != '') {
       this.exceptionService.getExceptionById(exceptionId)
         .subscribe(result => {
           this.exceptionSelected = result;
           this.exceptionSelected.app_code_version = this.exceptionSelected.application_code;
           if (this.exceptionSelected.application_version > '') this.exceptionSelected.app_code_version += "( " + this.exceptionSelected.application_version + " )";

           this.onShowExceptionDetail(true);
       });       
     }
  }

  onRowClicked(event: any) {
    this.exceptionSelected = event.data;
    this.exceptionSelected.app_code_version = this.exceptionSelected.application_code;
    if(this.exceptionSelected.application_version > '') this.exceptionSelected.app_code_version += "( " + this.exceptionSelected.application_version + " )";

    event.node.setSelected(true);

    this.onShowExceptionDetail(true);
  }
  
  onFirstDataRendered(){
    
  }

  filterChanged() {
    
  }

  onDateFilterChanged($dataBindValue)
  {
      let isCustomDateRange=$dataBindValue==this.LocalDateOptionsList[4]
      this.showCustomDate=isCustomDateRange
  }
  onSaveSearch()
  {
    if (this.searchExcForm.invalid) { return }
      
    if(this.searchExcForm.value.dateAdded==this.LocalDateOptionsList[4] && (this.searchExcForm.controls['customDateRange'].value ===null || !this.searchExcForm.controls['customDateRange'].valid))
    {
      this.alertService.error("Please select a date for custom option.")
      return       
    }
    this.showConfirmation(true)
  }
  saveSearch()
  {
    this.saveSubmitted = true
    if (this.saveSearchForm.invalid) { return }
    
    this.searchExcForm.value.appCode= this.searchExcForm.value.appCode==this.AllString? null:this.searchExcForm.value.appCode
    var savedObject={
      date_add: moment().startOf('day').format(),
      name: this.saveSearchForm.value.predefinedSearchName,
      user_id: this.currentLUser.id,
      is_private:this.saveSearchForm.value.is_private,
      parameters: JSON.stringify(this.searchExcForm.value)
    }

    this.callInProgress(true)
    this.exceptionSearchService.save(savedObject).subscribe(
      data=>{
      if(data.Success)
            {
              this.alertService.success("Save parameters has been successful saved.")
              this.getPredefinedSrch()
            }
            else{
              this.alertService.error(data.result.ErrorMessage)
            }
            this.callInProgress(false)
            this.showConfirmation(false)
    })
  }
  
  search() {

    let parameters = {
      implementation_id: this.searchExcForm.value['domain'],
      application_code: this.searchExcForm.value['appCode'] == this.AllString ? null : this.searchExcForm.value['appCode'],
      application_version: this.searchExcForm.value['appVersion'],
      message: this.searchExcForm.value['message'],
      exception_message: this.searchExcForm.value['exception'],
      username: this.searchExcForm.value['username'],
      showArchivedExceptions: this.searchExcForm.value['showArchivedExceptions'],
    };
    
    let start = null;
    let end = null;
    this.exceptionsSearchCount = 0;

    switch(this.searchExcForm.value['dateAdded']) {
      case this.LocalDateOptionsList[1]: // yesterday
        start = end = moment().subtract(1, 'day');
        break;

      case this.LocalDateOptionsList[2]: // this weeks
        start = moment().startOf('isoWeek');
        end = moment().endOf('isoWeek');
        break;
      
      case this.LocalDateOptionsList[3]: // last weeks
        start = moment().subtract(1,'weeks').startOf('isoWeek');
        end = moment().subtract(1,'weeks').endOf('isoWeek');
        break;
      
      case this.LocalDateOptionsList[4]: // custom
        if (this.searchExcForm.value['customDateRange']) {
          start = this.searchExcForm.value['customDateRange']['dateRange']['beginJsDate'];
          end = this.searchExcForm.value['customDateRange']['dateRange']['endJsDate'];
        }
        break;

      default:
        start = moment();
        end = moment();
        break;
    }

    parameters['date_add'] = start ? moment(start).startOf('day').format() : null;
    parameters['date_end'] = end ? moment(end).endOf('day').format() : null;

    var datasource = {
      getRows: (params: IGetRowsParams) => {
        params['request'].perge = function() {
          this.gridOptions.api.deselectAll();
          this.gridOptions.api.purgeServerSideCache(undefined);
        };
        parameters['grid_server_params'] = params['request'];
        this.exceptionService.getExceptions(parameters)
          .subscribe(resultForGrid => {
            this.exceptionsSearchCount += resultForGrid.length;
            var data = resultForGrid.length == this.gridOptions.cacheBlockSize + 1 ? resultForGrid.splice(0, resultForGrid.length == 1 ? 1 : resultForGrid.length - 1) : resultForGrid;
            params.successCallback(data, resultForGrid.length == 1 && data.length == this.gridOptions.cacheBlockSize ? params['request'].startRow + data.length + this.gridOptions.cacheBlockSize : params['request'].startRow + data.length);
        });
      }
    }

    this.gridApi.setServerSideDatasource(datasource);
    
  }
  
  onSearchReset(){
     
    this.searchExcForm.patchValue({
      domain: '',
      appCode: this.AllString,
      dateAdded:this.LocalDateOptionsList[0],
      customDateRange:'',
      appVersion:'',
      message:'',
      exception:'',
      predefinedSearch:'',
      username: '',
      showArchivedExceptions: ''
    })
    
    this.showCustomDate=false
    this.resetSaveSearchForm()
  }

  resetSaveSearchForm(){

    this.saveSearchFormControls.predefinedSearchName.setValue('')
    this.saveSearchFormControls.is_private.setValue(false)
    this.saveSearchForm.markAsUntouched()
  }
  ddlPredefinedChanged($event)
  {
    var selectedPreSearch= this.PredefinedSearchList.filter(x=>x.id==$event.target.value)

    if(selectedPreSearch.length>0){
        var params=JSON.parse(selectedPreSearch[0].parameters)
        var isDateCustom=params.dateAdded==this.LocalDateOptionsList[4] 
      
        this.searchExcForm.patchValue({
          domain: params.domain,
          appCode: params.appCode==null?this.AllString: params.appCode,
          dateAdded: params.dateAdded,
          customDateRange:params.customDateRange,
          appVersion:params.appVersion,
          message:params.message,
          exception:params.exception,
          username: params.username,
          showArchivedExceptions: params.showArchivedExceptions
        })
        this.showCustomDate=isDateCustom
    }
  }

  showConfirmation(visible: boolean) {
    this.showModal = visible
  }

  callInProgress(inProgress: boolean){
    this.loading = inProgress
  }

  @HostBinding("class") get themeClass() {
    return "u-scroll o-flex__item o-flex o-flex--vertical"
  }

  formatDisplayDate(value: Date, strFormat:string){
    if(value == null) return "";
    var result = moment(value).format(strFormat).toString();
    return result;
  }

  //#region "Confirm model"
  onShowConfirmDeleteExc(visible: boolean, action: enumCurrentAction = enumCurrentAction.None){
    if (action == enumCurrentAction.CreateExceptionFilter && this.addExcFilterForm.invalid) { 
      this.currentAction = action;
      return; 
    }

    this.isShowConfirm = visible
    if(!visible){
      this.currentAction = enumCurrentAction.None;
      this.confirmMsg = "";
    }else{
      this.currentAction = action;
      if(this.currentAction == enumCurrentAction.DeleteException){
        this.confirmMsg = "Are you sure you want to delete this exception ?"; 
      }
      if(this.currentAction == enumCurrentAction.CreateExceptionFilter){
        this.confirmMsg = "Are you sure you want to create a new exception filter and delete exceptions of " + this.exceptionSelected.application_code + " ?"; 
      }      
    }
  }

  onExecuteAction(){
    if(this.currentAction == enumCurrentAction.DeleteException){
      this.onDeleteException();
    } else if(this.currentAction == enumCurrentAction.CreateExceptionFilter){
      this.onAddExcFilter();
    }  
  }
  //#endregion "Confirm model"

  //#region "For Exception detail"
  onShowExceptionDetail(visible: boolean){
    this.showExceptionDetail = visible
  }

  onDeleteException(){
    let params = {
      id: this.exceptionSelected.id
    };
    this.isProcessAction = true;
    this.exceptionService.deleteException(params).subscribe(
      data => {
        this.isShowConfirm = false;
        this.showExceptionDetail = false;
        this.isProcessAction = false;
        this.alertService.success("Exception deleted successfully.")
        this.search();
      },
      error => {
        this.alertService.error(error.message);
        this.isProcessAction = false;
      }
    )
  }

  copyToClipboard(text: string) {
    utilObject.copyToClipboard(window.location.protocol + '//' + window.location.host + '/exception/' + text);
  }

  //#endregion "For Exception detail"
  //#region "For UsageTracking"
  showViewUsageTracking(visible: boolean){
    this.showUsageTracking = visible
    if(visible){
      this.currentTrackingDate = new Date(this.exceptionSelected.date_generated);
      this.preTrackingDate = new Date(this.currentTrackingDate);
      this.preTrackingDate.setMinutes(this.preTrackingDate.getMinutes()-5);
      this.onGetUsageTracking(this.currentTrackingDate);
    }
  }

  onGridTrackingReady(params) {
    this.agGridTracking.api.showNoRowsOverlay();
    this.agGridTracking.api.hideOverlay();
  }

  onGetUsageTracking(trackingDate) {
    var fromDateParam = new Date(trackingDate);
    fromDateParam.setMinutes(fromDateParam.getMinutes()-5);
    let params = {
      from_date: this.formatDisplayDate(fromDateParam, "YYYY/MM/DD HH:mm:ss"),
      to_date: this.formatDisplayDate(trackingDate, "YYYY/MM/DD HH:mm:ss"),
      implementation_id: this.exceptionSelected.implementation_id,
      application_code: this.exceptionSelected.application_code,
      username: this.exceptionSelected.username
    };
    this.isTrackingGetting = true;
    this.agGridTracking.api.showLoadingOverlay();
    this.usageTrackingService.getUsageTracking(params).subscribe(
      data => {
        this.rowTrackingData = data
        this.isTrackingGetting = false
        this.agGridTracking.api.hideOverlay();
      },
      error => {
        this.agGridTracking.api.showNoRowsOverlay();
        this.agGridTracking.api.hideOverlay();
        this.isTrackingGetting = false;
      }
    )
    
    this.agGridTracking.api.sizeColumnsToFit()
  }

  onPreviousUsageTracking(){
    this.currentTrackingDate.setMinutes(this.currentTrackingDate.getMinutes()-5);
    this.preTrackingDate.setMinutes(this.preTrackingDate.getMinutes()-5);
    this.onGetUsageTracking(this.currentTrackingDate);
  }

  onNextUsageTracking(){
    this.currentTrackingDate.setMinutes(this.currentTrackingDate.getMinutes()+5);
    this.preTrackingDate.setMinutes(this.preTrackingDate.getMinutes()+5);
    this.onGetUsageTracking(this.currentTrackingDate);
  }

  //#endregion "For UsageTracking"

  //#region "For Exception Filter"
  onShowAddExcFilter(visible: boolean){
    this.currentAction = enumCurrentAction.None;
    this.isShowAddExcFilter = visible;
    this.isExcFilterFormSubmited = false;
    this.addExcFilterResult="";
    if(!visible){
     this.addExcFilterForm.reset();
    }
    else{
      this.addExcFilterForm.patchValue({
        implementation_id: this.exceptionSelected.implementation_id,
        application_code: this.exceptionSelected.application_code,
        application_version: this.exceptionSelected.application_version,
        message: this.exceptionSelected.message,
        exception_message: this.exceptionSelected.exception_message,
        username: this.exceptionSelected.username,
        description: "",
        action: "1"
      });
    }

  }

  onClearExcFilterForm(key: string){
    switch(key)
    {
      case "implementation_id":
        this.addExcFilterForm.patchValue({
          implementation_id:""
        });
        break;

        case "application_code":
        this.addExcFilterForm.patchValue({
          application_code:""
        });
        break;

        case "application_version":
        this.addExcFilterForm.patchValue({
          application_version:""
        });
        break;

        case "message":
        this.addExcFilterForm.patchValue({
          message:""
        });
        break;

        case "exception_message":
        this.addExcFilterForm.patchValue({
          exception_message:""
        });
        break;

        case "username":
        this.addExcFilterForm.patchValue({
          username: ""
        });
        break;
    }    
  }

  onAddExcFilter(){
    this.isExcFilterFormSubmited = true;
    if (this.addExcFilterForm.invalid) { return }
    this.isProcessAction = true;
    var dateAdd = this.formatDisplayDate(this.exceptionSelected.date_generated, "YYYY/MM/DD HH:mm:ss");
    let params = {
      implementation_id: this.addExcFilterForm.value['implementation_id'],
      application_code: this.addExcFilterForm.value['application_code'],
      application_version: this.addExcFilterForm.value['application_version'],
      message: this.addExcFilterForm.value['message'],
      exception_message : this.addExcFilterForm.value['exception_message'],
      description: this.addExcFilterForm.value['description'],
      nb_proceeded: 0,
      date_add: dateAdd,
      date_last_found: dateAdd,
      username: this.addExcFilterForm.value['username'],
      device_code: null,
      auto_archive: this.addExcFilterForm.value['action'] == 2 ? 1 : 0,
      auto_delete: this.addExcFilterForm.value['action'] == 1 ? 1 : 0
    };

    this.exceptionFilterService.addExceptionFilterAndDeleteException(params).subscribe(
      data=>{
            if(data.Success)
            {
              this.alertService.success("Exception filter created successfully.")
              this.isShowAddExcFilter = false;
              this.isShowConfirm = false;
              this.showExceptionDetail = false;              
              this.search();
            }
            else{
              this.addExcFilterResult = data.result.ErrorMessage;
            }
            this.isProcessAction = false;
      })
  }

  onExcFilterFormHasError(filedName:string){
    var result = this.isExcFilterFormSubmited
                  && this.addExcFilterForm.controls[filedName].invalid
                  && this.addExcFilterForm.controls[filedName].errors;
    return result;
  }
  //#endregion "For Exception Filter"
}

export enum enumDateOptions {
  Today=1,
  Yesterday=2,
  ThisWeek=3,
  LastWeek=4,
  Custom=5
}

export enum enumCurrentAction {
  None=0,
  DeleteException=1,
  CreateExceptionFilter=2
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: "root"
})
export class ApplicationVersionService {
  constructor(private http: HttpClient) {}

  getFsaVersionList(params) {
    return this.http
      .post<any>(`${environment.apiUrl}/applicationVersion/fsa-version-list`, params)
      .pipe(map(x => x.Result));
  }

  getSaVersionList(params) {
    return this.http
      .post<any>(`${environment.apiUrl}/applicationVersion/sa-version-list`, params)
      .pipe(map(x => x.Result));
  }

  getAppVersionDetail(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/applicationVersion/get-detail/${id}`).pipe(map(x => x.Result));
  }

  checkVersionExisted(params) {
    return this.http
      .post<any>(`${environment.apiUrl}/applicationVersion/version-is-existed`, params)
      .pipe(map(x => x.Result));
  }

  saveFsaVersion(params) {
    return this.http
      .post<any>(`${environment.apiUrl}/applicationVersion/save-fsa-version`, params)
      .pipe(map(x => x.Result));
  }

  saveSaVersion(params) {
    return this.http
      .post<any>(`${environment.apiUrl}/applicationVersion/save-sa-version`, params)
      .pipe(map(x => x.Result));
  }

  deleteAppVersion(params) {
    return this.http.put<any>(`${environment.apiUrl}/applicationVersion/delete-app-version`, params).pipe(map(x => x.Result));
  }
    
}

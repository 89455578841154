import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

import { FreeTrialBannedEmailDto, FreeTrialBannedIpDto, TrialDto } from 'models/trial';

const httpOptions = {
  headers: new HttpHeaders({ 'Occidentifier': environment.accessToken })
};

@Injectable({
  providedIn: 'root'
})
export class TrialService {

  constructor(private http: HttpClient) { }

  getCountries(language: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/trial/countries/${language}`, httpOptions)
      .pipe(map(x => x.Result));
  }

  getLanguages() {
    return this.http
      .get<any>(`${environment.apiUrl}/trial/languages`, httpOptions)
      .pipe(map(x => x.Result));
  }

  isBannedIp() {
    return this.http
      .get<any>(`${environment.apiUrl}/trial/isbannedip`, httpOptions)
      .pipe(map(x => x.Result));
  }

  isBannedTrialIp(ip: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/freetrialbannedip/isipbannded/${ip}`, httpOptions)
      .pipe(map(x => x.Result));
  }

  getEmailType(email: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/trial/getemailtype/${email}`, httpOptions)
      .pipe(map(x => x.Result));
  }

  getTrialEmailType(email: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/freetrialbannedemail/trialemailtype/${email}`, httpOptions)
      .pipe(map(x => x.Result));
  }

  banEmail(data: FreeTrialBannedEmailDto) {
    return this.http
      .post<any>(`${environment.apiUrl}/freetrialbannedemail/add`, data, httpOptions)
      .pipe(map(x => x.Result));
  }

  freeTrial(data: TrialDto) {
    return this.http
      .post<any>(`${environment.apiUrl}/trial/freetrial`, data, httpOptions)
      .pipe(map(x => x.Result));
  }

  requestdemo(data: TrialDto) {
    return this.http
      .post<any>(`${environment.apiUrl}/trial/requestdemo`, data, httpOptions)
      .pipe(map(x => x.Result));
  }

  getTrialIp(implementation_id) {
    return this.http
    .get<any>(`${environment.apiUrl}/trial/gettrialip/${implementation_id}`, httpOptions)
    .pipe(map(x => x.Result));
  }

  banIp(data: FreeTrialBannedIpDto) {
    return this.http
      .post<any>(`${environment.apiUrl}/freetrialbannedip/add`, data, httpOptions)
      .pipe(map(x => x.Result));
  }

  getBannedEmailInfo(email: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/freetrialbannedemail/info/${email}`, httpOptions)
      .pipe(map(x => x.Result));
  }

  getBannedIpInfo(ip: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/freetrialbannedip/info/${ip}`, httpOptions)
      .pipe(map(x => x.Result));
  }

  isEnableTrial() {
    return this.http
      .get<any>(`${environment.apiUrl}/trial/isenabletrial`, httpOptions)
      .pipe(map(x => x.Result));
  }

  getNumberOfTechnicians() {
    return this.http
      .get<any>(`${environment.apiUrl}/trial/get_number_technicians`, httpOptions)
      .pipe(map(x => x.Result));
  }

  initTrialForm() {
    return this.http
      .get<any>(`${environment.apiUrl}/trial/initTrialForm`, httpOptions)
      .pipe(map(x => x.Result));
  }

  initTrialFormForMobile(language: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/trial/initTrialFormForMobile/${language}`, httpOptions)
      .pipe(map(x => x.Result));
  }

  getTrialFormValues(language: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/trial/getTrialFormValues/${language}`, httpOptions)
      .pipe(map(x => x.Result));
  }
}

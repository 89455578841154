import { Component, OnInit, HostBinding, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser"
import { Subscription, timer } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { TrialService } from 'services';
import { Steps, EmailType, Industry, TrialDto, JobTitle } from 'models/trial';
import { environment } from 'environments/environment';

@Component({
  selector: "[app-trial-registration]",
  templateUrl: "./trial-registration.component.html",
  styleUrls: ["./trial-registration.component.scss"]
})

export class TrialRegistrationComponent implements OnInit {

  loginInformationForm: FormGroup;
  isLoginInformationFormSubmitted: boolean = false;
  trialInformationForm: FormGroup;
  isTrialInformationFormSubmitted: boolean = false;
  isIpBanned: boolean = false;
  isAlreadyUse: boolean = false;
  isFree: boolean = false;
  isBanned: boolean = false;
  isRecaptchaValid = false;
  isShowContract: boolean = false;
  loading: boolean = false;
  errorMessage: string;
  step: Steps;
  steps = Steps;
  countryList: object[];
  languageList: object[];
  industryList: object[] = [];
  jobTitleList: object[] = [];
  entity: TrialDto = new TrialDto();
  portalLoginUrl: string = environment.portalLoginUrl;
  portalRecoverPasswordUrl: string = environment.portalRecoverPasswordUrl;
  recaptchaSiteKey: string = environment.recaptchaSiteKey;
  subscription: Subscription;
  dashoffset: number = 350;
  percent: number = 0;
  trialResult: object = {};
  portalLoginPageUrl:string = environment.portalLoginPageUrl;
  freeEmailMessage: SafeHtml;
  pleaseSelect: string = 'Please select';
  isValidNumberTechnician: boolean = true;
  isCompactVersion: boolean = false;
  isEnableTrial: boolean = true;
  language: string = 'en';

  constructor(@Inject(DOCUMENT) private document: Document, public translate: TranslateService, private route: ActivatedRoute,private router: Router, private formBuilder: FormBuilder, private trialService: TrialService, private _sanitizer: DomSanitizer) { }

  ngOnInit() {

    const lang = this.route.snapshot.paramMap.get("language") || 'en';
    this.translate.use(lang.indexOf('-') != -1 ? lang.split('-')[0] : lang);
    this.language = lang.indexOf('-') != -1 ? lang.split('-')[0] : lang;

    // check is banned ip
    this.loading = true;
    this.trialService.initTrialFormForMobile(this.language).subscribe(data => {
      this.loading = false;
      this.isIpBanned = data["isBannedIp"] === "1";
      this.isEnableTrial = data["isEnableTrial"] === "1";
      if (!this.isIpBanned && this.isEnableTrial) {
        this.countryList = data["countries"];
        this.init();
      }
      else{          
        window.location.href = "https://wello.solutions/" + lang + "/contact/";
      }        
    },
    error => {
      this.loading = false;
      this.errorMessage = error.Message;
    });
  }

  private passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (this.loginInformationForm && (control.value !== this.loginInformationForm.controls.adminuserpassword.value)) {
        return { passwordNotMatch: true };
    }
    return null;
 }

  init() {

    this.step = Steps.LoginInformation;
    this.loginInformationForm = this.formBuilder.group({
      adminuserfirstname: ['', Validators.required],
      adminuserlastname: ['', Validators.required],
      adminuseremail: ['', [Validators.required, Validators.email]],
      adminuserpassword: ['', [Validators.required, Validators.minLength(6)]],
      password_confirm: ['', [Validators.required, this.passwordMatcher.bind(this)]],
      defaultcountryname: ['', Validators.required],
      recaptcha: ['']
    });

    this.trialInformationForm = this.formBuilder.group({
      defaultlanguagename: ['', Validators.required],
      companyname: ['', Validators.required],
      phone_number: ['', Validators.required],
      industry: ['', Validators.required],
      jobtitle: ['', Validators.required],
      numberoftechnicians: ['', Validators.required],
      onboardinggoal: ['', Validators.required],
      agreement: [''],
      recaptcha: ['']
    });

    this.entity.istrial = true;
    this.entity.numberoftechnicians = 0;

    this.route.queryParams.subscribe(params => {
      this.entity.adminuseremail = params['email'];
      this.isCompactVersion = params['isCompactVersion'] == "true" ? true : false;
      if(this.isCompactVersion == true) {
        document.getElementsByTagName("body")[0].style.background = "none";
      }
    });

    this.translate.get('msg_SorryYouCantCreateAFreeTrialWithAFreeEmailPleaseContactUsViaThisForm').subscribe(res => {
      this.freeEmailMessage = this._sanitizer.bypassSecurityTrustHtml(res);

      let that = this;

      setTimeout(()=>{
        document.querySelector('.freeEmailMessage span').addEventListener('click', function() {
          that.isShowContract = true;
        });
      }, 1000);
    });

    //this.getCountries();
    //this.getLanguages();

    let node = document.createElement('script');
    node.src = 'https://www.googletagmanager.com/gtag/js?id=UA-47359231-7';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);

    let analytics = document.createElement('script');
    analytics.type = 'text/javascript';
    analytics.async = false;
    analytics.charset = 'utf-8';
    analytics.innerHTML = "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}; gtag('js', new Date()); gtag('config', 'UA-47359231-7');";
    document.getElementsByTagName('head')[0].appendChild(analytics);
  }

  getCountries() {
    this.trialService.getCountries(this.language).subscribe(data => {
      this.countryList = data;
      let defaultCountry = this.countryList.find(c => c['code'] == 'BE');
      if (defaultCountry) {
        this.entity.defaultcountryname = defaultCountry['country_name'];
      }
    });
  }

  getLanguages() {
    this.trialService.getLanguages().subscribe(data => {
      this.languageList = data;
      if (!this.entity.defaultlanguagename) this.defaultCountryNameChange();
    });
  }

  gotoLoginInfo() {
    this.step = Steps.LoginInformation;
  }

  loginInformationFormSubmit() {

    this.isLoginInformationFormSubmitted = true;

    if (this.loginInformationForm.invalid) {
      return;
    }

    // check email
    this.loading = true;
    this.trialService.getEmailType(this.entity.adminuseremail).subscribe(data => {
      this.loading = false;
      this.isAlreadyUse = data == EmailType.ALREADY_USED;
      this.isFree = data == EmailType.FREE;
      this.isBanned = data == EmailType.BANNED;

      if (this.isAlreadyUse) {
        this.loginInformationForm.controls.adminuseremail.setErrors({invalidEmail:true});
      }

      if (this.isFree) {
        this.loginInformationForm.controls.adminuseremail.setErrors({freeEmail:true});
      }

      if (!this.isAlreadyUse && !this.isFree && !this.isBanned) {
        this.loginInformationForm.controls.adminuseremail.setErrors(null);
        this.step = Steps.TrialInformation;
        this.initDropdownListAndLinks();
        this.getLanguages();
      }
    });
  }

  trialInformationFormSubmit() {

    this.isTrialInformationFormSubmitted = true;

    if (this.trialInformationForm.invalid) {
      return;
    }

    let canCreateTrial = true;

    this.step = Steps.Processing;

    this.loading = true;
    this.trialService.freeTrial(this.entity).subscribe(
      data => {
        this.loading = false;
        this.trialResult = data;

        if (data.Success == false && data.ErrorMessage == 'TRIAL_FORM_ENABLED'){
          canCreateTrial = false;          
        }
      },
      error => {
        this.loading = false;
        this.errorMessage = error.Message;
      }
    )

    this.subscription = timer(0, 100).pipe().subscribe(result => {
      if (this.loading == false) {
        if (canCreateTrial == false){
          this.subscription.unsubscribe();
          let language = this.route.snapshot.paramMap.get("language") || 'en';
          window.location.href = "https://odysseefieldservice.com/" + language + "/contact-us/";          
        }
        else{
          this.dashoffset = 0;
          this.percent = 100;
          this.subscription.unsubscribe();
          if(!this.isCompactVersion) 
            this.loginUserToPortal();
        }
      } else if (this.dashoffset > 3.5) {
        this.dashoffset -= 3.5;
        this.percent += 1;
      }
    });
  }

  loginUserToPortal() {
    this.document.location.href = this.portalLoginUrl + '?domain=' + encodeURIComponent(this.trialResult['Data']['domain']) + '&email=' + encodeURIComponent(this.trialResult['Data']['email']) + '&password=' + encodeURIComponent(this.trialResult['Data']['password']);
  }

  defaultCountryNameChange() {
    if (this.entity.defaultcountryname) {
      let country = this.countryList.find(c => c['country_name'] == this.entity.defaultcountryname);
      let language = this.languageList.find(l => l['code'] == 'EN');
      switch(country['code']) {
        case 'FR':
        case 'DE':
        case 'IT':
        case 'ES':
        case 'VN':
          language = this.languageList.find(l => l['code'] == country['code'])
          break;
        case 'BE':
        case 'NL':
          language = this.languageList.find(l => l['code'] == 'NL')
          break;
        case 'AT':
          language = this.languageList.find(l => l['code'] == 'DE')
          break;
        case 'GF':
        case 'PF':
        case 'RE':
          language = this.languageList.find(l => l['code'] == 'FR')
          break;
      }
      this.entity.defaultlanguagename = language['name'];
    }
  }

  numberoftechniciansChanged() {
    //this.entity.numberoftechnicians = this.entity.numberoftechnicians < 2 ? 2 : this.entity.numberoftechnicians;
    let val = this.trialInformationForm.get('numberoftechnicians').value < 1 ? null : this.trialInformationForm.get('numberoftechnicians').value;
    this.entity.numberoftechnicians = val;
    this.trialInformationForm.get('numberoftechnicians').setValue(val);
    this.isValidNumberTechnician = val >= 5 || val == null;
  }

  passwordChanged() {
    if (this.entity.adminuserpassword && this.entity.password_confirm) {
      if (this.entity.adminuserpassword != this.entity.password_confirm)
        this.loginInformationForm.controls.password_confirm.setErrors({passwordNotMatch: true});
      else
        this.loginInformationForm.controls.password_confirm.setErrors(null);
    }
  }

  decrease() {
    //if (this.entity.numberoftechnicians <= 2)
    //  this.entity.numberoftechnicians = 2;
    //else
    //  this.entity.numberoftechnicians--;

    if (this.entity.numberoftechnicians <= 1){
      this.entity.numberoftechnicians = 1;
      this.trialInformationForm.get('numberoftechnicians').setValue(this.entity.numberoftechnicians);
    }
    else{
      this.entity.numberoftechnicians--;
      this.trialInformationForm.get('numberoftechnicians').setValue(this.entity.numberoftechnicians);
    }

    this.isValidNumberTechnician = this.entity.numberoftechnicians >= 5;
  }

  increase() {
    if (this.entity.numberoftechnicians == 9999999){
      // reach to maximum number of technicians
      return;
    }

    this.entity.numberoftechnicians++;
    this.trialInformationForm.get('numberoftechnicians').setValue(this.entity.numberoftechnicians);
    this.isValidNumberTechnician = this.entity.numberoftechnicians >= 5;
  }

  resolved(event) {
    this.isRecaptchaValid = event != null;
  }

  initDropdownListAndLinks(){
    this.industryList = [];
    this.jobTitleList = [];
    
    this.translate.get('FORM_INDUSTRIES_TELECOM').subscribe(res => {
      this.industryList.push({ code: Industry.TELECOM, name: res });
      this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_INDUSTRIES_RENEWABLE_ENERGY').subscribe(res => {
      this.industryList.push({ code: Industry.RENEWABLE_ENERGY, name: res });
      this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_INDUSTRIES_UTILITY').subscribe(res => {
      this.industryList.push({ code: Industry.UTILITY, name: res });
      this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_INDUSTRIES_HVACR').subscribe(res => {
      this.industryList.push({ code: Industry.HVACR, name: res });
      this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_INDUSTRIES_IT_HARDWARE').subscribe(res => {
      this.industryList.push({ code: Industry.IT_HARDWARE, name: res });
      this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_INDUSTRIES_FACILITIES_MANAGEMENT').subscribe(res => {
      this.industryList.push({ code: Industry.FACILITIES_MANAGEMENT, name: res });
      this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_INDUSTRIES_MEDICAL_EQUIPMENT').subscribe(res => {
      this.industryList.push({ code: Industry.MEDICAL_EQUIPMENT, name: res });
      this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_INDUSTRIES_CONSTRUCTION').subscribe(res => {
      this.industryList.push({ code: Industry.CONSTRUCTION, name: res });
      this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_INDUSTRIES_MANUFACTURING').subscribe(res => {
      this.industryList.push({ code: Industry.MANUFACTURING, name: res });
      this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_INDUSTRIES_LOGISTICS').subscribe(res => {
      this.industryList.push({ code: Industry.LOGISTICS, name: res });
      this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_INDUSTRIES_BUILDING_TECHNOLOGIES').subscribe(res => {
      this.industryList.push({ code: Industry.BUILDING_TECHNOLOGIES, name: res });
      this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_INDUSTRIES_RESIDENTIAL_SERVICES').subscribe(res => {
      this.industryList.push({ code: Industry.RESIDENTIAL_SERVICES, name: res });
      this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_INDUSTRIES_SECURITY_SYSTEMS').subscribe(res => {
      this.industryList.push({ code: Industry.SECURITY_SYSTEMS, name: res });
      this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_INDUSTRIES_FIELD_MARKETING').subscribe(res => {
      this.industryList.push({ code: Industry.FIELD_MARKETING, name: res });
      this.industryList = this.industryList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_JOB_TITLE_CEO_FOUNDER').subscribe(res => {
      this.jobTitleList.push({ code: JobTitle.CEO_FOUNDER, name: res });
      this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_JOB_TITLE_SERVICE_MANAGER').subscribe(res => {
      this.jobTitleList.push({ code: JobTitle.SERVICE_MANAGER, name: res });
      this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_JOB_TITLE_FIELD_TECHNICIAN').subscribe(res => {
      this.jobTitleList.push({ code: JobTitle.FIELD_TECHNICIAN, name: res });
      this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_JOB_TITLE_BACK_OFFICE_USER').subscribe(res => {
      this.jobTitleList.push({ code: JobTitle.BACK_OFFICE_USER, name: res });
      this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_JOB_TITLE_HELPDESK_USER').subscribe(res => {
      this.jobTitleList.push({ code: JobTitle.HELPDESK_USER, name: res });
      this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_JOB_TITLE_CIO_IT_MANAGER').subscribe(res => {
      this.jobTitleList.push({ code: JobTitle.CIO_IT_MANAGER, name: res });
      this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_JOB_TITLE_CONSULTANT').subscribe(res => {
      this.jobTitleList.push({ code: JobTitle.CONSULTANT, name: res });
      this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('FORM_JOB_TITLE_DIRECTOR').subscribe(res => {
      this.jobTitleList.push({ code: JobTitle.DIRECTOR, name: res });
      this.jobTitleList = this.jobTitleList.sort((a, b) => a['name'].localeCompare(b['name']));
    });

    this.translate.get('lbl_shrt_PleaseSelect').subscribe(res => {
      this.pleaseSelect = res;
    });
  }

  get l() { return this.loginInformationForm.controls }

  get t() { return this.trialInformationForm.controls }

  ngOnDestroy() {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  @HostBinding("class") get themeClass() {
    return "u-scroll o-flex__item o-flex o-flex--vertical"
  }
}
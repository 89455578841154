export class User {
  id: number
  username: string
  email: string
  fullname: string
  token: string
  is_superadmin: boolean
  partner_company_name: string
  archived: boolean
  CanCreateNewImplementation: boolean
  CanArchiveImplementation: boolean
  CanRunQueries: boolean
  CanLogAs: boolean
  CanArchiveTrialImplementation: boolean
  CanExtendTrial: boolean
  AllowSeeException:boolean
  CanAdaptImplementationLicence:boolean
  CanAdaptTrialImplementationLicence:boolean
  language_code: string
  CanOpenSubscriptionAsOwner: boolean
  CanAddCredits: boolean
  CanAllowInvoice: boolean
  CanAdaptLicenceBreakingRules: boolean
  CanAdaptUserRights: boolean
  CanBanIpOrEmail: boolean
  CanEditImplementationAccountManager: boolean
  CanEditImplementationProjectManager: boolean
  CanMoveToProduction: boolean
  CanOpenSubscription: boolean
  CanAdaptReportBreakingRules: boolean
  AllowManageAppsVersion: boolean
}
